import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import { Voucher, VoucherBase, VoucherFilter } from 'dto/voucher';
import {
  clearVouchers,
  getVoucher,
  getVouchers,
  setVoucherFilter,
} from 'features/voucher/vouchersActions';

interface VouchersState {
  list?: Pagination<VoucherBase>;
  current?: Voucher;
  filter: Partial<VoucherFilter>;
}

const initialState: VouchersState = {
  filter: {},
};

export const vouchersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getVouchers.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(getVoucher.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(setVoucherFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(clearVouchers, (state) => {
      state.list = undefined;
    });
});
