import type { FC } from 'react';
import { Layout } from '@fleet/shared/components/Layout';
import { Link, Route, Switch } from 'react-router-dom';
import { CoTaxationGroupForm } from 'routes/CoTaxationGroups/CoTaxationGroupForm';
import { CoTaxationGroupTable } from 'routes/CoTaxationGroups/CoTaxationGroupTable';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { AddButton, CardHeader } from '@fleet/shared';

interface CoTaxationGroupsProps {}

export const CoTaxationGroups: FC<CoTaxationGroupsProps> = () => {
  return (
    <Layout
      height="auto"
      header={
        <CardHeader
          title={<TransTitle i18nKey="coTaxationGroups" />}
          sx={{ padding: '16px 24px' }}
        >
          <AddButton component={Link} to="/co-taxation-groups/create">
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
      drawer={
        <Switch>
          <Route
            component={CoTaxationGroupForm}
            path="/co-taxation-groups/:action(create|edit)/:id?"
            exact
          />
        </Switch>
      }
    >
      <CoTaxationGroupTable />
    </Layout>
  );
};
