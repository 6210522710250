import { createAsyncThunk } from 'store/utils';
import { api } from '@fleet/shared';
import qs from 'qs';
import {
  FareModelFixedFare,
  FareModelFixedFareFilter,
  FareModelFixedFarePayload,
} from 'dto/fareModelFixed';
import { createAction } from '@reduxjs/toolkit';
import { selectFareModel } from 'features/fareModel/fareModelSelectors';
import { Pagination } from '@fleet/shared/dto/pagination';

export const setFixedFaresFilter = createAction<
  Partial<FareModelFixedFareFilter>
>('fareModelFixed/setFixedFaresFilter');

export const getFixedFares = createAsyncThunk<
  Pagination<FareModelFixedFare>,
  Partial<FareModelFixedFareFilter> | undefined
>('fareModelFixed/getFixedFares', async (values, { dispatch, getState }) => {
  if (values) {
    dispatch(setFixedFaresFilter(values));
  }

  const { fixedFaresFilter, current } = getState().fareModel;

  return (
    await api.get<Pagination<FareModelFixedFare>>(
      `/fare-models/${current?.id}/fixed-fares${qs.stringify(fixedFaresFilter, {
        addQueryPrefix: true,
      })}`
    )
  ).data;
});

export const createOrUpdateFixedFare = createAsyncThunk<
  FareModelFixedFare,
  FareModelFixedFarePayload
>(
  'fareModelFixed/createOrUpdateFixedFare',
  async ({ id, ...rest }, { getState }) =>
    await (id ? api.put : api.post)(
      `/fare-models/${selectFareModel(getState())?.id}/fixed-fares${
        id ? `/${id}` : ''
      }`,
      rest
    )
);

export const deleteFixedFares = createAsyncThunk<void, Array<string>>(
  'fareModelFixed/deleteFixedFare',
  async (ids, { getState }) => {
    const fareModel = selectFareModel(getState());
    const { id: fareModelId } = fareModel!;

    await api.post(`/fare-models/${fareModelId}/fixed-fares/bulk-delete`, {
      ids,
    });
  }
);
