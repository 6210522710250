import { FC, useCallback, useMemo } from 'react';
import { useRowSelect } from 'react-table';
import {
  api,
  Button,
  ConfirmDeleteButton,
  FormProvider,
  Icon,
  makeClassificationOptions,
  Table,
  TableColumns,
  useBusinessEntitiesByType,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { Stack } from '@mui/material';
import { useRowEditActions } from '@fleet/shared/hooks';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { currentCoTaxationGroupSelector } from 'features/coTaxationGroup/coTaxationGroupSelectors';
import { Classifier } from '@fleet/shared/dto/classifier';
import {
  getCoTaxationGroup,
  getCoTaxationGroups,
} from 'features/coTaxationGroup/coTaxationGroupActions';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { TransModal } from 'i18n/trans/modal';

const conf = { baseURL: process.env.REACT_APP_API_OM };

interface CoTaxationGroupCarriersProps {}

export const CoTaxationGroupCarriers: FC<CoTaxationGroupCarriersProps> = () => {
  const dispatch = useDispatch();
  const current = useSelector(currentCoTaxationGroupSelector)!;
  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = useMemo(
    () => makeClassificationOptions(CARRIER),
    [CARRIER]
  );
  const data = useMemo(() => current?.contacts ?? [], [current]);

  const { form } = useForm<{ rows: Array<Classifier<number>> }>({
    initialValues: {
      rows: data,
    },
  });

  const columns = useMemo<TableColumns<Classifier<number>>>(
    () => [
      {
        accessor: 'id',
        Header: <TransTableHead i18nKey="carrier" />,
        type: 'select',
        editableProps: {
          options: carrierOptions,
        },
      },
    ],
    [carrierOptions]
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<Classifier<number>>) => {
      await Promise.all(
        rows.map((row) =>
          api.delete(
            `/co-taxation-groups/${current!.id}/organizations/${row.id}`,
            conf
          )
        )
      );

      dispatch(getCoTaxationGroups());
      dispatch(getCoTaxationGroup(current.id));
    },
    [current, dispatch]
  );

  const onRowUpdate = useCallback(
    async (value: Classifier<number>) => {
      await api.post(
        `/co-taxation-groups/${current!.id}/organizations`,
        { organizationId: value.id },
        conf
      );

      dispatch(getCoTaxationGroups());
      dispatch(getCoTaxationGroup(current.id));
    },
    [current, dispatch]
  );

  const table = useFormTable<Classifier<number>>(
    {
      data,
      columns,
      form,
      onRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  return (
    <FormProvider form={form}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <ConfirmDeleteButton
          title={<TransModal i18nKey="coTaxationGroupCarrierDeletionTitle" />}
          description={
            <TransModal
              i18nKey="coTaxationGroupCarrierDeletionDescription"
              values={{ count: table.selectedFlatRows.length }}
            />
          }
          disabled={!table.selectedFlatRows.length}
          onConfirm={removeSelectedRows}
        />
        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={addRow}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        table={table}
        getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
      />
    </FormProvider>
  );
};
