import type { FC } from 'react';
import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { TabPanel, Tabs } from '@fleet/shared';
import { tabsClasses } from '@mui/material/Tabs';
import { promotionalDiscountTabs } from 'dto/promotionalDiscount';
import { TransTab } from 'i18n/trans/tab';
import { PromotionalDiscountTabInformation } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/Information';
import { PromotionalDiscountTabBookingQuantity } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/BookingQuantity';
import { PromotionalDiscountTabTransactionTime } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/TransactionTime';
import { PromotionalDiscountTabDepartureDays } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/DepartureDays';
import { PromotionalDiscountTabSalesChannel } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/SalesChannel';
import { PromotionalDiscountTabLineType } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/LineType';
import { PromotionalDiscountTabPassengerGroup } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/PassengerGroup';
import { PromotionalDiscountTabCardType } from './PromotionalDiscountTabs/CardType';
import { PromotionalDiscountTabFeederDefeeder } from './PromotionalDiscountTabs/FeederDefeeder';
import { PromotionalDiscountTabAccompanyingPassengerTypeRestrictions } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/AccompanyingPassengerTypeRestrictions';
import { Alert, Stack, Typography } from '@mui/material';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { useSelector } from 'store/utils';
import { inventoryClassCompartmentIdSelector } from 'features/classification/classificationSelectors';
import { TransHint } from 'i18n/trans/hint';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'PromotionalDiscountTabs',
  }
);

interface PromotionalDiscountTabsProps {}

const inventoryClassesIncludesCompartmentAlertTabs = [
  'bookingQuantity',
  'passengerGroup',
  'accompanyingPassengerTypeRestrictions',
];

export const PromotionalDiscountTabs: FC<PromotionalDiscountTabsProps> = () => {
  const promotionalDiscount = useSelector(promotionalDiscountSelector);
  const inventoryClassIds = useMemo(
    () => (promotionalDiscount?.inventoryClasses ?? []).map(({ id }) => id),
    [promotionalDiscount?.inventoryClasses]
  );
  const compartmentInventoryClassId = useSelector(
    inventoryClassCompartmentIdSelector
  );

  const tabs: Record<typeof promotionalDiscountTabs[number], JSX.Element> =
    useMemo(
      () => ({
        information: <PromotionalDiscountTabInformation />,
        bookingQuantity: <PromotionalDiscountTabBookingQuantity />,
        transactionTime: <PromotionalDiscountTabTransactionTime />,
        departureDays: <PromotionalDiscountTabDepartureDays />,
        salesChannel: <PromotionalDiscountTabSalesChannel />,
        lineType: <PromotionalDiscountTabLineType />,
        passengerGroup: <PromotionalDiscountTabPassengerGroup />,
        cardType: <PromotionalDiscountTabCardType />,
        accompanyingPassengerTypeRestrictions: (
          <PromotionalDiscountTabAccompanyingPassengerTypeRestrictions />
        ),
        feederDefeeder: <PromotionalDiscountTabFeederDefeeder />,
      }),
      []
    );

  const classes = useStyles();
  return (
    <Tabs
      className={classes.root}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 },
        },
      }}
    >
      {promotionalDiscountTabs.map((tab) => (
        <TabPanel
          key={tab}
          value={tab}
          label={
            <Stack direction="row" alignItems="baseline" sx={{ gap: '5px' }}>
              <Typography variant="subtitle">
                <TransTab i18nKey={`promotionalDiscount.${tab}`} />
              </Typography>
              <Typography variant="body2">
                ({promotionalDiscount?.[tab].length ?? 0})
              </Typography>
            </Stack>
          }
        >
          {compartmentInventoryClassId &&
            inventoryClassIds.includes(compartmentInventoryClassId) &&
            inventoryClassesIncludesCompartmentAlertTabs.includes(tab) && (
              <Alert severity="warning">
                <TransHint i18nKey="inventoryClassesIncludesCompartment" />
              </Alert>
            )}
          {tabs[tab]}
        </TabPanel>
      ))}
    </Tabs>
  );
};
