import { Classifier } from '@fleet/shared/dto/classifier';
import { Validity } from 'dto/common';

export const fareTypes = [
  'FARE_TYPE.ORIGIN_DESTINATION',
  'FARE_TYPE.ZONE',
  'FARE_TYPE.DISTANCE',
  'FARE_TYPE.THRU',
  'FARE_TYPE.FIXED',
  'FARE_TYPE.ADMISSION_DEPENDANT',
] as const;
type FareType = typeof fareTypes[number];

export interface FareModel extends Classifier {
  ownerId: number;
  isActive: boolean;
  validity: Validity;
  validityPeriodInterpretation: Classifier;
  areFaresBidirectional: boolean;
  hasOriginDestinationFares: boolean;
  useYieldedMultilegDistancePricing: boolean;
  hasThruFares: boolean;
  hasZoneFares: boolean;
  fareTypes: Array<Classifier<FareType>>;
}

export interface FareModelPayload
  extends Omit<FareModel, 'validityPeriodInterpretation'> {
  validityPeriodInterpretationId: string;
}

export interface FareModelCopy {
  id: string;
  name: string;
  ownerId: number;
  validity: {
    from: Date;
    to: Date;
    timeZone: string;
  };
  fareTypes: Array<FareType>;
}

export interface FareModelStop {
  id: string;
  stop: Classifier<number> & {
    city: Classifier<number>;
    country: Classifier<number>;
    county: Classifier<number>;
    streetAddress: string;
  };
}

export interface FareModelFare {
  fareId: string;
  currency: Classifier;
  fareCategory: Classifier;
  fare: number;
  validity: Validity;
}

export interface FareModelOriginDestinationFare {
  originStop: FareModelStop;
  destinationStop: FareModelStop;
  fares: Array<FareModelFare>;
}

export interface LineNetworkStop {
  id: number;
  code: string;
  name: string;
  streetAddress: string;
  zipCode: string;
  cityId: number;
  countyId: number;
  countryId: number;
  latitude: number;
  longitude: number;
  isMultiLegHub: true;
  minTransferTimeInMinutes: number;
  isWheelChairAccessible: boolean;
  localizations: Array<{
    languageId: string;
    name: string;
  }>;
  cityName: string;
  countryName: string;
  countyName: string;
}
