import type { FC } from 'react';
import { Layout } from '@fleet/shared/components/Layout';
import { useParams } from 'react-router-dom';
import { VoucherDetailsForm } from 'routes/Vouchers/VoucherDetailsForm';
import { CardHeader, Loadable, TabPanel, Tabs } from '@fleet/shared';
import { ActivityLog } from 'routes/Vouchers/Tabs/ActivityLog';
import { makeStyles } from '@mui/styles';
import { ChangeHistory } from 'routes/Vouchers/Tabs/ChangeHistory';
import { useEffect } from 'react';
import { getVoucher } from 'features/voucher/vouchersActions';
import { useDispatch, useSelector } from 'store/utils';
import { voucherLoadingSelector } from 'features/loading/loadingSelectors';
import { TransTitle } from 'i18n/trans/title';
import { TransTab } from 'i18n/trans/tab';
import { currentVoucherSelector } from 'features/voucher/vouchersSelector';

interface VoucherDetailsProps {}

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '24px',
    },
  }),
  {
    name: 'OrganizationsDetails',
  }
);

export const VoucherDetails: FC<VoucherDetailsProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const current = useSelector(currentVoucherSelector);
  const loading = useSelector(voucherLoadingSelector);

  useEffect(() => {
    dispatch(getVoucher(id));
  }, [dispatch, id]);

  return (
    <Loadable loading={loading}>
      <Layout
        sx={{ flex: '0 !important', minHeight: 'unset !important' }}
        header={
          <CardHeader
            title={
              <TransTitle i18nKey="voucher" values={{ num: current?.code }} />
            }
          />
        }
      >
        <VoucherDetailsForm id={id} />
      </Layout>
      <div className={classes.root}>
        <Tabs>
          <TabPanel
            key="activityLog"
            label={<TransTab i18nKey="activityLog" />}
            value="activityLog"
          >
            <ActivityLog />
          </TabPanel>
          <TabPanel
            key="changeHistory"
            label={<TransTab i18nKey="changeHistory" />}
            value="changeHistory"
          >
            <ChangeHistory />
          </TabPanel>
        </Tabs>
      </div>
    </Loadable>
  );
};
