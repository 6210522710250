import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ConditionField,
  FormControl,
  PeriodField,
  SelectField,
  SelectOwnerField,
  SwitchField,
  TextField,
  TimeZoneField,
  useFormContext,
} from '@fleet/shared';
import { Grid } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { VOUCHER_TYPE } from 'dto/vouchersTemplate';
import { useParams } from 'react-router-dom';
import {
  currentVoucherTemplate,
  vouchersPriorityTemplateListSelector,
} from 'features/vouchersTemplate/vouchersTemplateSelector';
import { getPriorityVoucherTemplates } from 'features/vouchersTemplate/vouchersTemplateActions';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { renderToString } from 'react-dom/server';
import { TransValidate } from 'i18n/trans/validate';
import { useDispatch, useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

export interface VouchersTemplateFormProps {}

export const VouchersTemplateForm: FC<VouchersTemplateFormProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const { action } = useParams<{ action: 'create' | 'edit' }>();
  const isEditing = action === 'edit';
  const voucherTypes = useClassificationOptions(
    ClassificationGroup.VOUCHER_TYPE
  );
  const voucherUsageTypes = useClassificationOptions(
    ClassificationGroup.VOUCHER_USAGE_TYPE
  );
  const periodTypes = useClassificationOptions(ClassificationGroup.PERIOD_TYPE);
  const printoutThemes = useClassificationOptions(
    ClassificationGroup.PRINTOUT_THEMES
  );
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const vouchersPriorityTemplateList = useSelector(
    vouchersPriorityTemplateListSelector
  );
  const currentTemplate = useSelector(currentVoucherTemplate);
  const [selectedOwnerId, setSelectedOwnerId] = useState<number>(
    currentBusinessEntityId
  );
  const { getState, change } = useFormContext();
  const { values } = getState();

  useEffect(() => {
    dispatch(getPriorityVoucherTemplates({ ownerId: selectedOwnerId }));
  }, [currentBusinessEntityId, dispatch, selectedOwnerId]);

  const validateUniquePriority = useCallback(
    (value) => {
      if (!value) return undefined;
      const currentPriority = currentTemplate?.priority;
      const selectedOwnerPriorityVouchersTemplate =
        vouchersPriorityTemplateList.map((value) => {
          if (value.owner.id === selectedOwnerId) return value.priority;
        });

      const indexCurrentPriorityTemplate =
        selectedOwnerPriorityVouchersTemplate.indexOf(currentPriority);
      if (indexCurrentPriorityTemplate >= 0) {
        selectedOwnerPriorityVouchersTemplate.splice(
          indexCurrentPriorityTemplate,
          1
        );
      }

      return selectedOwnerPriorityVouchersTemplate.includes(+value)
        ? renderToString(
            <TransValidate
              i18nKey="uniq"
              values={{
                field: renderToString(<TransField i18nKey="priority" />),
              }}
            />
          )
        : undefined;
    },
    [currentTemplate?.priority, selectedOwnerId, vouchersPriorityTemplateList]
  );

  const selectedVoucherType = useMemo(
    () => values.voucherTypeId === VOUCHER_TYPE.COMPENSATION,
    [values.voucherTypeId]
  );

  useEffect(() => {
    if (values.voucherTypeId === VOUCHER_TYPE.COMPENSATION) {
      change('canValueBeSetOnCreation', true);
    }
  }, [change, values.voucherTypeId]);

  return (
    <>
      <Grid container columns={4} spacing={2}>
        <Grid item xs={1}>
          <SelectOwnerField
            businessEntities={businessEntities}
            allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
            onChange={setSelectedOwnerId}
            disabled
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="name"
            label={<TransField i18nKey="templateName" />}
            required
          />
        </Grid>
        <Grid item xs={1}>
          <SelectField
            options={voucherTypes}
            name="voucherTypeId"
            label={<TransField i18nKey="voucherType" />}
            disabled={isEditing}
            required
          />
        </Grid>

        {!selectedVoucherType && (
          <Grid item xs={1}>
            <SwitchField
              name="canValueBeSetOnCreation"
              label={<TransField i18nKey="canValueBeSetOnCreation" />}
              type="checkbox"
            />
          </Grid>
        )}

        <ConditionField when="canValueBeSetOnCreation" is={true}>
          <>
            <PeriodField
              from={{
                name: 'validity.from',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'validity.to',
                label: <TransField i18nKey="validTo" />,
                isClearable: true,
              }}
            />
            <Grid item xs={1}>
              <TimeZoneField
                name="validity.timeZone"
                label={<TransField i18nKey="validTimeZone" />}
              />
            </Grid>
          </>
        </ConditionField>
        <Grid item xs={1}>
          <FormControl label={<TransField i18nKey="validityPeriod" />} required>
            <Grid container columns={2} spacing={2} alignItems="flex-start">
              <Grid item xs={1}>
                <TextField name="validityPeriod" type="number" required />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="periodTypeId"
                  options={periodTypes}
                  required
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <SelectField
            options={printoutThemes}
            name="printoutThemeId"
            required
            label={<TransField i18nKey="printoutTheme" />}
          />
        </Grid>
        <Grid item xs={1}>
          <SelectField
            options={voucherUsageTypes}
            name="voucherUsageTypeId"
            label={<TransField i18nKey="voucherUsageType" />}
            disabled={isEditing}
            required
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="vatRate"
            label={<TransField i18nKey="vatRate" />}
            type="number"
            required
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="maxNoOfPayments"
            label={<TransField i18nKey="maxNoOfPayments" />}
            type="number"
            required
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="maxNoOfTotalTickets"
            label={<TransField i18nKey="maxNoOfTotalTickets" />}
            type="number"
            required
          />
        </Grid>
        <Grid item xs={1}>
          <TextField name="code" label={<TransField i18nKey="code" />} />
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="priority"
            label={<TransField i18nKey="priority" />}
            type="number"
            required
            validate={validateUniquePriority}
          />
        </Grid>
      </Grid>
      {children}
    </>
  );
};
