import { createAction } from '@reduxjs/toolkit';
import {
  FareModel,
  FareModelCopy,
  FareModelFare,
  FareModelOriginDestinationFare,
  FareModelPayload,
  FareModelStop,
} from 'dto/fareModel';
import { selectFareModel } from 'features/fareModel/fareModelSelectors';
import { createAsyncThunk } from 'store/utils';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import { fetchFareModels } from 'features/fareModel/fareModelService';
import { api } from '@fleet/shared';
import { Validity } from 'dto/common';

export const getFareModels = createAsyncThunk<Array<FareModel>>(
  'fareModel/getFareModels',
  async (_, { getState }) =>
    await fetchFareModels(selectCurrentBusinessEntityId(getState()))
);

export const setFareModel = createAction<FareModel | undefined>(
  'fareModel/setFareModel'
);

export const getFareModel = createAsyncThunk<FareModel, string>(
  'fareModel/getFareModel',
  async (id, { dispatch }) => {
    const { data } = await api.get<FareModel>(`/fare-models/${id}`);
    dispatch(setFareModel(data));
    // await dispatch(getFareModelOriginDestinationStops());
    return data;
  }
);

export const createFareModel = createAsyncThunk<FareModel, FareModelPayload>(
  'fareModel/createFareModel',
  async (payload) => (await api.post(`/fare-models`, payload)).data
);

export const updateFareModel = createAsyncThunk<FareModel, FareModelPayload>(
  'fareModel/updateFareModel',
  async ({ id, ...payload }) =>
    (await api.put(`/fare-models/${id}`, payload)).data
);

export const copyFareModel = createAsyncThunk<
  FareModel,
  Omit<FareModelCopy, 'ownerId'>
>('fareModel/copyFareModel', async ({ id, ...payload }, { dispatch }) => {
  const fareModel = (await api.post(`/fare-models/${id}/copy`, payload)).data;
  dispatch(setFareModel(fareModel));
  return fareModel;
});

export const deleteFareModel = createAsyncThunk<FareModel, string>(
  'fareModel/deleteFareModel',
  async (id) => (await api.delete(`/fare-models/${id}`)).data
);

export const getFareModelOriginDestinationStops = createAsyncThunk<
  {
    originStops: Array<FareModelStop>;
    destinationStops: Array<FareModelStop>;
  },
  string
>('fareModel/getFareModelOriginDestinationStops', async (id) => {
  const { originStops, destinationStops } = (
    await api.get(`/fare-models/${id}/origin-destination-stops`)
  ).data;
  return { originStops, destinationStops };
});

export const updateFareModelStops = createAsyncThunk<
  void,
  {
    type: 'origin' | 'destination';
    stopIds: Array<number>;
  }
>('fareModel/updateFareModelStops', async ({ type, stopIds }, { getState }) => {
  const { id } = selectFareModel(getState())!;
  await api.put(`/fare-models/${id}/${type}-stops/bulk`, { stopIds });
});

export interface FareModelOriginDestinationSearchParams {
  id: string;
  originStopIds: Array<number>;
  destinationStopIds: Array<number>;
  currencyId?: string;
  fareCategoryId?: string;
  validity: Partial<Omit<Validity, 'timeZone'>>;
}

export const getFareModelOriginDestinationFares = createAsyncThunk<
  Array<FareModelOriginDestinationFare>,
  Partial<FareModelOriginDestinationSearchParams> | undefined
>(
  'fareModel/getFareModelOriginDestinationFares',
  async (params, { getState }) => {
    const { id } = selectFareModel(getState())!;
    return (
      await api.post(`/fare-models/${id}/origin-destination-fares/search`, {
        originStopIds: [],
        destinationStopIds: [],
        ...params,
      })
    ).data.originDestinationsFares;
  }
);

export const addUpdateOriginDestinationFare = createAsyncThunk<
  FareModelFare,
  Record<string, unknown>
>(
  'fareModel/addUpdateOriginDestinationFare',
  async ({ fareModelId, fareId, ...values }) =>
    (
      await api[fareId ? 'put' : 'post'](
        `/fare-models/${fareModelId}/origin-destination-fares${
          fareId ? `/${fareId}` : ''
        }`,
        values
      )
    ).data
);
