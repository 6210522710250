import {
  TableColumns,
  decimalToPercentage,
  percentageToDecimal,
  renderPercentageError,
  makeClassificationOptions,
} from '@fleet/shared';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import {
  createOrUpdatePromotionalDiscountCardTypes,
  removePromotionalDiscountCardTypes,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import type { Option } from '@fleet/shared/dto/option';
import { Classifier } from '@fleet/shared/dto/classifier';
import { fetchCardTypes } from 'features/promotionalDiscount/promotionalDiscountService';
import { validate } from '@fleet/shared/form/validate';
import { isDiscountAmountFieldDisabled } from './utils';

interface PromotionalDiscountTabCardTypeProps {}

type Entity = PromotionalDiscount['cardType'][number];

export const PromotionalDiscountTabCardType: FC<PromotionalDiscountTabCardTypeProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { cardType, ownerId } = currentDiscount;
    const [cardTypesOptions, setCardTypesOptions] = useState<
      Array<Option<number>>
    >([]);

    useEffect(() => {
      const fetchCardTypesOptions = async () => {
        setCardTypesOptions(
          makeClassificationOptions<Classifier<number>>(
            await fetchCardTypes(ownerId)
          )
        );
      };

      fetchCardTypesOptions();
    }, [ownerId]);

    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          id: 'cardType.id',
          accessor: ({ cardType }) => cardType?.id,
          type: 'select',
          editableProps: {
            options: cardTypesOptions,
            validate: validate.required(),
          },
          Header: <TransTableHead i18nKey="cardType" />,
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'cardType',
            }),
          },
        },
      ],
      [cardTypesOptions, currentDiscount]
    );

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({ cardType, discountPercentageAmount, ...rest }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountCardTypes({
            ...rest,
            cardTypeId: cardType.id,
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountCardTypes(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={cardType.map((cardType) => ({
          ...cardType,
          discountPercentageAmount: cardType.discountPercentageAmount
            ? decimalToPercentage(cardType.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
