import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { selectFareModel } from 'features/fareModel/fareModelSelectors';
import type {
  FareModelCommercialDistance,
  FareModelDistanceInterval,
  FareModelDistanceSearchParams,
} from 'dto/fareModelDistance';
import qs from 'qs';
import { FareModelOriginDestinationCommercialDistance } from 'dto/fareModelDistance';
import { FareModelStop } from 'dto/fareModel';
import { selectFareModelDistanceFaresFilter } from 'features/fareModel/fareModelDistanceSelectors';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';

/**
 * Distance ranges
 */

export const setFareModelDistanceFaresFilter = createAction<
  Partial<FareModelDistanceSearchParams>
>('fareModelDistances/setFareModelDistanceFaresFilter');

export const getFareModelDistanceFares = createAsyncThunk<
  Pagination<FareModelDistanceInterval>,
  Partial<FareModelDistanceSearchParams> | undefined
>(
  'fareModelDistances/getFareModelDistanceFares',
  async (values, { getState, dispatch }) => {
    if (values) {
      dispatch(setFareModelDistanceFaresFilter(values));
    }

    const state = getState();
    const { id } = selectFareModel(state)!;
    const filters = selectFareModelDistanceFaresFilter(state);

    return (
      await api.get<Pagination<FareModelDistanceInterval>>(
        `/fare-models/${id}/distance-fares${qs.stringify(filters, {
          addQueryPrefix: true,
        })}`
      )
    ).data;
  }
);

/**
 * Commercial distances
 */
export const getFareModelCommercialDistanceStops = createAsyncThunk<
  {
    originStops: Array<FareModelStop>;
    destinationStops: Array<FareModelStop>;
  },
  string
>(
  'fareModelCommercialDistances/getFareModelCommercialDistanceStops',
  async (id) => {
    const { originStops, destinationStops } = (
      await api.get(`/fare-models/${id}/distance-origin-destination-stops`)
    ).data;
    return { originStops, destinationStops };
  }
);

export const updateFareModelCommercialDistanceStops = createAsyncThunk<
  void,
  {
    type: 'origin' | 'destination';
    stopIds: Array<number>;
  }
>(
  'fareModelCommercialDistances/updateStops',
  async ({ type, stopIds }, { getState }) => {
    const { id } = selectFareModel(getState())!;
    await api.put(`/fare-models/${id}/distance-${type}-stops/bulk`, {
      stopIds,
    });
  }
);

export const getFareModelCommercialDistances = createAsyncThunk<
  Array<FareModelOriginDestinationCommercialDistance>,
  Partial<{
    originStopIds: Array<number>;
    destinationStopIds: Array<number>;
  }>
>(
  'fareModelCommercialDistances/searchDistances',
  async (params, { getState }) => {
    const { id } = selectFareModel(getState())!;
    return (
      await api.post(`/fare-models/${id}/commercial-distances/search`, {
        originStopIds: [],
        destinationStopIds: [],
        ...params,
      })
    ).data.commercialDistances;
  }
);

export const updateFareModelCommercialDistance = createAsyncThunk<
  FareModelCommercialDistance,
  FareModelCommercialDistance & { originId: string; destinationId: string }
>(
  'fareModelCommercialDistances/updateDistance',
  async ({ commercialDistanceId, ...payload }, { getState }) => {
    const { id } = selectFareModel(getState())!;
    return (
      await api[commercialDistanceId ? 'put' : 'post'](
        [`/fare-models`, id, 'commercial-distances', commercialDistanceId]
          .filter(Boolean)
          .join('/'),
        payload
      )
    ).data;
  }
);

export const deleteFareModelCommercialDistance = createAsyncThunk<
  void,
  FareModelCommercialDistance
>(
  'fareModelCommercialDistances/deleteDistance',
  async ({ commercialDistanceId }, { getState }) => {
    const { id: fareModelId } = selectFareModel(getState())!;
    if (!commercialDistanceId) return;
    await api.delete(
      `/fare-models/${fareModelId}/commercial-distances/${commercialDistanceId}`
    );
  }
);
