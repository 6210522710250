import { FC, useMemo } from 'react';
import { Route, useParams } from 'react-router-dom';
import { Layout, Loadable } from '@fleet/shared';
import { VouchersTemplateTable } from 'routes/VouchersTemplate/VouchersTemplateTable';
import { VouchersTemplateDetailsForm } from 'routes/VouchersTemplate/VouchersTemplateDetailsForm';
import { useSelector } from 'store/utils';
import { vouchersTemplatesLoadingSelector } from 'features/loading/loadingSelectors';

interface VouchersTemplateProps {}

export const VouchersTemplate: FC<VouchersTemplateProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const isCreation = useMemo(() => Boolean(action === 'create'), [action]);
  const isEdit = useMemo(() => Boolean(action === 'edit' && id), [action, id]);

  const loading = useSelector(vouchersTemplatesLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Route
            path={`/vouchers-template/:action(create|edit)/:id?`}
            render={() => (
              <VouchersTemplateDetailsForm
                isCreation={isCreation}
                isEdit={isEdit}
              />
            )}
            exact
          />
        }
      >
        <VouchersTemplateTable />
      </Layout>
    </Loadable>
  );
};
