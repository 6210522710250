import { FC, useCallback } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Button,
  FormControl,
  FormProvider,
  Icon,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { VoucherForm } from 'routes/Vouchers/VoucherForm';
import { useDispatch, useSelector } from 'store/utils';
import { updateVoucher } from 'features/voucher/vouchersActions';
import { currentVoucherSelector } from 'features/voucher/vouchersSelector';

interface VoucherDetailsFormProps {
  id: string;
}

export const VoucherDetailsForm: FC<VoucherDetailsFormProps> = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(currentVoucherSelector);

  const onSubmit = useCallback(
    ({ isCancelled, validUntil }) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(updateVoucher({ isCancelled, validUntil, id }));
      }),
    [dispatch, id]
  );

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: current,
  });
  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <Box sx={{ p: 3, pt: 2, pb: 2 }}>
      <Stack sx={{ mb: 2 }}>
        <Typography variant="subtitle">
          <TransSubtitle i18nKey="details" />
        </Typography>
      </Stack>
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <VoucherForm data={current}>
            <Grid item xs="auto" sx={{ ml: 'auto', mt: 4 }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetChanges" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<Icon name="check" />}
                  >
                    <TransButton i18nKey="save" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </VoucherForm>
        </form>
      </FormProvider>
    </Box>
  );
};
