import { createSelector, selector } from 'store/utils';

export const selectPassengerTypeDiscounts = selector(
  (state) => state.passengerTypeDiscount.list
);
export const passengerTypeDiscountsSelector = createSelector(
  selectPassengerTypeDiscounts
);

export const selectPassengerTypeDiscountsFilter = selector(
  (state) => state.passengerTypeDiscount.filter
);
export const passengerTypeDiscountsFilterSelector = createSelector(
  selectPassengerTypeDiscountsFilter
);

export const selectPassengerTypeDiscount = selector(
  (state) => state.passengerTypeDiscount.current
);
export const passengerTypeDiscountSelector = createSelector(
  selectPassengerTypeDiscount
);
