import { FC, useCallback, useMemo } from 'react';
import {
  Card,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  api,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { Button, CardContent, Stack, Typography } from '@mui/material';
import { useRowSelect } from 'react-table';
import { useRowEditActions } from '@fleet/shared/hooks';
import { TransTableHead } from 'i18n/trans/table';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { TransTitle } from 'i18n/trans/title';
import { renderToString } from 'react-dom/server';
import { TransField } from 'i18n/trans/field';
import { getFareModelDistanceFares } from 'features/fareModel/fareModelDistanceActions';
import { FareModelDistanceFare } from 'dto/fareModelDistance';

interface FareModelDistanceRangeFaresTableProps {
  distanceIntervalId: string;
  fares: Array<FareModelDistanceFare>;
}

export const FareModelDistanceRangeFaresTable: FC<FareModelDistanceRangeFaresTableProps> =
  ({ fares: data, distanceIntervalId }) => {
    const currentFareModel = useSelector(fareModelSelector)!;
    const dispatch = useDispatch();
    const currencyOptions = useClassificationOptions(
      ClassificationGroup.CURRENCY
    );
    const fareCategoryOptions = useClassificationOptions(
      ClassificationGroup.FARE_CATEGORY
    );

    const columns = useMemo<TableColumns<FareModelDistanceFare>>(
      () => [
        {
          accessor: 'fare',
          Header: <TransTableHead i18nKey="fare" />,
          conditions: {
            disabled: {
              when: 'coefficient',
              is: (val) => !!val,
            },
          },
          editableProps: {
            required: false,
            type: 'number',
          },
        },
        {
          id: 'currency.id',
          accessor: ({ currency }) => currency?.id,
          Header: <TransTableHead i18nKey="currency" />,
          type: 'select',
          editableProps: {
            options: currencyOptions,
          },
        },
        {
          id: 'fareCategory.id',
          accessor: ({ fareCategory }) => fareCategory?.id,
          Header: <TransTableHead i18nKey="category" />,
          type: 'select',
          editableProps: {
            validate: (value, row) =>
              value && value === row?.baseFareCategory?.id
                ? renderToString(
                    <TransField
                      i18nKey="sameValue"
                      values={{
                        field: renderToString(
                          <TransTableHead i18nKey="fareBasedOnCategory" />
                        ),
                      }}
                    />
                  )
                : undefined,
            options: fareCategoryOptions,
          },
        },
        {
          id: 'baseFareCategory.id',
          accessor: ({ baseFareCategory }) => baseFareCategory?.id,
          Header: <TransTableHead i18nKey="fareBasedOnCategory" />,
          type: 'select',
          editableProps: {
            required: false,
            validate: (value, row) => {
              if (value && value === row?.fareCategory?.id) {
                return renderToString(
                  <TransField
                    i18nKey="sameValue"
                    values={{
                      field: renderToString(
                        <TransTableHead i18nKey="category" />
                      ),
                    }}
                  />
                );
              } else if (!value && row?.coefficient) {
                return renderToString(<TransField i18nKey="required" />);
              } else {
                return undefined;
              }
            },
            options: fareCategoryOptions,
            showEmptyOption: true,
          },
        },
        {
          id: 'coefficient',
          accessor: ({ coefficient }) => coefficient,
          Header: <TransTableHead i18nKey="coefficient" />,
          conditions: {
            disabled: {
              when: 'fare',
              is: (val) => !!val,
            },
          },
          editableProps: {
            required: false,
            type: 'number',
          },
        },
      ],
      [currencyOptions, fareCategoryOptions]
    );

    const { form } = useForm<{ rows: Array<FareModelDistanceFare> }>({
      initialValues: {
        rows: data,
      },
    });

    const handleRowUpdate = useCallback(
      async ({
        id,
        currency,
        fareCategory,
        baseFareCategory,
        ...rest
      }: FareModelDistanceFare) => {
        await (id ? api.put : api.post)(
          `/fare-models/${currentFareModel.id}/distance-fares${
            id ? `/${id}` : ''
          }`,
          {
            ...rest,
            fareModelDistanceIntervalId: distanceIntervalId,
            currencyId: currency?.id,
            fareCategoryId: fareCategory?.id,
            baseFareCategoryId: baseFareCategory?.id,
          }
        );

        dispatch(getFareModelDistanceFares());
      },
      [currentFareModel.id, dispatch, distanceIntervalId]
    );

    const table = useFormTable<FareModelDistanceFare>(
      {
        data,
        columns,
        form,
        initialState: {
          selectedRowIds: {},
        },
        onRowUpdate: handleRowUpdate,
      },
      useRowSelect,
      useIndeterminateRowSelectCheckbox,
      useRowEditActions
    );

    const onRowsRemoved = useCallback(
      async (rows: Array<FareModelDistanceFare>) => {
        await Promise.all(
          rows.map((row) =>
            api.delete(
              `/fare-models/${currentFareModel.id}/distance-fares/${row.id}`
            )
          )
        );

        dispatch(getFareModelDistanceFares());
      },
      [currentFareModel.id, dispatch]
    );

    const { addRow, removeSelectedRows } = useFormTableControls({
      table,
      form,
      removeQuery: onRowsRemoved,
    });

    return (
      <Card sx={{ backgroundColor: 'background.default' }}>
        <CardContent>
          <FormProvider form={form}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontWeight="bold">
                <TransTitle i18nKey="fares" />
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                sx={{ mb: 1, gap: '10px' }}
              >
                <Button
                  startIcon={<Icon name="delete" />}
                  onClick={removeSelectedRows}
                  disabled={!Object.keys(table.state.selectedRowIds).length}
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
                <Button startIcon={<Icon name="plus" />} onClick={addRow}>
                  <TransButton i18nKey="addNew" />
                </Button>
              </Stack>
            </Stack>
            <Table
              table={table}
              getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
            />
          </FormProvider>
        </CardContent>
      </Card>
    );
  };
