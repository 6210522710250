import type { FC, ChangeEvent, MouseEventHandler } from 'react';
import type { FareModelStopsMatrixCellContentProps } from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FareModelCommercialDistancesContext } from 'routes/FareModels/FareModelDistances/FareModelCommercialDistancesContext';
import _debounce from 'lodash/debounce';
import TextField from '@mui/material/TextField';
import { FareModelCommercialDistance } from 'dto/fareModelDistance';
import { useDispatch } from 'store/utils';
import {
  deleteFareModelCommercialDistance,
  updateFareModelCommercialDistance,
} from 'features/fareModel/fareModelDistanceActions';

export const FareModelCommercialDistanceField: FC<FareModelStopsMatrixCellContentProps> =
  ({ originStopId, destinationStopId }) => {
    const { distancesMap, changeDistance } = useContext(
      FareModelCommercialDistancesContext
    );
    const distance = useMemo(
      () =>
        distancesMap.get(originStopId)?.get(destinationStopId) ??
        ({} as FareModelCommercialDistance),
      [destinationStopId, distancesMap, originStopId]
    );
    const [value, setValue] = useState<string>(``);
    useEffect(() => {
      setValue((distance && `${distance?.distanceKm ?? ''}`) ?? '');
    }, [distance]);

    const dispatch = useDispatch();
    const changeHandler = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        let data: FareModelCommercialDistance | undefined;
        if (!value) await dispatch(deleteFareModelCommercialDistance(distance));
        else {
          data = await dispatch(
            updateFareModelCommercialDistance({
              originId: originStopId,
              destinationId: destinationStopId,
              ...distance,
              distanceKm: value as unknown as number,
            })
          ).unwrap();
        }
        changeDistance(originStopId, destinationStopId, data);
      },
      [changeDistance, destinationStopId, dispatch, distance, originStopId]
    );

    const debouncedEventHandler = useMemo(
      () => _debounce(changeHandler, 500),
      [changeHandler]
    );

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        debouncedEventHandler(event);
      },
      [debouncedEventHandler]
    );

    const handleFocus = useCallback<MouseEventHandler<HTMLInputElement>>(
      (event) => {
        event.stopPropagation();
      },
      []
    );

    return (
      <TextField
        variant="outlined"
        value={value}
        onChange={handleChange}
        onClick={handleFocus}
      />
    );
  };
