import { makeStyles } from '@mui/styles';
import {
  Button,
  ExternalLink,
  FormControl,
  FormField,
  FormFieldProps,
  Modal,
  useModal,
} from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import { FinalFieldAdapterProps } from '@fleet/shared/form/FormField';
import { TransField } from 'i18n/trans/field';
import { TransHint } from 'i18n/trans/hint';
import { TransButton } from 'i18n/trans/button';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Fragment } from 'react';
import { TransTableHead } from 'i18n/trans/table';

const useStyles = makeStyles(
  (theme) => ({
    value: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginRight: theme.spacing(),
      padding: '6px 0',
    },
    more: {
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(),
    },
  }),
  {
    name: 'PromotionsField',
  }
);

interface PromotionsFieldAdapterProps<TValue extends Classifier>
  extends FinalFieldAdapterProps<Array<TValue>, HTMLDivElement> {}
interface PromotionsFieldProps<TValue extends Classifier>
  extends FormFieldProps<Array<TValue>> {}

export function PromotionsFieldAdapter<TValue extends Classifier>(
  props: PromotionsFieldAdapterProps<TValue>
) {
  const { input } = props;
  const { value } = input;
  const [promotion, ...promotions] = value;
  const { length } = promotions;
  const { open: isOpen, onOpen, onClose } = useModal();
  const classes = useStyles();

  return (
    <>
      <FormControl label={<TransField i18nKey="promotions" />}>
        <Stack direction="row" alignItems="center">
          {promotion && (
            <span className={classes.value}>
              <ExternalLink
                path={`/General/Campaign/Edit/${promotion.id}`}
                content={promotion.name}
              />
            </span>
          )}
          {Boolean(length > 1) && (
            <>
              <span className={classes.more}>
                <TransHint i18nKey="plusMore" values={{ num: length }} />
              </span>
              <Button
                variant="text"
                size="small"
                onClick={onOpen}
                sx={{ px: 0, minWidth: 'unset' }}
              >
                <TransButton i18nKey="viewAll" />
              </Button>
            </>
          )}
        </Stack>
      </FormControl>
      <Modal
        title={<TransField i18nKey="promotions" />}
        open={isOpen}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TransTableHead i18nKey="promotionName" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map(({ id, name }) => (
              <TableRow key={id}>
                <TableCell>
                  <ExternalLink
                    path={`/General/Campaign/Edit/${id}`}
                    content={name}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Modal>
    </>
  );
}

export const PromotionsField = <TValue extends Classifier>(
  props: PromotionsFieldProps<TValue>
) => (
  <FormField component={PromotionsFieldAdapter} {...props} defaultValue={[]} />
);
