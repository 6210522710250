import { createSelector, selector } from 'store/utils';

export const selectFareModelDistanceFaresFilter = selector(
  (state) => state.fareModel.distanceFaresFilter
);
export const fareModelDistanceFaresFilterSelector = createSelector(
  selectFareModelDistanceFaresFilter
);
export const selectFareModelDistanceFares = selector(
  (state) => state.fareModel.distanceFares
);
export const fareModelDistancesFaresSelector = createSelector(
  selectFareModelDistanceFares
);
export const selectFareModelOriginDestinationCommercialDistances = selector(
  (state) => state.fareModel.commercialDistances
);
export const fareModelOriginDestinationCommercialDistances = createSelector(
  selectFareModelOriginDestinationCommercialDistances
);
export const selectFareModelCommercialDistanceOriginStops = selector(
  (state) => state.fareModel.commercialDistanceOriginStops
);
export const fareModelCommercialDistanceOriginStopsSelector = createSelector(
  selectFareModelCommercialDistanceOriginStops
);
export const selectFareModelCommercialDistanceDestinationStops = selector(
  (state) => state.fareModel.commercialDistanceDestinationStops
);
export const fareModelCommercialDistanceDestinationStopsSelector =
  createSelector(selectFareModelCommercialDistanceDestinationStops);
