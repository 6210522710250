import { makeStyles } from '@mui/styles';
import {
  FareModelStopsDetailsForm,
  FareModelStopsDetailsFormProps,
} from 'routes/FareModels/FareModelStops/FareModelStopsDetailsForm';
import { FareModelCommercialDistancesFilterForm } from 'routes/FareModels/FareModelDistances/FareModelCommercialDistancesFilterForm';
import { Divider, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { FareModelStopsContextProvider } from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { TransSearch } from 'i18n/trans/search';
import { SearchResult } from '@fleet/shared';
import { FareModelCommercialDistanceResult } from 'routes/FareModels/FareModelDistances/FareModelCommercialDistanceResult';
import { FareModelCommercialDistancesContextProvider } from 'routes/FareModels/FareModelDistances/FareModelCommercialDistancesContext';
import {
  fareModelCommercialDistanceDestinationStopsSelector,
  fareModelCommercialDistanceOriginStopsSelector,
  fareModelOriginDestinationCommercialDistances,
} from 'features/fareModel/fareModelDistanceSelectors';
import { useParams } from 'react-router-dom';
import {
  getFareModelCommercialDistanceStops,
  updateFareModelCommercialDistanceStops,
} from 'features/fareModel/fareModelDistanceActions';
import { fareModelDistancesLoadingSelector } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    content: {
      flex: 1,
      height: '100%',
    },
    divider: {
      margin: `${theme.spacing(3)} ${theme.spacing(-3)}`,
    },
  }),
  {
    name: 'FareModelCommercialDistances',
  }
);

export const FareModelCommercialDistances = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const loading = useSelector(fareModelDistancesLoadingSelector);
  const originStops = useSelector(
    fareModelCommercialDistanceOriginStopsSelector
  );
  const destinationStops = useSelector(
    fareModelCommercialDistanceDestinationStopsSelector
  );
  const originDestinationCommercialDistances = useSelector(
    fareModelOriginDestinationCommercialDistances
  );
  const dispatch = useDispatch();
  const handleChangeStops = useCallback<
    FareModelStopsDetailsFormProps['onChangeStops']
  >(
    async (name, stops) => {
      await dispatch(
        updateFareModelCommercialDistanceStops({
          type: name as 'origin' | 'destination',
          stopIds: Array.from(stops.keys()),
        })
      );
      await dispatch(getFareModelCommercialDistanceStops(id));
    },
    [dispatch, id]
  );

  const divider = useMemo(
    () => <Divider className={classes.divider} />,
    [classes.divider]
  );

  return (
    <div className={classes.root}>
      <Typography variant="subtitle" fontWeight="700" paragraph>
        <TransSubtitle i18nKey="details" />
      </Typography>
      <FareModelStopsDetailsForm
        originStops={originStops}
        destinationStops={destinationStops}
        onChangeStops={handleChangeStops}
      />
      {divider}
      <FareModelStopsContextProvider
        view="matrix"
        id={id}
        originStops={originStops}
        destinationStops={destinationStops}
      >
        <FareModelCommercialDistancesFilterForm />
        {divider}
        <SearchResult
          results={originStops.length}
          loading={loading}
          message={<TransSearch i18nKey="noStopsFound" />}
        >
          <FareModelCommercialDistancesContextProvider
            distances={originDestinationCommercialDistances}
          >
            <div className={classes.content}>
              <FareModelCommercialDistanceResult />
            </div>
          </FareModelCommercialDistancesContextProvider>
        </SearchResult>
      </FareModelStopsContextProvider>
    </div>
  );
};
