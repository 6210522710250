import { createAsyncThunk } from 'store/utils';
import { getPromotionalDiscount } from 'features/promotionalDiscount/promotionalDiscountActions';
import { selectPromotionalDiscount } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { api } from '@fleet/shared';

function createOrUpdatePromotionalDiscountTabRow<
  D extends { id?: unknown; [key: string]: unknown }
>(tab: string) {
  return createAsyncThunk<D, Partial<D>>(
    `promotionalDiscountTab/createOrUpdatePromotionalDiscountTabRow/${tab}`,
    async ({ id, ...payload }, { dispatch, getState }) => {
      const pmdId = selectPromotionalDiscount(getState())!.id;
      const { data } = await (id ? api.put : api.post)(
        id
          ? `/promotional-discounts/${tab}/${id}`
          : `/promotional-discounts/${pmdId}/${tab}`,
        payload,
        {
          baseURL: process.env.REACT_APP_API_DM,
        }
      );
      await dispatch(getPromotionalDiscount(pmdId)).unwrap();
      return data;
    }
  );
}
function removePromotionalDiscountTabRow<D extends { id: unknown }>(
  tab: string
) {
  return createAsyncThunk<void, Array<D>>(
    `promotionalDiscountTab/removePromotionalDiscountTabRow/${tab}`,
    async (rows, { dispatch, getState }) => {
      await Promise.all(
        rows.map(({ id }) =>
          api.delete(`/promotional-discounts/${tab}/${id}`, {
            baseURL: process.env.REACT_APP_API_DM,
          })
        )
      );
      await dispatch(
        getPromotionalDiscount(selectPromotionalDiscount(getState())!.id)
      );
    }
  );
}

/**
 * Information
 */
export const createOrUpdatePromotionalDiscountInformation =
  createOrUpdatePromotionalDiscountTabRow('information');
export const removePromotionalDiscountInformation =
  removePromotionalDiscountTabRow('information');
/**
 * Booking quantities
 */
export const createOrUpdatePromotionalDiscountBookingQuantity =
  createOrUpdatePromotionalDiscountTabRow('booking-quantities');
export const removePromotionalDiscountBookingQuantities =
  removePromotionalDiscountTabRow('booking-quantities');
/**
 * Transaction times
 */
export const createOrUpdatePromotionalDiscountTransactionTime =
  createOrUpdatePromotionalDiscountTabRow('transaction-times');
export const removePromotionalDiscountTransactionTimes =
  removePromotionalDiscountTabRow('transaction-times');
/**
 * Departure days
 */
export const createOrUpdatePromotionalDiscountDepartureDay =
  createOrUpdatePromotionalDiscountTabRow('departure-days');
export const removePromotionalDiscountDepartureDays =
  removePromotionalDiscountTabRow('departure-days');
/**
 * Sales channels
 */
export const createOrUpdatePromotionalDiscountSalesChannel =
  createOrUpdatePromotionalDiscountTabRow('sales-channels');
export const removePromotionalDiscountSalesChannels =
  removePromotionalDiscountTabRow('sales-channels');
/**
 * Line types
 */
export const createOrUpdatePromotionalDiscountLineType =
  createOrUpdatePromotionalDiscountTabRow('line-types');
export const removePromotionalDiscountLineTypes =
  removePromotionalDiscountTabRow('line-types');
/**
 * Passenger groups
 */
export const createOrUpdatePromotionalDiscountPassengerGroup =
  createOrUpdatePromotionalDiscountTabRow('passenger-groups');
export const removePromotionalDiscountPassengerGroups =
  removePromotionalDiscountTabRow('passenger-groups');
/**
 * Card types
 */
export const createOrUpdatePromotionalDiscountCardTypes =
  createOrUpdatePromotionalDiscountTabRow('card-types');
export const removePromotionalDiscountCardTypes =
  removePromotionalDiscountTabRow('card-types');
/**
 * Accompanying passenger
 */
export const createOrUpdatePromotionalDiscountAccompanyingPassenger =
  createOrUpdatePromotionalDiscountTabRow('accompanying-passenger-types');
export const removePromotionalDiscountAccompanyingPassenger =
  removePromotionalDiscountTabRow('accompanying-passenger-types');
/**
 * Feeder/defeeder
 */
export const createOrUpdatePromotionalDiscountFeederDefeeder =
  createOrUpdatePromotionalDiscountTabRow('feeder-defeeders');
export const removePromotionalDiscountFeederDefeeder =
  removePromotionalDiscountTabRow('feeder-defeeders');
