import type { FC } from 'react';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransField } from 'i18n/trans/field';
import { SelectField, SelectFieldProps } from '@fleet/shared';

interface SelectCurrencyFieldProps
  extends Partial<SelectFieldProps<string, false>> {}

export const SelectCurrencyField: FC<SelectCurrencyFieldProps> = (props) => {
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );

  return (
    <SelectField
      name="currencyId"
      label={<TransField i18nKey="currency" />}
      options={currencyOptions}
      {...props}
    />
  );
};
