import { FC, useCallback, useMemo } from 'react';
import {
  FormField,
  TableColumns,
  TextField,
  decimalToPercentage,
  percentageToDecimal,
  renderPercentageError,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import {
  createOrUpdatePromotionalDiscountAccompanyingPassenger,
  removePromotionalDiscountAccompanyingPassenger,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import { isDiscountAmountFieldDisabled } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { validate } from '@fleet/shared/form/validate';
import { Stack } from '@mui/material';

interface PromotionalDiscountTabAccompanyingPassengerTypeRestrictionsProps {}

type Entity =
  PromotionalDiscount['accompanyingPassengerTypeRestrictions'][number];

export const PromotionalDiscountTabAccompanyingPassengerTypeRestrictions: FC<PromotionalDiscountTabAccompanyingPassengerTypeRestrictionsProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { accompanyingPassengerTypeRestrictions } = currentDiscount;
    const passengerTypeOptions = useClassificationOptions(
      ClassificationGroup.PASSENGER_TYPE
    );

    const renderCountCell = useCallback(({ cell: { row, column } }) => {
      const { state, original } = row;

      if (state.editable) {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <FormField
              name={`rows[${row.id}].${column.id}From`}
              validate={validate.required()}
              render={({ input }) => <TextField {...input} type="number" />}
            />
            <span>-</span>
            <FormField
              name={`rows[${row.id}].${column.id}To`}
              validate={validate.required()}
              render={({ input }) => <TextField {...input} type="number" />}
            />
          </Stack>
        );
      }

      const [columnId] = column.id.split('.');

      return (
        <Stack direction="row" gap={1}>
          <span>{original[columnId]?.['countFrom']}</span>
          <span>-</span>
          <span>{original[columnId]?.['countTo']}</span>
        </Stack>
      );
    }, []);

    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          id: 'passengerTypes.types',
          accessor: ({ passengerTypes }) =>
            passengerTypes?.types.map(({ id }) => id),
          Header: <TransTableHead i18nKey="passengerType" />,
          type: 'select',
          editableProps: {
            multiple: true,
            options: passengerTypeOptions,
            valueAccessor: 'id',
            validate: validate.required(),
          },
        },
        {
          id: 'passengerTypes.count',
          Header: <TransTableHead i18nKey="passengerAmount" />,
          Cell: renderCountCell,
        },
        {
          id: 'accompanyingPassengerTypes.types',
          accessor: ({ accompanyingPassengerTypes }) =>
            accompanyingPassengerTypes?.types.map(({ id }) => id),
          Header: <TransTableHead i18nKey="accompanyingPassengerType" />,
          type: 'select',
          editableProps: {
            multiple: true,
            options: passengerTypeOptions,
            valueAccessor: 'id',
            validate: validate.required(),
          },
          width: '180px',
        },
        {
          id: 'accompanyingPassengerTypes.count',
          Header: <TransTableHead i18nKey="accompanyingPassengerAmount" />,
          Cell: renderCountCell,
          width: '200px',
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'accompanyingPassengerTypeRestrictions',
            }),
          },
        },
      ],
      [currentDiscount, passengerTypeOptions, renderCountCell]
    );

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({
        passengerTypes: { types: passengerTypes, ...restOfPassengerTypes },
        accompanyingPassengerTypes: {
          types: accompanyingPassengerTypes,
          ...restOfAccompanyingPassengerTypes
        },
        discountPercentageAmount,
        ...rest
      }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountAccompanyingPassenger({
            ...rest,
            passengerTypes: {
              ...restOfPassengerTypes,
              ids: passengerTypes.map(({ id }) => id),
            },
            accompanyingPassengerTypes: {
              ...restOfAccompanyingPassengerTypes,
              ids: accompanyingPassengerTypes.map(({ id }) => id),
            },
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountAccompanyingPassenger(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={accompanyingPassengerTypeRestrictions.map((restriction) => ({
          ...restriction,
          discountPercentageAmount: restriction.discountPercentageAmount
            ? decimalToPercentage(restriction.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
