import type { FC } from 'react';
import { useCallback } from 'react';
import { getFareModelCommercialDistances } from 'features/fareModel/fareModelDistanceActions';
import type { FareModelStopsSelectValue } from 'routes/FareModels/FareModelStopsSelect/FareModelStopsSelect';
import { useDispatch } from 'store/utils';
import {
  FareModelFilterForm,
  FareModelFilterFormProps,
} from 'routes/FareModels/FareModelFilterForm';

interface FareModelCommercialDistancesFilterFormProps {}

interface FormValues {
  originStops: FareModelStopsSelectValue;
  destinationStops: FareModelStopsSelectValue;
}

export const FareModelCommercialDistancesFilterForm: FC<FareModelCommercialDistancesFilterFormProps> =
  () => {
    const dispatch = useDispatch();
    const onSubmit = useCallback<
      FareModelFilterFormProps<FormValues>['onSubmit']
    >(
      async ({ originStopIds, destinationStopIds, ...values }) => {
        await dispatch(
          getFareModelCommercialDistances({
            originStopIds,
            destinationStopIds,
            ...values,
          })
        );
      },
      [dispatch]
    );

    return <FareModelFilterForm<FormValues> onSubmit={onSubmit} />;
  };
