import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Pagination } from '@fleet/shared/dto/pagination';
import {
  FinalFieldAdapterProps,
  metaError,
} from '@fleet/shared/form/FormField';
import { FareModelStop } from 'dto/fareModel';
import { Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransHint } from 'i18n/trans/hint';
import { TransModal } from 'i18n/trans/modal';
import { makeStyles } from '@mui/styles';
import {
  Button,
  FormControl,
  FormField,
  FormFieldProps,
  Icon,
  Modal,
  ModalProps,
  useModal,
} from '@fleet/shared';
import {
  FareModelStopsSelectTable,
  FareModelStopsSelectTableProps,
  FareModelStopsSelectTableRow,
} from 'routes/FareModels/FareModelStopsSelect/FareModelStopsSelectTable';
import { copy as copyMap } from 'helpers/map';

const useStyles = makeStyles(
  (theme) => ({
    value: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginRight: theme.spacing(),
    },
    more: {
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(),
    },
    modal: {
      padding: theme.spacing(3),
      width: '100%',
      '& .MuiDialog-container': {
        width: '100%',
      },
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: theme.breakpoints.values.lg,
        minHeight: '100%',
        '& .MuiDialogContent-root': {
          display: 'flex',
          minHeight: 0,
          overflow: 'unset',
          flexDirection: 'column',
          '& .MuiTablePagination-root': {
            overflow: 'unset',
          },
        },
      },
    },
  }),
  {
    name: 'FareModelStopsSelect',
  }
);

export type FareModelStopsSelectValue = Map<
  number,
  FareModelStopsSelectTableRow
>;

export interface FareModelStopsSelectProps {
  label: ReactNode;
  onConfirm?: (name: string, stops: FareModelStopsSelectValue) => void;
  selectedStops?: Array<FareModelStop['stop']>;
  allowSearch?: boolean;
  required?: boolean;
}

interface FareModelStopsSelectAdapterProps<
  TValue extends FareModelStopsSelectValue
> extends FinalFieldAdapterProps<TValue, HTMLDivElement>,
    Partial<FareModelStopsSelectProps> {}

export const mapStops = (stops: Array<FareModelStop> = []) =>
  new Map(
    stops.map(({ stop }) => [
      stop.id,
      {
        ...stop,
        countryId: stop.country?.id,
        countryName: stop.country?.name,
        countyName: stop.county?.name,
        cityName: stop.city?.name,
      },
    ])
  );
const copyValue = <TValue extends FareModelStopsSelectValue>(value: TValue) =>
  copyMap<number, FareModelStopsSelectTableRow>(value);

function FareModelStopsSelectAdapter<TValue extends FareModelStopsSelectValue>({
  label,
  input,
  onConfirm,
  selectedStops: propSelectedStops,
  allowSearch = false,
  required,
  meta,
}: FareModelStopsSelectAdapterProps<TValue>) {
  const [stops, setStops] = useState<Pagination<FareModelStopsSelectTableRow>>({
    items: [],
    totalCount: 0,
    offset: 0,
  });
  const { value, name, onChange } = input;
  const selectedStops = useMemo(
    () => propSelectedStops ?? Array.from(value.values()),
    [propSelectedStops, value]
  );
  const { 0: firstStop, length: stopsLength } = useMemo(
    () => Array.from(value.values()),
    [value]
  );
  const [draftValue, setDraftValue] = useState(copyValue(value));

  const selectedRowIds = useMemo(
    () =>
      Array.from(draftValue.values()).reduce(
        (acc, { id }) => ({ ...acc, [id]: true }),
        {}
      ),
    [draftValue]
  );
  const { open: isOpen, onOpen, onClose } = useModal();

  useEffect(() => {
    // clean draftValue on form reset
    setDraftValue(copyValue(value));
  }, [value]);
  const handleChange = useCallback<FareModelStopsSelectTableProps['onChange']>(
    (add, rows) => {
      const stops = copyValue(draftValue);
      rows.forEach((row) => {
        const { id } = row;
        if (add) stops.set(id, row);
        else stops.delete(id);
      });
      setDraftValue(stops);
    },
    [draftValue]
  );

  const handleClose = useCallback<Required<ModalProps>['onClose']>(
    (e, reason) => {
      if (reason === 'escapeKeyDown') setDraftValue(copyValue(value));
      onClose();
    },
    [onClose, value]
  );
  const handleConfirm = useCallback(() => {
    const stops = copyValue(draftValue);
    onChange(stops);
    onConfirm?.(name, stops);
    onClose();
  }, [draftValue, name, onChange, onClose, onConfirm]);

  const classes = useStyles();
  return (
    <>
      <FormControl label={label} required={required} error={metaError(meta)}>
        <Stack direction="row" alignItems="center">
          {firstStop && <span className={classes.value}>{firstStop.name}</span>}
          {Boolean(stopsLength > 1) && (
            <span className={classes.more}>
              <TransHint i18nKey="plusMore" values={{ num: stopsLength - 1 }} />
            </span>
          )}
          <Button
            variant="text"
            size="small"
            onClick={onOpen}
            sx={{ px: 0, minWidth: 'unset' }}
          >
            <TransButton i18nKey="addEdit" />
          </Button>
        </Stack>
      </FormControl>
      <Modal
        open={isOpen}
        onClose={handleClose}
        classes={{ root: classes.modal }}
        title={<TransModal i18nKey="selectStops" />}
        actionButton={{
          label: <TransButton i18nKey="confirm" />,
          startIcon: <Icon name="check" />,
          onClick: handleConfirm,
        }}
        fullWidth
      >
        <FareModelStopsSelectTable
          selectedStops={selectedStops}
          stops={stops}
          setStops={setStops}
          selectedRowIds={selectedRowIds}
          onChange={handleChange}
          allowSearch={allowSearch}
        />
      </Modal>
    </>
  );
}

export const FareModelStopsSelect = <TValue extends FareModelStopsSelectValue>(
  props: FormFieldProps<TValue> & FareModelStopsSelectProps
) => (
  <FormField
    component={FareModelStopsSelectAdapter}
    {...props}
    allowNull={false}
    defaultValue={new Map() as TValue}
  />
);
