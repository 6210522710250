import {
  TableColumns,
  decimalToPercentage,
  percentageToDecimal,
  renderPercentageError,
  makeClassificationOptions,
} from '@fleet/shared';
import { Option } from '@fleet/shared/dto/option';
import { Classifier } from '@fleet/shared/dto/classifier';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { fetchInventoryClasses } from 'features/classification/classificationService';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import {
  createOrUpdatePromotionalDiscountFeederDefeeder,
  removePromotionalDiscountFeederDefeeder,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { useDispatch, useSelector } from 'store/utils';
import { validate } from '@fleet/shared/form/validate';
import { isDiscountAmountFieldDisabled } from './utils';

interface PromotionalDiscountTabFeederDefeederProps {}

type Entity = PromotionalDiscount['feederDefeeder'][number];

export const PromotionalDiscountTabFeederDefeeder: FC<PromotionalDiscountTabFeederDefeederProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { feederDefeeder, ownerId } = currentDiscount;
    const [inventoryClassesOptions, setInventoryClassesOptions] = useState<
      Array<Option<string>>
    >([]);

    useEffect(() => {
      const fetchInventoryClassesOptions = async () => {
        setInventoryClassesOptions(
          makeClassificationOptions<Classifier>(
            await fetchInventoryClasses(ownerId)
          )
        );
      };

      fetchInventoryClassesOptions();
    }, [ownerId]);

    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          id: 'currentInventoryClasses',
          accessor: ({ currentInventoryClasses }) =>
            currentInventoryClasses?.map(({ id }) => id),
          Header: <TransTableHead i18nKey="currentLeg" />,
          type: 'select',
          editableProps: {
            multiple: true,
            options: inventoryClassesOptions,
            valueAccessor: 'id',
            validate: validate.required(),
          },
        },
        {
          id: 'previousNextInventoryClasses',
          accessor: ({ previousNextInventoryClasses }) =>
            previousNextInventoryClasses?.map(({ id }) => id),
          Header: <TransTableHead i18nKey="previousNextLeg" />,
          type: 'select',
          editableProps: {
            multiple: true,
            options: inventoryClassesOptions,
            valueAccessor: 'id',
            validate: validate.required(),
          },
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'feederDefeeder',
            }),
          },
        },
      ],
      [currentDiscount, inventoryClassesOptions]
    );

    const dispatch = useDispatch();
    const onRowsUpdate = useCallback(
      async ({
        currentInventoryClasses,
        previousNextInventoryClasses,
        discountPercentageAmount,
        ...rest
      }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountFeederDefeeder({
            ...rest,
            currentInventoryClassIds: currentInventoryClasses?.map(
              ({ id }) => id
            ),
            previousNextInventoryClassIds: previousNextInventoryClasses?.map(
              ({ id }) => id
            ),
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountFeederDefeeder(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={feederDefeeder.map((feeder) => ({
          ...feeder,
          discountPercentageAmount: feeder.discountPercentageAmount
            ? decimalToPercentage(feeder.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowsUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
