import { useSelector } from 'store/utils';
import { currentVoucherSelector } from 'features/voucher/vouchersSelector';
import { useMemo } from 'react';
import { Table, TableColumns } from '@fleet/shared';
import { Activity } from 'dto/voucher';
import { useTable } from 'react-table';
import { makeStyles } from '@mui/styles';
import { TransTableHead } from 'i18n/trans/table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';

const useStyles = makeStyles(
  () => ({
    table: {
      marginBottom: '28px',
    },
  }),
  {
    name: 'ActivityLogTab',
  }
);

export const ActivityLog = () => {
  const classes = useStyles();
  const current = useSelector(currentVoucherSelector);

  //TODO field refundedTo is missing
  const columns = useMemo<TableColumns<Activity>>(
    () => [
      {
        accessor: 'bookingNumber',
        Header: <TransTableHead i18nKey="bookingNumber" />,
      },
      {
        accessor: 'ticketsInBasket',
        Header: <TransTableHead i18nKey="numberOfTickets" />,
      },
      {
        accessor: 'activityTime',
        Header: <TransTableHead i18nKey="date" />,
        Cell: ({ value }) => formatDate(value.value, currentDateTimeFormat),
      },
      {
        accessor: 'amount',
        Header: <TransTableHead i18nKey="paidWith" />,
        Cell: ({ value, row }) =>
          `${value.toFixed(2)} ${row.original.currency.name}`,
      },
      {
        accessor: 'salesUnit',
        Header: <TransTableHead i18nKey="salesUnit" />,
        Cell: ({ value }) => value?.name || '-',
      },
      {
        accessor: 'salesPoint',
        Header: <TransTableHead i18nKey="salesPoint" />,
        Cell: ({ value }) => value?.name || '-',
      },
    ],
    []
  );
  const table = useTable({
    data: current?.activities ?? [],
    columns,
  });

  return (
    <>
      <Table classes={{ table: classes.table }} table={table} />
    </>
  );
};
