import { classificationReducer } from 'features/classification/classificationReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { commonReducer } from 'features/common/commonReducer';
import { fareModelReducer } from 'features/fareModel/fareModelReducer';
import { loadingReducer } from 'features/loading/loadingReducer';
import { passengerTypeReducer } from 'features/passengerType/passengerTypeReducer';
import { passengerTypeDiscountReducer } from 'features/passengerTypeDiscount/passengerTypeDiscountReducer';
import { promotionalDiscountReducer } from 'features/promotionalDiscount/promotionalDiscountReducer';
import { coTaxationGroupReducer } from 'features/coTaxationGroup/coTaxationGroupReducer';
import { vouchersTemplateReducer } from 'features/vouchersTemplate/vouchersTemplateReducer';
import { vouchersReducer } from 'features/voucher/vouchersReducer';
import { zonePriceListReducer } from 'features/zonePriceList/zonePriceListReducer';
import { zoneMapReducer } from 'features/zoneMap/zoneMapReducer';

export default combineReducers({
  common: commonReducer,
  loading: loadingReducer,
  passengerType: passengerTypeReducer,
  passengerTypeDiscount: passengerTypeDiscountReducer,
  promotionalDiscount: promotionalDiscountReducer,
  classification: classificationReducer,
  coTaxationGroup: coTaxationGroupReducer,
  vouchersTemplate: vouchersTemplateReducer,
  vouchers: vouchersReducer,
  zoneMap: zoneMapReducer,
  zonePriceList: zonePriceListReducer,
  fareModel: fareModelReducer,
});
