import { createSelector, selector } from 'store/utils';

export const selectFareModels = selector((state) => state.fareModel.list);
export const fareModelsSelector = createSelector(selectFareModels);

export const selectFareModel = selector((state) => state.fareModel.current);
export const fareModelSelector = createSelector(selectFareModel);

export const selectFareModelOriginStops = selector(
  (state) => state.fareModel.originStops
);
export const fareModelOriginStopsSelector = createSelector(
  selectFareModelOriginStops
);

export const selectFareModelDestinationStops = selector(
  (state) => state.fareModel.destinationStops
);
export const fareModelDestinationStopsSelector = createSelector(
  selectFareModelDestinationStops
);

export const selectFareModelOriginDestinationFares = selector(
  (state) => state.fareModel.originDestinationFares
);
export const fareModelOriginDestinationFaresSelector = createSelector(
  selectFareModelOriginDestinationFares
);
