import { createContext, useMemo } from 'react';
import type { FareModelFare } from 'dto/fareModel';
import type { FC } from 'react';
import type { FareModelOriginDestinationFare } from 'dto/fareModel';

type QtyMapValue = {
  fares: Set<number>;
  categories: Set<string>;
  currency: string;
};
type QtyMap = Map<string, QtyMapValue>;

type FaresMap = Map<string, Map<string, Array<FareModelFare>>>;

export const FareModelStopsFaresContext = createContext<{
  faresMap: FaresMap;
  qtyMap: QtyMap;
}>({
  faresMap: new Map(),
  qtyMap: new Map(),
});

interface FareModelStopsFaresContextProps {
  originDestinationFares: Array<FareModelOriginDestinationFare>;
}

export const FareModelStopsFaresContextProvider: FC<FareModelStopsFaresContextProps> =
  (props) => {
    const { originDestinationFares, children } = props;

    const faresMap = useMemo(() => {
      const originStopsMap: FaresMap = new Map();
      originDestinationFares.forEach(
        ({ destinationStop, originStop, fares }) => {
          if (!originStopsMap.has(originStop.id)) {
            originStopsMap.set(originStop.id, new Map());
          }
          const destinationStopsMap = originStopsMap.get(originStop.id)!;
          destinationStopsMap.set(destinationStop.id, fares);
          originStopsMap.set(originStop.id, destinationStopsMap);
        }
      );
      return originStopsMap;
    }, [originDestinationFares]);

    const qtyMap = useMemo<QtyMap>(() => {
      const map: QtyMap = new Map();
      const calc = (
        id: string,
        fare: FareModelFare,
        count: number,
        inc = false
      ) => {
        const {
          fares = new Set(),
          categories = new Set(),
          currency = '',
        } = (map.get(id) ?? {}) as QtyMapValue;
        const { fare: price, fareCategory, currency: fareCurrency } = fare;
        const value = {
          // fares may have empty fare after "Add new" button click
          fares: price ? fares.add(price) : fares,
          categories: fareCategory
            ? categories.add(fareCategory.id)
            : categories,
          currency: fareCurrency ? fareCurrency.name : currency,
        };
        if (count && inc) value.fares = fares.add(price);
        map.set(id, value);
      };

      faresMap.forEach((destinationStopsMap, originStopId) => {
        destinationStopsMap.forEach((fares, destinationStopId) => {
          fares.forEach((fare, index, { length }) => {
            const count = !index ? length : 0;
            calc(originStopId, fare, count, true);
            calc(`${originStopId}_${destinationStopId}`, fare, count);
          });
        });
      });
      return map;
    }, [faresMap]);

    return (
      <FareModelStopsFaresContext.Provider
        value={{
          faresMap,
          qtyMap,
        }}
      >
        {children}
      </FareModelStopsFaresContext.Provider>
    );
  };
