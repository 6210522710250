import { ZoneMap } from 'dto/zoneMap';
import qs from 'qs';
import { Stop, StopsFilter } from 'dto/stop';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';

export const fetchZoneMaps = async (ownerId: number) =>
  (
    await api.get<{ items: Array<ZoneMap> }>(
      `/zone-maps${qs.stringify(
        { ownerId },
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`
    )
  ).data.items;

export const fetchStops = async (filter: Partial<StopsFilter>) =>
  (
    await api.get<Pagination<Stop>>(
      `/stops${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`,
      { baseURL: process.env.REACT_APP_API_LM }
    )
  ).data;
