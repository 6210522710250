import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import {
  PassengerTypeDiscount,
  PassengerTypeDiscountFilter,
  PassengerTypeDiscountInfo,
  PassengerTypeDiscountValues,
} from 'dto/passengerTypeDiscount';
import { createAsyncThunk } from 'store/utils';
import qs from 'qs';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import { api } from '@fleet/shared';

export const setPassengerTypeDiscountsFilter = createAction<
  Partial<PassengerTypeDiscountFilter>
>('passengerTypeDiscount/setPassengerTypeDiscountsFilter');

export const clearPassengerTypeDiscounts = createAction(
  'passengerTypeDiscount/clearPassengerTypeDiscounts'
);

export const getPassengerTypeDiscounts = createAsyncThunk<
  Pagination<PassengerTypeDiscount>,
  Partial<PassengerTypeDiscountFilter> | undefined
>(
  'passengerTypeDiscount/getPassengerTypeDiscounts',
  async (values, { dispatch, getState }) => {
    if (values) {
      dispatch(setPassengerTypeDiscountsFilter(values));
    }

    const { filter } = getState().passengerTypeDiscount;

    return (
      await api.get(
        `/passenger-type-discounts${qs.stringify(
          {
            ownerId: selectCurrentBusinessEntityId(getState()),
            ...filter,
          },
          {
            addQueryPrefix: true,
            allowDots: true,
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_DM,
        }
      )
    ).data;
  }
);

export const setPassengerTypeDiscount = createAction<
  PassengerTypeDiscount | undefined
>('passengerTypeDiscount/setPassengerTypeDiscount');

export const createPassengerTypeDiscount = createAsyncThunk<
  PassengerTypeDiscount,
  PassengerTypeDiscountValues
>(
  'passengerTypeDiscount/createPassengerTypeDiscount',
  async (payload) =>
    (
      await api.post<PassengerTypeDiscount>(
        `/passenger-type-discounts`,
        payload,
        {
          baseURL: process.env.REACT_APP_API_DM,
        }
      )
    ).data
);

export const updatePassengerTypeDiscount = createAsyncThunk<
  PassengerTypeDiscount,
  PassengerTypeDiscountValues
>(
  'passengerTypeDiscount/updatePassengerTypeDiscount',
  async ({ id, ...payload }) =>
    (
      await api.put<PassengerTypeDiscount>(
        `/passenger-type-discounts/${id}`,
        payload,
        {
          baseURL: process.env.REACT_APP_API_DM,
        }
      )
    ).data
);

export const deletePassengerTypeDiscount = createAsyncThunk<void, string>(
  'passengerTypeDiscount/deletePassengerTypeDiscount',
  async (id, { dispatch }) => {
    await api.delete(`/passenger-type-discounts/${id}`, {
      baseURL: process.env.REACT_APP_API_DM,
    });
    await dispatch(getPassengerTypeDiscounts());
  }
);

export const getPassengerTypeDiscount = createAsyncThunk<
  PassengerTypeDiscount,
  string | number
>(
  'passengerTypeDiscount/getPassengerTypeDiscount',
  async (id, { dispatch }) => {
    const { data } = await api.get(`/passenger-type-discounts/${id}`, {
      baseURL: process.env.REACT_APP_API_DM,
    });
    dispatch(setPassengerTypeDiscount(data));
    return data;
  }
);

export const updateOrCreatePassengerTypeDiscountInfo = createAsyncThunk<
  PassengerTypeDiscountInfo,
  { id: number; payload: PassengerTypeDiscountInfo }
>(
  'passengerTypeDiscount/updateOrCreatePassengerTypeDiscountInfo',
  async (
    { id: discountId, payload: { id, language, ...payload } },
    { dispatch }
  ) => {
    const { data } = await (id ? api.put : api.post)(
      `/passenger-type-discounts/${!id ? `/${discountId}` : ''}/information${
        id ? `/${id}` : ''
      }`,
      { ...payload, languageId: language?.id },
      {
        baseURL: process.env.REACT_APP_API_DM,
      }
    );
    await dispatch(getPassengerTypeDiscount(discountId));
    return data;
  }
);

export const deletePassengerTypeDiscountInfo = createAsyncThunk<
  void,
  { id: number; discountId: PassengerTypeDiscountInfo['id'] }
>(
  'passengerTypeDiscount/deletePassengerTypeDiscountInfo',
  async ({ id, discountId }, { dispatch }) => {
    await api.delete(`/passenger-type-discounts/information/${id}`, {
      baseURL: process.env.REACT_APP_API_DM,
    });
    await dispatch(getPassengerTypeDiscount(discountId));
  }
);

export const deletePassengerTypeDiscountInfosBulk = createAsyncThunk<
  void,
  { ids: Array<number>; discountId: PassengerTypeDiscountInfo['id'] }
>(
  'passengerTypeDiscount/deletePassengerTypeDiscountInfo',
  async ({ ids, discountId }, { dispatch }) => {
    await Promise.all(
      ids.map((id) =>
        api.delete(`/passenger-type-discounts/information/${id}`, {
          baseURL: process.env.REACT_APP_API_DM,
        })
      )
    );
    await dispatch(getPassengerTypeDiscount(discountId));
  }
);
