import { Classifier } from '@fleet/shared/dto/classifier';
import type { Filter, Validity, ValidOnWeekday } from 'dto/common';
import type { PaginationParams } from '@fleet/shared/dto/pagination';
import { TicketMinimalCostValue } from 'dto/common';

export interface PromotionalDiscountFilter extends Filter, PaginationParams {
  name?: string;
  discountPercentageAmount: number | null;
  calculationTypeId?: string;
  currencyId?: string;
  validityFrom?: Validity;
  validityTo?: Validity;
  promotionId?: number;
}

export interface PromotionalDiscount {
  id: string;

  // main form fields
  ownerId: number;
  name: string;
  inventoryClasses: Array<Classifier>;
  currency: Classifier; // CURRENCY
  discountPercentageAmount: number | null;
  discountType: Classifier; // BONUS_SCHEME_DISCOUNT_TYPE
  calculationBasis: Classifier;
  salesLimit?: number;
  salesLimitType?: Classifier; // TICKET_SALES_LIMIT_TYPE
  promotions: Array<Classifier>;
  validity: Validity;
  excludedPurchaseDays: Array<string>;
  excludedTravelDays: Array<string>;
  ticketMinimalCost: Array<{
    amount: number;
    currency: Classifier; // CURRENCY
  }>;
  tripDateValidity: Validity;

  // advanced accordion fields
  isPublic?: boolean;
  journeyWay: Classifier; // JOURNEY_WAY
  journeyDirection: Classifier; // JOURNEY_DIRECTION
  journeyWayType: Classifier; // JOURNEY_WAY_TYPE
  journeyType: Classifier; // JOURNEY_TYPE
  isManuallySelectable?: boolean;
  validOnWeekday: ValidOnWeekday;

  // currently not used fields:
  // code: string;
  // comment: string;
  // type: Classifier; // BONUS_SCHEME_TYPE
  // areExcludedDaysForTransaction?: boolean;
  // isBasedOnUnitPrice?: boolean;
  // isChangeable?: boolean;
  // isCommissionCalculated: boolean;
  // isGroupTicketDiscount: boolean;
  // isRefundable?: boolean;
  // shouldAskReasonForManualSelection?: boolean;
  // seatClassCategory: Classifier; // SEAT_CLASS_CATEGORY
  // canBeReappliedDuringModification?: boolean;

  // tabs data
  information: Array<{
    id: number;
    language: Classifier; // CULTURE
    discountName: string;
    information: string;
    discountDocumentType: string;
  }>;
  bookingQuantity: Array<{
    id: number;
    discountPercentageAmount: number | null;
    ticketCountFrom: number;
    ticketCountTo: number;
  }>;
  transactionTime: Array<{
    id: number;
    discountPercentageAmount: number | null;
    transactionTimeFrom: string;
    transactionTimeTo: string;
  }>;
  departureDays: Array<{
    id: number;
    discountPercentageAmount: number | null;
    daysBeforeDepartureFrom: number;
    daysBeforeDepartureTo: number;
  }>;
  salesChannel: Array<{
    id: number;
    discountPercentageAmount: number | null;
    salesChannel: Classifier; // SALES_POINT_TYPE
    salesPoints: Array<Classifier<number>>;
    salesChannelAgents: Array<Classifier<number>>;
    salesChannelCountries: Array<Classifier<number>>;
  }>;
  lineType: Array<{
    id: number;
    discountPercentageAmount: number | null;
    lineType: Classifier; // BUS_LINE_TYPE
  }>;
  passengerGroup: Array<{
    id: number;
    discountPercentageAmount: number | null;
    passengerType: Classifier<number>;
    ticketCountFrom: number;
    ticketCountTo: number;
  }>;
  cardType: Array<{
    id: number;
    discountPercentageAmount: number | null;
    cardType: Classifier;
  }>;
  accompanyingPassengerTypeRestrictions: Array<{
    id: number;
    discountPercentageAmount: number | null;
    passengerTypes: {
      types: Array<Classifier<number>>;
      countFrom: number;
      countTo: number;
    };
    accompanyingPassengerTypes: {
      types: Array<Classifier<number>>;
      countFrom: number;
      countTo: number;
    };
  }>;
  feederDefeeder: Array<{
    id: number;
    currentInventoryClasses: Array<Classifier>;
    previousNextInventoryClasses: Array<Classifier>;
    discountPercentageAmount: number | null;
  }>;

  // CURRENTLY NOT USED FIELDS:
  // brand: Array<{
  //   id: number;
  //   discountPercentageAmount: number | null;
  //   lineBrand: Classifier<number>;
  // }>;
  // bonusCardGroup: Array<{
  //   id: number;
  //   bonusCardGroupId: number;
  //   bonusCardGroupName: string;
  // }>;
  // route: Array<{
  //   id: number;
  //   line: Classifier<number>;
  //   lineTemplate: Classifier<number>;
  //   salesLimit: number;
  //   salesLimitType: Classifier; // TICKET_SALES_LIMIT_TYPE
  //   departureTimes: Array<Date>;
  //   tripRoutePart: Classifier; // TRIP_ROUTE_PART
  //   departureStops: Array<Classifier<number>>;
  //   destinationStops: Array<Classifier<number>>;
  //   validOnWeekday: ValidOnWeekday;
  //   ticketMinimalCost: Array<{
  //     amount: number;
  //     currency: Classifier; // CURRENCY
  //   }>;
  //   prices: Array<{
  //     amount: number;
  //     currency: Classifier; // CURRENCY
  //   }>;
  // }>;
  // loyaltyLevel: Array<{
  //   id: number;
  //   name: string;
  //   discountPercentageAmount: number | null;
  // }>;
  // packageTour: Array<{
  //   id: number;
  //   discountPercentageAmount: number | null;
  //   packageTourId: number;
  //   packageTourName: string;
  // }>;
  // paymentMethodRestriction: Array<{
  //   id: number;
  //   paymentType: Classifier; // PAYMENT_TYPE
  // }>;
  // requiredInfo: Array<{
  //   id: number;
  //   implementationLevel: Classifier; // FIELD_IMPLEMENTATION_LEVEL
  //   fieldId: number;
  // }>;
}

export interface PromotionalDiscountValues {
  id: string;
  ownerId: number;
  name: string;
  code?: string;
  comment?: string;
  discountTypeId: string; // BONUS_SCHEME_DISCOUNT_TYPE
  calculationBasisId: string;
  discountPercentageAmount: number | null;
  currencyId: string; // CURRENCY
  isPublic: boolean;
  validity: Exclude<Validity, 'to'>;
  tripDateValidity: Exclude<Validity, 'to'>;
  salesLimit?: number;
  salesLimitTypeId?: string; // TICKET_SALES_LIMIT_TYPE
  areExcludedDaysForTransaction?: boolean;
  isChangeable?: boolean;
  isRefundable?: boolean;
  isBasedOnUnitPrice?: boolean;
  isCommissionCalculated?: boolean;
  isManuallySelectable?: boolean;
  shouldAskReasonForManualSelection?: boolean;
  journeyWayId?: string; // JOURNEY_WAY
  seatClassCategoryId?: string; // SEAT_CLASS_CATEGORY
  canBeReappliedDuringModification?: boolean;
  journeyTypeId?: string; // JOURNEY_TYPE
  journeyWayTypeId?: string; // JOURNEY_WAY_TYPE
  journeyDirectionId?: string; // JOURNEY_DIRECTION
  isGroupTicketDiscount?: boolean;
  validOnWeekday?: ValidOnWeekday;
  ticketMinimalCost?: Array<TicketMinimalCostValue>;
  excludedPurchaseDays?: Array<string>;
  excludedTravelDays?: Array<string>;
  inventoryClasses?: Array<string>;
  canBeAppliedToReservations: boolean;
}

export const promotionalDiscountTabs = [
  'information',
  'bookingQuantity',
  'transactionTime',
  'departureDays',
  'salesChannel',
  'lineType',
  'passengerGroup',
  'cardType',
  'accompanyingPassengerTypeRestrictions',
  'feederDefeeder',
] as const;

export type PromotionalDiscountTab = typeof promotionalDiscountTabs[number];
