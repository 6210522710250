import type { Pagination } from '@fleet/shared/dto/pagination';
import type {
  PromotionalDiscount,
  PromotionalDiscountFilter,
} from 'dto/promotionalDiscount';
import { createReducer } from '@reduxjs/toolkit';
import {
  clearPromotionalDiscounts,
  getPromotionalDiscounts,
  setPromotionalDiscount,
  setPromotionalDiscountFilter,
} from 'features/promotionalDiscount/promotionalDiscountActions';

export interface PromotionalDiscountState {
  filter: Partial<PromotionalDiscountFilter>;
  list?: Pagination<PromotionalDiscount>;
  current?: PromotionalDiscount;
}

const initialState: PromotionalDiscountState = {
  filter: {},
};

export const promotionalDiscountReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setPromotionalDiscountFilter, (state, action) => {
        state.filter = action.payload;
      })
      .addCase(clearPromotionalDiscounts, (state) => {
        state.list = undefined;
      })
      .addCase(getPromotionalDiscounts.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setPromotionalDiscount, (state, action) => {
        state.current = action.payload;
      });
  }
);
