import type { FC } from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';
import {
  FareModelCommercialDistance,
  FareModelOriginDestinationCommercialDistance,
} from 'dto/fareModelDistance';
import { noop } from '@fleet/shared/utils/noop';

type DistancesMap = Map<string, Map<string, FareModelCommercialDistance>>;
interface FareModelCommercialDistancesContextValue {
  distancesMap: DistancesMap;
  changeDistance: (
    originStopId: string,
    destinationStopId: string,
    distance?: FareModelCommercialDistance
  ) => void;
}
export const FareModelCommercialDistancesContext =
  createContext<FareModelCommercialDistancesContextValue>({
    distancesMap: new Map(),
    changeDistance: noop,
  });

interface FareModelCommercialDistancesContextProps {
  distances: Array<FareModelOriginDestinationCommercialDistance>;
}

export const FareModelCommercialDistancesContextProvider: FC<FareModelCommercialDistancesContextProps> =
  ({ distances, children }) => {
    const [distancesMap, setDistancesMap] = useState<DistancesMap>(new Map());
    useEffect(() => {
      const distancesMap: DistancesMap = new Map();
      distances.forEach(
        ({ originStop, destinationStop, commercialDistance }) => {
          if (!distancesMap.has(originStop.id)) {
            distancesMap.set(originStop.id, new Map());
          }
          const destinationStopsMap = distancesMap.get(originStop.id)!;
          destinationStopsMap.set(destinationStop.id, commercialDistance);
          distancesMap.set(originStop.id, destinationStopsMap);
        }
      );
      setDistancesMap(distancesMap);
    }, [distances]);

    const changeDistance = useCallback<
      FareModelCommercialDistancesContextValue['changeDistance']
    >(
      (originStopId, destinationStopId, distance) => {
        if (!distancesMap.has(originStopId)) {
          distancesMap.set(originStopId, new Map());
        }
        const destinationStopsMap = distancesMap.get(originStopId)!;
        distance
          ? destinationStopsMap.set(destinationStopId, distance)
          : destinationStopsMap.delete(destinationStopId);
        distancesMap.set(originStopId, new Map(destinationStopsMap));
        setDistancesMap(new Map(distancesMap));
      },
      [distancesMap]
    );

    return (
      <FareModelCommercialDistancesContext.Provider
        value={{ distancesMap, changeDistance }}
      >
        {children}
      </FareModelCommercialDistancesContext.Provider>
    );
  };
