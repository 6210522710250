import { FC, useCallback, useMemo } from 'react';
import {
  TableColumns,
  decimalToPercentage,
  percentageToDecimal,
  renderPercentageError,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  createOrUpdatePromotionalDiscountSalesChannel,
  removePromotionalDiscountSalesChannels,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import { validate } from '@fleet/shared/form/validate';
import { isDiscountAmountFieldDisabled } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/utils';

interface PromotionalDiscountTabSalesChannelProps {}

type Entity = PromotionalDiscount['salesChannel'][number];

export const PromotionalDiscountTabSalesChannel: FC<PromotionalDiscountTabSalesChannelProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { salesChannel } = currentDiscount;
    const salesChannelOptions = useClassificationOptions(
      ClassificationGroup.SALES_POINT_TYPE
    );
    const countryOptions = useClassificationOptions(
      ClassificationGroup.COUNTRY
    );

    const columns: TableColumns<Entity> = useMemo(() => {
      return [
        {
          id: 'salesChannel.id',
          accessor: ({ salesChannel }) => salesChannel?.id,
          type: 'select',
          editableProps: {
            options: salesChannelOptions,
            validate: validate.required(),
          },
          Header: <TransTableHead i18nKey="salesChannel" />,
        },
        {
          accessor: 'salesPoints',
          Header: <TransTableHead i18nKey="pointOfSale" />,
          editableProps: {
            required: false,
            // TODO, options: [],
          },
        },
        {
          accessor: 'salesChannelAgents',
          Header: <TransTableHead i18nKey="agency" />,
          editableProps: {
            required: false,
            // TODO, options: [],
          },
        },
        {
          id: 'salesChannelCountries',
          accessor: ({ salesChannelCountries }) =>
            salesChannelCountries?.map(({ id }) => id),
          Header: <TransTableHead i18nKey="country" />,
          type: 'select',
          editableProps: {
            multiple: true,
            options: countryOptions,
            valueAccessor: 'id',
            required: false,
          },
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'salesChannel',
            }),
          },
        },
      ];
    }, [countryOptions, currentDiscount, salesChannelOptions]);

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({
        salesChannel,
        discountPercentageAmount,
        salesChannelCountries,
        salesChannelAgents,
        salesPoints,
        ...rest
      }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountSalesChannel({
            ...rest,
            salesChannelId: salesChannel.id,
            salesChannelCountryIds: (salesChannelCountries ?? []).map(
              ({ id }) => id
            ),
            salesChannelAgentIds: (salesChannelAgents ?? []).map(
              ({ id }) => id
            ),
            salesPointIds: (salesPoints ?? []).map(({ id }) => id),
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountSalesChannels(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={salesChannel.map((channel) => ({
          ...channel,
          discountPercentageAmount: channel.discountPercentageAmount
            ? decimalToPercentage(channel.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
