import type { FC } from 'react';
import { useCallback, useContext } from 'react';
import { PriceDetailsTabPricesContext } from 'routes/ZonePriceList/context/PriceDetailsTabPricesContext';
import {
  columnWidth,
  PriceDetailsTabPricesMatrixCell,
  PriceDetailsTabPricesMatrixContext,
  PriceDetailsTabPricesMatrixInnerElementType,
  PriceDetailsTabPricesMatrixItemWrapper,
  rowHeight,
  stickyColumnWidth,
  stickyRowHeight,
} from 'routes/ZonePriceList/PriceDetailsTabPricesMatrixCell';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid as Grid, VariableSizeGridProps } from 'react-window';

const PriceDetailsTabPricesMatrixGrid: FC<VariableSizeGridProps> = ({
  children: ItemRenderer,
  ...props
}) => (
  <PriceDetailsTabPricesMatrixContext.Provider
    value={{
      ItemRenderer,
      rowCount: props.rowCount,
      columnCount: props.columnCount,
    }}
  >
    <Grid itemData={{ ItemRenderer }} {...props}>
      {PriceDetailsTabPricesMatrixItemWrapper}
    </Grid>
  </PriceDetailsTabPricesMatrixContext.Provider>
);

export const PriceDetailsTabPricesMatrix: FC = () => {
  const { zoneIds = [] } = useContext(PriceDetailsTabPricesContext);

  const getRowHeight = useCallback(
    (index: number) => (index === 0 ? stickyRowHeight : rowHeight),
    []
  );
  const getColumnWidth = useCallback(
    (index: number) => (index === 0 ? stickyColumnWidth : columnWidth),
    []
  );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <PriceDetailsTabPricesMatrixGrid
          width={width}
          height={height}
          innerElementType={PriceDetailsTabPricesMatrixInnerElementType}
          rowCount={zoneIds.length + 1}
          columnCount={zoneIds.length + 1}
          estimatedRowHeight={rowHeight}
          rowHeight={getRowHeight}
          estimatedColumnWidth={columnWidth}
          columnWidth={getColumnWidth}
        >
          {PriceDetailsTabPricesMatrixCell}
        </PriceDetailsTabPricesMatrixGrid>
      )}
    </AutoSizer>
  );
};
