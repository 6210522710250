import { useEffect, useMemo, FC } from 'react';
import { getFareModels } from 'features/fareModel/fareModelActions';
import { fareModelsSelector } from 'features/fareModel/fareModelSelectors';
import { Link, Route, Switch } from 'react-router-dom';
import { CardHeader } from '@fleet/shared/mui';
import { FareModelCard } from 'routes/FareModels/FareModelCard';
import { FareModelForm } from 'routes/FareModels/FareModelForm';
import { useDispatch, useSelector } from 'store/utils';
import { fareModelsLoadingSelector } from 'features/loading/loadingSelectors';
import { CardContent, Grid } from '@mui/material';
import { Layout } from '@fleet/shared/components/Layout';
import _orderBy from 'lodash/orderBy';
import { AddButton, Loadable, SearchResult } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';

interface FareModelsProps {}

export const FareModels: FC<FareModelsProps> = () => {
  const dispatch = useDispatch();
  const data = useSelector(fareModelsSelector);
  const loading = useSelector(fareModelsLoadingSelector);
  const sortedData = useMemo(
    () => _orderBy(data, ['isActive', 'name'], ['desc', 'asc']),
    [data]
  );

  useEffect(() => {
    dispatch(getFareModels());
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader
            title={<TransTitle i18nKey="fareModels" />}
            sx={{ px: 3 }}
          >
            <AddButton component={Link} to="/fare-models/create">
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/fare-models/:action(create|edit)/:id?"
              component={FareModelForm}
              exact
            />
          </Switch>
        }
      >
        <SearchResult
          results={data.length}
          i18nKey="noDataAdded"
          loading={loading}
        >
          <CardContent sx={{ p: 2, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {sortedData.map((item) => (
                <Grid key={item.id} item sm={4} md={3}>
                  <FareModelCard {...item} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
