import { createAsyncThunk } from 'store/utils';
import {
  Zone,
  ZoneMap,
  ZoneMapPayload,
  ZoneMapSearchFilter,
  ZoneWithLocalizations,
} from 'dto/zoneMap';
import qs from 'qs';
import { Localization } from 'dto/classification';
import { createAction } from '@reduxjs/toolkit';
import {
  selectCurrentZoneId,
  selectCurrentZoneMapId,
} from 'features/zoneMap/zoneMapSelectors';
import { api } from '@fleet/shared';

export const clearZoneMapList = createAction('zoneMap/clearZoneMapList');

export const getZoneMapList = createAsyncThunk<
  Array<ZoneMap>,
  Partial<ZoneMapSearchFilter> | undefined
>('zoneMap/getZoneMapList', async (values, { getState, dispatch }) => {
  if (values) {
    dispatch(setZoneMapFilter(values));
  }

  const { filter } = getState().zoneMap;

  return (
    await api.get<{ items: Array<ZoneMap> }>(
      `/zone-maps${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data.items;
});

export const clearCurrentZoneMap = createAction('zoneMap/clearCurrentZoneMap');

export const getZoneMap = createAsyncThunk<ZoneMap, number>(
  'zoneMap/getZoneMap',
  async (zoneMapId) => (await api.get(`/zone-maps/${zoneMapId}`)).data
);

export const createZoneMap = createAsyncThunk<ZoneMap, ZoneMapPayload>(
  'zoneMap/createZoneMap',
  async (payload) => (await api.post('/zone-maps', payload)).data
);

export const updateZoneMap = createAsyncThunk<ZoneMap, ZoneMapPayload>(
  'zoneMap/updateZoneMap',
  async ({ id, ...payload }) =>
    (await api.put(`/zone-maps/${id}`, payload)).data
);

export const deleteZoneMaps = createAsyncThunk<void, Array<number>>(
  'zoneMap/deleteZoneMaps',
  async (zoneMaps) => {
    await Promise.all(zoneMaps.map((id) => api.delete(`/zone-maps/${id}`)));
  }
);

export const clearCurrentZone = createAction('zoneMap/clearCurrentZone');

export const getZone = createAsyncThunk<
  ZoneWithLocalizations,
  { zoneId?: string; zoneMapId?: string } | undefined
>('zoneMap/getZone', async (payload, { getState }) => {
  const zoneMapId = payload?.zoneMapId ?? selectCurrentZoneMapId(getState());
  const zoneId = payload?.zoneId ?? selectCurrentZoneId(getState());

  const zone = (await api.get<Zone>(`/zone-maps/${zoneMapId}/zones/${zoneId}`))
    .data;
  const localizations = (
    await api.get<{ items: Array<Localization> }>(
      `/zones/${zoneId}/localizations`
    )
  ).data.items;

  return { ...zone, localizations };
});

export const createZone = createAsyncThunk<
  Zone,
  { zoneMapId: string; payload: Zone }
>(
  'zoneMap/createZone',
  async ({ zoneMapId, payload }) =>
    (await api.post(`/zone-maps/${zoneMapId}/zones`, payload)).data
);

export const updateZone = createAsyncThunk<
  Zone,
  { zoneMapId: string; payload: Zone }
>(
  'zoneMap/updateZone',
  async ({ zoneMapId, payload: { id, ...rest } }) =>
    (await api.put(`/zone-maps/${zoneMapId}/zones/${id}`, rest)).data
);

export const deleteZones = createAsyncThunk<void, Array<number>>(
  'zoneMap/deleteZones',
  async (zoneIds, { getState }) => {
    await Promise.all(
      zoneIds.map((id) =>
        api.delete(
          `/zone-maps/${selectCurrentZoneMapId(getState())}/zones/${id}`
        )
      )
    );
  }
);

export const createStopLocalization = createAsyncThunk<
  never,
  Omit<Localization, 'id'>
>(
  'zoneMap/createStopLocalization',
  async (payload, { getState }) =>
    (
      await api.post(
        `/zones/${selectCurrentZoneId(getState())}/localizations`,
        payload
      )
    ).data
);

export const updateStopLocalization = createAsyncThunk<never, Localization>(
  'zoneMap/updateStopLocalization',
  async ({ id, ...rest }, { getState }) =>
    (
      await api.put(
        `/zones/${selectCurrentZoneId(getState())}/localizations/${id}`,
        rest
      )
    ).data
);

export const removeStopLocalizations = createAsyncThunk<
  void,
  Array<Localization>
>('zoneMap/removeStopLocalization', async (localizations, { getState }) => {
  await Promise.all(
    localizations.map(({ id }) =>
      api.delete(
        `/zones/${selectCurrentZoneId(getState())}/localizations/${id}`
      )
    )
  );
});

export const addStopsToZone = createAsyncThunk<never, Array<number>>(
  'zoneMap/addStopsToZone',
  async (stopIds, { getState }) =>
    (
      await api.post(`/zones/${selectCurrentZoneId(getState())}/stops/bulk`, {
        stopIds,
      })
    ).data
);

export const deleteStopsFromZone = createAsyncThunk<void, Array<number>>(
  'zoneMap/deleteStopsFromZone',
  async (stopIds, { getState }) => {
    await api.delete(
      `/zones/${selectCurrentZoneId(getState())}/stops/bulk${qs.stringify(
        { ids: stopIds.join(',') },
        { addQueryPrefix: true }
      )}`
    );
  }
);

export const setZoneMapFilter = createAction<Partial<ZoneMapSearchFilter>>(
  'zoneMap/setZoneMapFilter'
);
