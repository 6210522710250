import { useCallback, useMemo } from 'react';
import {
  AddButton,
  Button,
  FormProvider,
  formSubmit,
  PeriodField,
  RadioGroupField,
  SearchForm,
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransNav } from 'i18n/trans/nav';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import {
  getVoucherTemplates,
  setVoucherTemplateFilter,
} from 'features/vouchersTemplate/vouchersTemplateActions';
import { VouchersTemplateSearchFormValues } from 'dto/vouchersTemplate';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { vouchersTemplateFilterSelector } from 'features/vouchersTemplate/vouchersTemplateSelector';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { pick } from 'lodash';

export const VouchersTemplateSearchForm = () => {
  const dispatch = useDispatch();
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(vouchersTemplateFilterSelector);
  const voucherTypes = useClassificationOptions(
    ClassificationGroup.VOUCHER_TYPE
  );

  const onSubmit = useCallback(
    (values: VouchersTemplateSearchFormValues) => {
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        dispatch(getVoucherTemplates(values));
      });
    },
    [dispatch]
  );

  const defaultValues = useMemo(
    () => ({ ownerId: currentBusinessEntityId }),
    [currentBusinessEntityId]
  );

  const initialValues = useMemo(
    () => ({ ...defaultValues, ...filter }),
    [defaultValues, filter]
  );

  const { form, handleSubmit } = useForm<VouchersTemplateSearchFormValues>({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(
      setVoucherTemplateFilter({
        ...defaultValues,
        ...pick(filter, ['limit', 'offset']),
      })
    );
  }, [defaultValues, dispatch, filter, form]);

  return (
    <SearchForm
      title={<TransNav i18nKey="vouchersTemplate" />}
      action={
        <AddButton component={Link} to="vouchers-template/create">
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <SelectOwnerField
                businessEntities={businessEntities}
                allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="name"
                label={<TransField i18nKey="templateName" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                options={voucherTypes}
                name="voucherTypeId"
                label={<TransField i18nKey="voucherType" />}
                showEmptyOption
              />
            </Grid>
            <PeriodField
              from={{
                name: 'validFrom',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'validTo',
                label: <TransField i18nKey="validTo" />,
                isClearable: true,
              }}
            />
            <Grid item xs={1}>
              <RadioGroupField
                name="hasVouchers"
                label={<TransField i18nKey="hasVouchers" />}
                options="BOOL"
                inline
              />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                name="isActive"
                label={<TransField i18nKey="isActive" />}
                options="BOOL"
                inline
              />
            </Grid>
            <Stack
              direction="row"
              sx={{ ml: 'auto', alignItems: 'end' }}
              spacing={1}
            >
              <Button onClick={handleReset} variant="text">
                <TransButton i18nKey="resetFilters" />
              </Button>
              <Button icon="search" type="submit" sx={{ ml: 2 }}>
                <TransButton i18nKey="search" />
              </Button>
            </Stack>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
