import { createTrans, TransKeys } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  addPriceList: (
    <Trans i18nKey="title.addPriceList" defaults="New price list" />
  ),
  coTaxationGroups: (
    <Trans i18nKey="title.coTaxationGroups" defaults="Co-taxation groups" />
  ),
  fareModel: <Trans i18nKey="title.fareModel" defaults="Fare model" />,
  fareModelOriginDestinationFares: (
    <Trans
      i18nKey="title.fareModelOriginDestinationFares"
      defaults="{{name}}: Origin-Destination fares"
    />
  ),
  fareModels: <Trans i18nKey="title.fareModels" defaults="Fare models" />,
  fares: <Trans i18nKey="title.fares" defaults="Fares" />,
  massEdit: <Trans i18nKey="title.massEdit" defaults="Mass edit" />,
  newPromotionalDiscount: (
    <Trans
      i18nKey="subtitle.newPromotionalDiscount"
      defaults="New promotional discount"
    />
  ),
  passengerType: (
    <Trans i18nKey="title.passengerType" defaults="Passenger type" />
  ),
  passengerTypeDiscounts: (
    <Trans
      i18nKey="title.passengerTypeDiscounts"
      defaults="Passenger type discounts"
    />
  ),
  passengerTypes: (
    <Trans i18nKey="title.passengerTypes" defaults="Passenger types" />
  ),
  priceList: <Trans i18nKey="title.priceList" defaults="Price lists" />,
  promotionalDiscount: (
    <Trans
      i18nKey="title.promotionalDiscount"
      defaults="Promotional discount: {{name}}"
    />
  ),
  promotionalDiscounts: (
    <Trans
      i18nKey="title.promotionalDiscounts"
      defaults="Promotional discounts"
    />
  ),
  translations: <Trans i18nKey="title.translations" defaults="Translations" />,
  validityCriteria: (
    <Trans i18nKey="title.validityCriteria" defaults="Validity criteria" />
  ),
  voucher: <Trans i18nKey="title.voucher" defaults="Voucher: {{num}}" />,
});

export type TransTitleKeys = TransKeys<typeof TransTitle>;
