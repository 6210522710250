import { createReducer } from '@reduxjs/toolkit';
import {
  City,
  ClassificationGroup,
  ClassificationKey,
  Country,
  County,
  InventoryClass,
  ZoneMap,
} from 'dto/classification';
import { PassengerType } from 'dto/passengerType';
import {
  getBusinessEntities,
  getCities,
  getClassificationGroups,
  getCounties,
  getCountries,
  getInventoryClasses,
  getPassengerTypes,
  getPrintoutThemes,
  getZoneMaps,
} from 'features/classification/classificationActions';
import { Classifier } from '@fleet/shared/dto/classifier';
import { BusinessEntity } from '@fleet/shared/dto/businessEntity';

type ClassificationState = Record<ClassificationKey, Array<Classifier>> & {
  [ClassificationGroup.BUSINESS_ENTITY]: Array<BusinessEntity<number>>;
  [ClassificationGroup.CITY]: Array<City>;
  [ClassificationGroup.COUNTRY]: Array<Country>;
  [ClassificationGroup.COUNTY]: Array<County>;
  [ClassificationGroup.INVENTORY_CLASS]: Array<InventoryClass>;
  [ClassificationGroup.PASSENGER_TYPE]: Array<PassengerType>;
  [ClassificationGroup.PRINTOUT_THEMES]: Array<Classifier>;
  [ClassificationGroup.ZONE_MAP]: Array<ZoneMap>;
};

const initialState = {} as ClassificationState;

export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getClassificationGroups.fulfilled, (state, action) => {
      action.payload.forEach((data) => (state[data.id] = data.classifications));
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.COUNTRY = action.payload;
    })
    .addCase(getCounties.fulfilled, (state, action) => {
      state.COUNTY = action.payload;
    })
    .addCase(getCities.fulfilled, (state, action) => {
      state.CITY = action.payload;
    })
    .addCase(getBusinessEntities.fulfilled, (state, action) => {
      state.BUSINESS_ENTITY = action.payload;
    })
    .addCase(getPassengerTypes.fulfilled, (state, action) => {
      state.PASSENGER_TYPE = action.payload;
    })
    .addCase(getPrintoutThemes.fulfilled, (state, action) => {
      state.PRINTOUT_THEMES = action.payload;
    })
    .addCase(getZoneMaps.fulfilled, (state, action) => {
      state.ZONE_MAP = action.payload;
    })
    .addCase(getInventoryClasses.fulfilled, (state, action) => {
      state.INVENTORY_CLASS = action.payload;
    });
});
