import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import {
  getFareModel,
  setFareModel,
} from 'features/fareModel/fareModelActions';
import { fareModelFixedLoadingSelector } from 'features/loading/loadingSelectors';
import { Layout, Loadable } from '@fleet/shared';
import { FareModelFixedTable } from 'routes/FareModels/FareModelFixed/FareModelFixedTable';
import { getFixedFares } from 'features/fareModel/fareModelFixedActions';

export const FareModelFixed: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const loading = useSelector(fareModelFixedLoadingSelector);

  useEffect(() => {
    (async () => {
      dispatch(setFareModel());
      await dispatch(getFareModel(id)).unwrap();
      await dispatch(getFixedFares()).unwrap();
    })();
  }, [dispatch, id]);

  return (
    <Loadable loading={loading}>
      <Layout>
        <FareModelFixedTable />
      </Layout>
    </Loadable>
  );
};
