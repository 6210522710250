import type { FC } from 'react';
import { useContext, useMemo } from 'react';
import { useModal } from '@fleet/shared/hooks';
import { Button, Modal } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { TransButton } from 'i18n/trans/button';
import { Typography } from '@mui/material';
import { TransHint } from 'i18n/trans/hint';
import {
  FareModelStopsContext,
  FareModelStopsMatrixCellContentProps,
} from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { FareModelStopsFares } from 'routes/FareModels/FareModelStops/FareModelStopsFares';
import _isEmpty from 'lodash/isEmpty';
import { FareModelStopsFaresContext } from 'routes/FareModels/FareModelStops/FareModelStopsFaresContext';

const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      '& .MuiTypography-root, & .MuiButton-root': {
        fontSize: 'inherit',
      },
    },
    categories: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    modal: {
      '& .MuiDialog-paper': {
        minHeight: 256,
      },
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
  }),
  {
    name: 'FareModelStopsMatrixCellFares',
  }
);

export const FareModelStopsMatrixCellFares: FC<FareModelStopsMatrixCellContentProps> =
  ({ originStopId, destinationStopId }) => {
    const { fareModelId, stopsMap } = useContext(FareModelStopsContext);
    const { faresMap } = useContext(FareModelStopsFaresContext);

    const fares = useMemo(
      () => faresMap.get(originStopId)?.get(destinationStopId) ?? [],
      [destinationStopId, faresMap, originStopId]
    );

    const { min, max, currency, categories } = useMemo(
      () =>
        fares
          // fares may have empty fare after "Add new" button click
          .filter((fare) => !_isEmpty(fare))
          .reduce(
            ({ min, max, categories }, { fare, currency, fareCategory }) => ({
              min: Math.min(min, fare),
              max: Math.max(max, fare),
              currency: currency.name ?? currency.id,
              categories: categories.add(fareCategory.name ?? fareCategory.id),
            }),
            { min: Infinity, max: 0, currency: '', categories: new Set() }
          ),
      [fares]
    );

    const classes = useStyles();
    const { open: isOpen, onOpen, onClose } = useModal();
    return (
      <div className={classes.root}>
        <Typography fontWeight={700}>
          {min !== Infinity &&
            `${[min, min !== max && max]
              .filter(Boolean)
              .join('–')} ${currency}`}
          &nbsp;
        </Typography>
        <Typography className={classes.categories}>
          <span>
            {categories.size > 1 ? (
              <TransHint
                i18nKey="categories"
                values={{ num: categories.size }}
              />
            ) : (
              [...categories].join('')
            )}
          </span>
          <Button
            variant="text"
            size="small"
            sx={{ p: 0, minWidth: 0 }}
            onClick={onOpen}
          >
            {Boolean(fares.length) ? (
              <TransButton i18nKey="edit" />
            ) : (
              <TransButton i18nKey="add" />
            )}
          </Button>
          <Modal
            open={isOpen}
            onClose={onClose}
            title={`${stopsMap.get(originStopId)!.name} – ${
              stopsMap.get(destinationStopId)!.name
            }`}
            actionButton={<TransButton i18nKey="confirm" />}
            className={classes.modal}
            maxWidth="lg"
          >
            <FareModelStopsFares
              fareModelId={fareModelId!}
              fares={fares}
              originStopId={originStopId}
              destinationStopId={destinationStopId}
            />
          </Modal>
        </Typography>
      </div>
    );
  };
