import type { FC } from 'react';
import { useCallback } from 'react';
import type { FieldState } from 'final-form';
import { renderToString } from 'react-dom/server';
import { TransField, TransFieldKeys } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { DateField, ModalFieldArray } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import _cloneDeep from 'lodash/cloneDeep';
import _noop from 'lodash/noop';
import _unset from 'lodash/unset';
import { TransValidate } from 'i18n/trans/validate';
import { formatDate } from '@fleet/shared/utils/date';
import { PromotionalDiscount } from 'dto/promotionalDiscount';

interface ExcludedDatesFieldProps {
  name: keyof Pick<
    PromotionalDiscount,
    'excludedTravelDays' | 'excludedPurchaseDays'
  >;
}

export const ExcludedDatesField: FC<ExcludedDatesFieldProps> = ({ name }) => {
  const validateDates = useCallback(
    (
      value: string,
      allValues: { [key in TransFieldKeys]: Array<string> },
      meta: FieldState<string>
    ) => {
      const copyValues = _cloneDeep(allValues);
      _unset(copyValues, meta.name); // delete current value from all
      const values = (copyValues[name] ?? []).filter(Boolean);
      if (value && values.includes(value)) {
        return renderToString(
          <TransValidate
            i18nKey="uniq"
            values={{ field: renderToString(<TransField i18nKey={name} />) }}
          />
        );
      }
      return undefined;
    },
    [name]
  );

  const validateRow = (rows?: Array<string>) => {
    if (rows?.some((row) => row.length === 0)) {
      return renderToString(
        <TransField i18nKey="excludedDateFieldCantBeEmpty" />
      );
    }
    return null;
  };

  return (
    <ModalFieldArray<string>
      name={name}
      label={<TransField i18nKey={name} />}
      appendValue=""
      accessor={(date) => formatDate(date)}
      validate={validateRow}
      render={({ name, removeButton }) => ({
        canRemove: false,
        field: (
          <Grid container spacing={2}>
            <Grid item sx={{ flex: 1 }}>
              <DateField name={name} validate={validateDates} />
            </Grid>
            <Grid item>{removeButton}</Grid>
          </Grid>
        ),
      })}
      modalProps={{
        actionButton: {
          label: <TransButton i18nKey="confirm" />,
          icon: 'check',
          onClick: _noop,
        },
      }}
    />
  );
};
