import { createReducer } from '@reduxjs/toolkit';
import {
  PriorityVouchersTemplate,
  VouchersTemplateBase,
  VouchersTemplateSearchFormValues,
  VoucherTemplate,
} from 'dto/vouchersTemplate';
import {
  clearVoucherTemplates,
  getPriorityVoucherTemplates,
  getVoucherTemplates,
  setVoucherTemplate,
  setVoucherTemplateFilter,
} from 'features/vouchersTemplate/vouchersTemplateActions';

interface VouchersTemplateState {
  list: Array<VouchersTemplateBase>;
  current?: VoucherTemplate;
  filter: Partial<VouchersTemplateSearchFormValues>;
  priorityList: Array<PriorityVouchersTemplate>;
}

const initialState: VouchersTemplateState = {
  list: [],
  filter: {},
  priorityList: [],
};

export const vouchersTemplateReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getVoucherTemplates.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(clearVoucherTemplates, (state) => {
        state.list = [];
      })
      .addCase(setVoucherTemplate, (state, action) => {
        state.current = action.payload;
      })
      .addCase(setVoucherTemplateFilter, (state, action) => {
        state.filter = action.payload;
      })
      .addCase(getPriorityVoucherTemplates.fulfilled, (state, action) => {
        state.priorityList = action.payload.map((template) => ({
          priority: template.priority,
          owner: {
            id: template.owner.id,
            name: template.owner.name,
          },
        }));
      });
  }
);
