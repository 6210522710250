import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  changesSaved: <Trans i18nKey="alert.changesSaved" defaults="Changes saved" />,
  coTaxationCreated: (
    <Trans
      i18nKey="alert.coTaxationCreated"
      defaults="New co-taxation group added"
    />
  ),
  coTaxationDeleted: (
    <Trans
      i18nKey="alert.coTaxationDeleted"
      defaults="Co-taxation group was deleted"
    />
  ),
  coTaxationUpdated: (
    <Trans
      i18nKey="alert.coTaxationUpdated"
      defaults="Co-taxation group was updated"
    />
  ),
  fareModelCreated: (
    <Trans i18nKey="alert.fareModelCreated" defaults="New fare model added" />
  ),
  fareModelDeleted: (
    <Trans i18nKey="alert.fareModelDeleted" defaults="Fare model deleted" />
  ),
  fareModelFixedFareCreated: (
    <Trans
      i18nKey="alert.fareModelFixedFareCreated"
      defaults="Fixed fare created"
    />
  ),
  fareModelFixedFareUpdated: (
    <Trans
      i18nKey="alert.fareModelFixedFareUpdated"
      defaults="Fixed fare updated"
    />
  ),
  fareModelFixedFaresDeleted: (
    <Trans
      i18nKey="alert.fareModelFixedFaresDeleted"
      defaults="Fixed fare(s) deleted"
    />
  ),
  fareModelUpdated: (
    <Trans
      i18nKey="alert.fareModelUpdated"
      defaults="Fare model data updated"
    />
  ),
  fillAtLeastOne: (
    <Trans
      i18nKey="alert.fillAtLeastOne"
      defaults="At least one filter must be filled"
    />
  ),
  passengerTypeDiscountCreated: (
    <Trans
      i18nKey="alert.passengerTypeDiscountCreated"
      defaults="Passenger type discount {{name}} created"
    />
  ),
  passengerTypeDiscountDeleted: (
    <Trans
      i18nKey="alert.passengerTypeDiscountDeleted"
      defaults="Passenger type discount deleted"
    />
  ),
  passengerTypeDiscountInfoDeleted: (
    <Trans
      i18nKey="alert.passengerTypeDiscountInfoDeleted"
      defaults="Passenger type discount information deleted"
    />
  ),
  passengerTypeDiscountUpdated: (
    <Trans
      i18nKey="alert.passengerTypeDiscountUpdated"
      defaults="Passenger type discount updated"
    />
  ),
  passengerTypeTranslationsDeleted: (
    <Trans
      i18nKey="alert.passengerTypeTranslationsDeleted"
      defaults="Passenger type translation(s) deleted"
    />
  ),
  passengerTypeValidityCriteriaDeleted: (
    <Trans
      i18nKey="alert.validityCriteriaAdd"
      defaults="Passenger type validity criteria(s) deleted"
    />
  ),
  priceCreated: (
    <Trans i18nKey="alert.priceCreated" defaults="New price added" />
  ),
  priceDeleted: <Trans i18nKey="alert.priceDeleted" defaults="Price deleted" />,
  priceUpdated: (
    <Trans i18nKey="alert.priceUpdated" defaults="Price data updated" />
  ),
  promotionalDiscountCreated: (
    <Trans
      i18nKey="alert.promotionalDiscountCreated"
      defaults="Promotional discount {{name}} created"
    />
  ),
  promotionalDiscountDeleted: (
    <Trans
      i18nKey="alert.promotionalDiscountDeleted"
      defaults="Promotional discount deleted"
    />
  ),
  promotionalDiscountUpdated: (
    <Trans
      i18nKey="alert.promotionalDiscountUpdated"
      defaults="Promotional discount updated"
    />
  ),
  voucherTemplateAdd: (
    <Trans
      i18nKey="alert.voucherTemplateAdd"
      defaults="Voucher template was added"
    />
  ),
  voucherTemplateDelete: (
    <Trans
      i18nKey="alert.voucherTemplateDelete"
      defaults="Voucher template was deleted"
    />
  ),
  voucherTemplateUpdate: (
    <Trans
      i18nKey="alert.voucherTemplateUpdate"
      defaults="Voucher template was updated"
    />
  ),
  zoneCreated: <Trans i18nKey="alert.zoneCreated" defaults="New zone added" />,
  zoneDeleted: <Trans i18nKey="alert.zoneDelete" defaults="Zone was deleted" />,
  zoneMapCreated: (
    <Trans i18nKey="alert.zoneMapCreated" defaults="New zone map added" />
  ),
  zoneMapDeleted: (
    <Trans i18nKey="alert.zoneMapDeleted" defaults="Zone map was deleted" />
  ),
  zoneMapUpdated: (
    <Trans i18nKey="alert.zoneMapUpdated" defaults="Zone map was updated" />
  ),
  zoneUpdated: (
    <Trans i18nKey="alert.zoneUpdated" defaults="Zone was updated" />
  ),
});
