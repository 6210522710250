import { api } from '@fleet/shared';
import { PassengerType } from 'dto/passengerType';
import qs from 'qs';

export const fetchCardTypes = async (ownerId: number) =>
  (
    await api.get(`/business-entities/${ownerId}/card-types`, {
      baseURL: process.env.REACT_APP_API_OM,
    })
  ).data.items;

export const fetchPassengerTypes = async (ownerId: number) =>
  (
    await api.get<{ items: Array<PassengerType> }>(
      `/passenger-types${qs.stringify(
        { ownerId, limit: 9999 },
        {
          addQueryPrefix: true,
        }
      )}`
    )
  ).data.items;
