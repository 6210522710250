import _flow from 'lodash/fp/flow';
import _lowerCase from 'lodash/fp/lowerCase';
import _toString from 'lodash/fp/toString';
import _split from 'lodash/fp/split';
import _some from 'lodash/fp/some';
import _includes from 'lodash/fp/includes';

type String = string | null | undefined;
const clean = (str?: String) =>
  typeof str === 'string'
    ? // eslint-disable-next-line no-useless-escape
      str.replace(/[`~!@#$%^&*()_|+\-=÷¿?;:'",.<>{}\[\]\\\/]/gi, '')
    : '';

const prepareString = _flow(_toString, _lowerCase, clean);

const includesSpace = _includes(' ');

type Filter = (string: String) => boolean;

export const filterByKeyword = (keyword: string): Filter => {
  const preparedInput = prepareString(keyword);
  const includesPreparedInput = _includes(preparedInput);
  const someStartWithPreparedInput = _flow(
    _split(' '),
    _some(_includes(preparedInput))
  );

  const hasEntryPreparedInput = includesSpace(preparedInput)
    ? includesPreparedInput
    : someStartWithPreparedInput;

  return _flow(prepareString, hasEntryPreparedInput);
};
