import { createAsyncThunk } from 'store/utils';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import {
  PassengerType,
  PassengerTypeFilter,
  PassengerTypeTranslation,
  PassengerTypeValidityCriteria,
  PassengerTypeValidityCriteriaPayload,
} from 'dto/passengerType';
import { selectCurrentPassengerType } from 'features/passengerType/passengerTypeSelectors';
import { createAction } from '@reduxjs/toolkit';
import { api } from '@fleet/shared';
import { Pagination } from '@fleet/shared/dto/pagination';
import qs from 'qs';

export const setPassengerType = createAction<PassengerType | undefined>(
  'passengerType/setPassengerType'
);

export const getPassengerType = createAsyncThunk<void, string>(
  'passengerType/getPassengerType',
  async (id, { dispatch }) => {
    const [
      { data: passengerType },
      { data: translations },
      { data: validityCriterias },
    ] = await Promise.all([
      api.get<PassengerType>(`/passenger-types/${id}`),
      api.get<{ items: Array<PassengerTypeTranslation> }>(
        `/passenger-types/${id}/localizations`
      ),
      api.get<{ items: Array<PassengerTypeValidityCriteria> }>(
        `/passenger-types/${id}/validity-criterias`
      ),
    ]);

    dispatch(
      setPassengerType({
        ...passengerType,
        translations: translations.items,
        validityCriterias: validityCriterias.items,
      })
    );
  }
);

export const setPassengerTypeFilter = createAction<
  Partial<PassengerTypeFilter>
>('passengerType/setPassengerTypeFilter');

export const getPassengerTypes = createAsyncThunk<
  Pagination<PassengerType>,
  Partial<PassengerTypeFilter> | undefined
>('passengerType/getPassengerTypes', async (values, { dispatch, getState }) => {
  if (values) {
    dispatch(setPassengerTypeFilter(values));
  }

  const { filter } = getState().passengerType;

  return (
    await api.get<Pagination<PassengerType>>(
      `/passenger-types${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`
    )
  ).data;
});

export const createPassengerType = createAsyncThunk<
  PassengerType,
  PassengerType
>('passengerType/createPassengerType', async (payload, { getState }) => {
  return (
    await api.post<PassengerType>(`/passenger-types`, {
      ...payload,
      ownerId: selectCurrentBusinessEntityId(getState()),
    })
  ).data;
});

export const updatePassengerType = createAsyncThunk<
  PassengerType,
  PassengerType
>(
  'passengerType/updatePassengerType',
  async ({ id, ...payload }, { getState }) => {
    return (
      await api.put<PassengerType>(`/passenger-types/${id}`, {
        ...payload,
        ownerId: selectCurrentBusinessEntityId(getState()),
      })
    ).data;
  }
);

export const updateOrCreatePassengerTypeTranslation = createAsyncThunk<
  void,
  PassengerTypeTranslation
>(
  'passengerType/updateOrCreatePassengerTypeTranslation',
  async (payload, { getState }) => {
    const passengerType = selectCurrentPassengerType(getState());
    if (!passengerType) return;
    const { id: passengerTypeId } = passengerType;
    const { id: localizationId, ...data } = payload;

    await (localizationId ? api.put : api.post)(
      `/passenger-types/${passengerTypeId}/localizations${
        localizationId ? `/${localizationId}` : ''
      }`,
      data
    );
  }
);

export const deletePassengerTypeTranslation = createAsyncThunk<void, number>(
  'passengerType/deletePassengerTypeTranslation',
  async (localizationId, { getState }) => {
    const passengerType = selectCurrentPassengerType(getState());
    if (!passengerType) return;
    const { id: passengerTypeId } = passengerType;
    await api.delete(
      `/passenger-types/${passengerTypeId}/localizations/${localizationId}`
    );
  }
);

export const updateOrCreatePassengerTypeValidityCriteria = createAsyncThunk<
  void,
  PassengerTypeValidityCriteriaPayload
>(
  'passengerType/updateOrCreatePassengerTypeValidityCriteria',
  async ({ id: validityCriteriaId, ...data }, { getState }) => {
    const passengerType = selectCurrentPassengerType(getState());
    if (!passengerType) return;
    const { id: passengerTypeId } = passengerType;

    await (validityCriteriaId ? api.put : api.post)(
      `/passenger-types/${passengerTypeId}/validity-criterias${
        validityCriteriaId ? `/${validityCriteriaId}` : ''
      }`,
      data
    );
  }
);

export const deletePassengerTypeValidityCriteria = createAsyncThunk<
  void,
  string
>(
  'passengerType/deletePassengerTypeValidityCriteria',
  async (validityCriteriaId, { getState }) => {
    const passengerType = selectCurrentPassengerType(getState());
    if (!passengerType) return;
    const { id: passengerTypeId } = passengerType;
    await api.delete(
      `/passenger-types/${passengerTypeId}/validity-criterias/${validityCriteriaId}`
    );
  }
);
