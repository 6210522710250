import type { ChangeEventHandler, FC, FormEvent } from 'react';
import { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Input, RadioGroupProps } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { RadioGroup } from '@fleet/shared';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    form: {
      flex: 1,
    },
    inputSearchAdornment: {
      marginRight: theme.spacing(-1),
    },
  }),
  {
    name: 'PriceDetailsTabZonesFilterForm',
  }
);

type InputName = 'name' | 'code';
export type TabZonesFilters = { assigned?: boolean } & {
  [key in InputName]?: string;
};

interface PriceDetailsTabZonesFilterFormProps {
  onChange: (filters: TabZonesFilters) => void;
}

export const PriceDetailsTabZonesFilterForm: FC<PriceDetailsTabZonesFilterFormProps> =
  ({ onChange }) => {
    const [filters, setFilters] = useState<TabZonesFilters>({});

    const handleSubmit = useCallback(
      (event?: FormEvent) => {
        event?.preventDefault();
        onChange(filters);
      },
      [filters, onChange]
    );

    const handleSetFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        const { id: name, value } = event.target;
        setFilters((old) => ({ ...old, [name as InputName]: value }));
      },
      []
    );

    const handleChangeAssigned = useCallback<
      Required<RadioGroupProps>['onChange']
    >(
      (value) => {
        const values = { ...filters };
        values.assigned = value as boolean;
        setFilters(values);
        onChange(values);
      },
      [filters, onChange]
    );

    const classes = useStyles();
    return (
      <Stack direction="row" sx={{ pb: 3 }} spacing={2}>
        <RadioGroup
          name="assigned"
          label={<TransField i18nKey="assigned" />}
          options="BOOL"
          defaultValue=""
          onChange={handleChangeAssigned}
          inline
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <Input
            name="name"
            label={<TransField i18nKey="zoneName" />}
            endAdornment={
              <InputAdornment
                position="end"
                className={classes.inputSearchAdornment}
              >
                <IconButton type="submit">
                  <Icon name="search" />
                </IconButton>
              </InputAdornment>
            }
            onChange={handleSetFilter}
          />
        </form>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Input
            name="code"
            label={<TransField i18nKey="code" />}
            endAdornment={
              <InputAdornment
                position="end"
                className={classes.inputSearchAdornment}
              >
                <IconButton type="submit">
                  <Icon name="search" />
                </IconButton>
              </InputAdornment>
            }
            onChange={handleSetFilter}
          />
        </form>
      </Stack>
    );
  };
