import { InventoryClass } from 'dto/classification';
import qs from 'qs';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';

export const fetchInventoryClasses = async (ownerId: number) =>
  (
    await api.get<Pagination<InventoryClass>>(
      `/business-entities/${ownerId}/inventory-classes${qs.stringify(
        { limit: 100 },
        {
          addQueryPrefix: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_VM,
      }
    )
  ).data.items;
