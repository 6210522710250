import { useCallback } from 'react';
import { isAfter, isBefore, parseISO } from 'date-fns';

export const useValidAccessor = <T extends Date | string>() =>
  useCallback((row: { validity: { from: T; to?: T } }) => {
    if (!row?.validity) {
      return;
    }

    const { from, to } = row.validity;
    const now = Date.now();
    const [validFromDate, validToDate] = [from, to]
      .filter(Boolean)
      .map((str) => parseISO(str! as string));
    const validityStarted = isAfter(now, validFromDate);
    const validityActive = validToDate ? isBefore(now, validToDate) : true;

    return validityStarted && validityActive;
  }, []);
