import { FC, useCallback, useMemo } from 'react';
import {
  TableColumns,
  decimalToPercentage,
  percentageToDecimal,
  renderPercentageError,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { validate } from '@fleet/shared/form/validate';
import {
  createOrUpdatePromotionalDiscountBookingQuantity,
  removePromotionalDiscountBookingQuantities,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import { isDiscountAmountFieldDisabled } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/utils';

interface PromotionalDiscountTabBookingQuantityProps {}

type Entity = PromotionalDiscount['bookingQuantity'][number];

export const PromotionalDiscountTabBookingQuantity: FC<PromotionalDiscountTabBookingQuantityProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { bookingQuantity } = currentDiscount;
    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          accessor: 'ticketCountFrom',
          Header: <TransTableHead i18nKey="from" />,
          editableProps: {
            validate: validate.required(),
          },
        },
        {
          accessor: 'ticketCountTo',
          editableProps: {
            validate: validate.required(),
          },
          Header: <TransTableHead i18nKey="to" />,
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'bookingQuantity',
            }),
          },
        },
      ],
      [currentDiscount]
    );

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({ discountPercentageAmount, ...rest }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountBookingQuantity({
            ...rest,
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountBookingQuantities(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={bookingQuantity.map((quantity) => ({
          ...quantity,
          discountPercentageAmount: quantity.discountPercentageAmount
            ? decimalToPercentage(quantity.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
