import { FC, useCallback, useEffect, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import { FareModelPayload } from 'dto/fareModel';
import {
  createFareModel,
  getFareModel,
  getFareModels,
  setFareModel,
  updateFareModel,
} from 'features/fareModel/fareModelActions';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { TransHint } from 'i18n/trans/hint';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  DrawerForm,
  FormProvider,
  formSubmit,
  Loadable,
  PeriodField,
  RadioGroupField,
  SelectField,
  SelectOwnerField,
  useForm,
} from '@fleet/shared';
import { ConditionField, TextField } from '@fleet/shared/form';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { useAlert } from 'react-alert';
import { FareModelCopyModal } from 'routes/FareModels/FareModelCopyModal';
import { FareModelDeleteModal } from 'routes/FareModels/FareModelDeleteModal';
import { useDispatch, useSelector } from 'store/utils';
import { fareModelLoadingSelector } from 'features/loading/loadingSelectors';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

const IS_DELETE_FUNCTIONALITY_HIDDEN = true; //This functionality isn't fully implemented in the BE yet

const useStyles = makeStyles(
  () => ({
    action: { alignSelf: 'flex-start' },
  }),
  {
    name: 'FareModelForm',
  }
);

interface FareModelFormProps {}

export const FareModelForm: FC<FareModelFormProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const dispatch = useDispatch();
  const currentFareModel = useSelector(fareModelSelector);
  const currentFareModelId = currentFareModel?.id;
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const validityPeriodInterpretationOptions = useClassificationOptions(
    ClassificationGroup.VALIDITY_PERIOD_INTERPRETATION
  );
  const history = useHistory();
  const alert = useAlert();
  const classes = useStyles();
  const loading = useSelector(fareModelLoadingSelector);

  useEffect(() => {
    dispatch(setFareModel());

    if (action === 'edit' && id) {
      dispatch(getFareModel(id));
    }

    return () => {
      dispatch(setFareModel());
    };
  }, [action, dispatch, id]);

  const onSubmit = useCallback(
    (payload: FareModelPayload) => {
      formSubmit(async () => {
        await dispatch(
          (currentFareModelId ? updateFareModel : createFareModel)(payload)
        ).unwrap();
        await dispatch(getFareModels());
        history.replace('/fare-models');
        alert.success(
          <TransAlert
            i18nKey={
              currentFareModelId ? 'fareModelUpdated' : 'fareModelCreated'
            }
          />
        );
      });
    },
    [alert, currentFareModelId, dispatch, history]
  );

  const initialValues = useMemo(() => {
    const fareTypes = (currentFareModel?.fareTypes ?? []).map(({ id }) => id);
    return {
      ownerId: currentBusinessEntityId,
      isActive: false,
      areFaresBidirectional: false,
      validityPeriodInterpretationId:
        'VALIDITY_PERIOD_INTERPRETATION.SALES_PERIOD',
      hasOriginDestinationFares: fareTypes.includes(
        'FARE_TYPE.ORIGIN_DESTINATION'
      ),
      useYieldedMultilegDistancePricing: false,
      hasZoneFares: fareTypes.includes('FARE_TYPE.ZONE'),
      hasDistanceFares: fareTypes.includes('FARE_TYPE.DISTANCE'),
      hasFixedFares: fareTypes.includes('FARE_TYPE.FIXED'),
      ...(currentFareModelId && {
        ...currentFareModel,
        validityPeriodInterpretationId:
          currentFareModel?.validityPeriodInterpretation?.id,
      }),
    };
  }, [currentBusinessEntityId, currentFareModel, currentFareModelId]);

  const { form, handleSubmit, submitting } = useForm<FareModelPayload>({
    initialValues,
    onSubmit,
    subscription: { submitting: true, values: true },
  });

  const { batch, change } = form;

  const handleActiveFareModel = useCallback(
    (isActive: boolean) => async () => {
      const { validityPeriodInterpretation, ...rest } = currentFareModel!;
      await dispatch(
        updateFareModel({
          ...rest,
          validityPeriodInterpretationId: validityPeriodInterpretation?.id,
          isActive,
        })
      ).unwrap();
      batch(() => change('isActive', isActive));
      await dispatch(getFareModels());
    },
    [batch, change, currentFareModel, dispatch]
  );

  const closeDrawer = useCallback(() => {
    history.replace('/fare-models');
    dispatch(setFareModel());
  }, [dispatch, history]);

  return (
    <DrawerForm
      onClose={closeDrawer}
      open
      arrow={false}
      disablePortal={false}
      hideBackdrop={false}
      width={424}
    >
      <Loadable loading={loading}>
        <FormProvider form={form}>
          <Stack
            sx={{
              height: '100%',
              width: '424px !important',
              minWidth: '0 !important',
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <CardHeader
              sx={{ pb: 0 }}
              classes={{ action: classes.action }}
              title={
                <Typography variant="subtitle" component="div">
                  <TransTitle i18nKey="fareModel" />
                </Typography>
              }
              action={
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              }
              subheader={
                currentFareModelId && (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    sx={{ mt: 1 }}
                  >
                    <FareModelCopyModal />
                    <ConditionField when="isActive" is={true}>
                      {(isFareModelActive) => (
                        <Button
                          size="small"
                          startIcon={
                            <Icon
                              name={
                                isFareModelActive
                                  ? 'deactivate'
                                  : 'check-circle'
                              }
                              size={16}
                            />
                          }
                          onClick={handleActiveFareModel(!isFareModelActive)}
                        >
                          <TransButton
                            i18nKey={
                              isFareModelActive ? 'deactivate' : 'activate'
                            }
                          />
                        </Button>
                      )}
                    </ConditionField>
                    {!IS_DELETE_FUNCTIONALITY_HIDDEN && (
                      <FareModelDeleteModal />
                    )}
                  </Stack>
                )
              }
            />
            <CardContent sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
              <TextField
                label={<TransField i18nKey="name" />}
                name="name"
                required
                margin="normal"
              />
              <SelectOwnerField
                businessEntities={businessEntities}
                allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
                margin="normal"
                disabled
              />
              <SelectField
                label={<TransField i18nKey="validityPeriodInterpretation" />}
                name="validityPeriodInterpretationId"
                margin="normal"
                options={validityPeriodInterpretationOptions}
              />
              <PeriodField
                from={{
                  name: 'validity.from',
                  label: <TransField i18nKey="validFrom" />,
                  margin: 'normal',
                  required: true,
                }}
                to={{
                  name: 'validity.to',
                  label: <TransField i18nKey="validTo" />,
                  margin: 'normal',
                  isClearable: true,
                }}
                grid={false}
              />
              <RadioGroupField
                options={[
                  {
                    value: true,
                    label: <TransField i18nKey="fareDirection.bidirectional" />,
                  },
                  {
                    value: false,
                    label: (
                      <TransField i18nKey="fareDirection.unidirectional" />
                    ),
                  },
                ]}
                name="areFaresBidirectional"
                label={
                  <Stack direction="row" alignItems="center">
                    <TransField i18nKey="fareDirection" />
                    <Tooltip
                      content={<TransHint i18nKey="fareDirection" />}
                      delay={500}
                    >
                      <Icon name="question" size={16} margin />
                    </Tooltip>
                  </Stack>
                }
                margin="normal"
                inline
              />
              <RadioGroupField
                options="BOOL_ONLY"
                name="useYieldedMultilegDistancePricing"
                label={<TransField i18nKey="yieldedMultilegDistancePricing" />}
                margin="normal"
                inline
              />
              <Typography paragraph variant="subtitle" sx={{ mt: 2, mb: 2 }}>
                <TransSubtitle i18nKey="connectedFareTypes" />
              </Typography>

              <RadioGroupField
                options="BOOL_ONLY"
                name="hasOriginDestinationFares"
                label={<TransField i18nKey="originDestinationFaresActive" />}
                helper={
                  <ConditionField when="id" is={Boolean}>
                    <Link to={`/fare-models/edit/${id}/stops`}>
                      <TransField i18nKey="originDestinationFaresActive.helper" />
                    </Link>
                  </ConditionField>
                }
                margin="normal"
                disabled
                inline
              />

              <Divider sx={{ mt: 1 }} />

              <RadioGroupField
                options="BOOL_ONLY"
                name="hasZoneFares"
                label={<TransField i18nKey="zoneMapFaresActive" />}
                helper={
                  <ConditionField when="id" is={Boolean}>
                    <Link
                      to={`/zone-fares/maps?fareModelId=${currentFareModelId}`}
                    >
                      <TransField i18nKey="zoneMapFaresActive.helper" />
                    </Link>
                  </ConditionField>
                }
                margin="normal"
                disabled
                inline
              />

              <Divider sx={{ mt: 1 }} />

              <RadioGroupField
                options="BOOL_ONLY"
                name="hasDistanceFares"
                label={<TransField i18nKey="distanceFaresActive" />}
                helper={
                  currentFareModelId && (
                    <Link to={`/fare-models/edit/${id}/distances`}>
                      <TransField i18nKey="distanceFaresActive.helper" />
                    </Link>
                  )
                }
                margin="normal"
                disabled
                inline
              />

              <Divider sx={{ mt: 1 }} />

              <RadioGroupField
                options="BOOL_ONLY"
                name="hasFixedFares"
                label={<TransField i18nKey="fixedFaresActive" />}
                helper={
                  currentFareModelId && (
                    <Link to={`/fare-models/edit/${id}/fixed`}>
                      <TransField i18nKey="fixedFaresActive.helper" />
                    </Link>
                  )
                }
                margin="normal"
                disabled
                inline
              />
            </CardContent>
            <CardActions
              sx={{
                padding: 3,
                justifyContent: 'flex-end',
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Button variant="text" color="primary" onClick={closeDrawer}>
                <TransButton i18nKey="cancel" />
              </Button>
              {id ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<Icon name="check" />}
                >
                  <TransButton i18nKey="save" disabled={submitting} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                  startIcon={<Icon name="plus" />}
                >
                  <TransButton i18nKey="add" />
                </Button>
              )}
            </CardActions>
          </Stack>
        </FormProvider>
      </Loadable>
    </DrawerForm>
  );
};
