import type { Pagination } from '@fleet/shared/dto/pagination';
import type { PriceListsItem, PriceListFormValues } from 'dto/zonePriceList';
import { createReducer } from '@reduxjs/toolkit';
import {
  setPriceLists,
  setPriceList,
  setPriceListsFilter,
  updatePriceLists,
} from 'features/zonePriceList/zonePriceListActions';

export interface ZonePriceListState {
  list?: Pagination<PriceListsItem>;
  filter: Partial<PriceListFormValues>;
  current?: PriceListsItem;
}

const initialState: ZonePriceListState = {
  filter: {},
};

export const zonePriceListReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPriceListsFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(setPriceLists, (state, action) => {
      state.list = action.payload;
    })
    .addCase(updatePriceLists, (state, action) => {
      // state.list = action.payload;
      const { payload: priceList } = action;
      if (!state.list?.items) return;
      state.list.items = state.list.items.map((item) =>
        item.id === priceList.id ? priceList : item
      );
    })
    .addCase(setPriceList, (state, action) => {
      state.current = action.payload;
    });
});
