import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import {
  PassengerTypeDiscount,
  PassengerTypeDiscountFilter,
} from 'dto/passengerTypeDiscount';
import {
  clearPassengerTypeDiscounts,
  getPassengerTypeDiscounts,
  setPassengerTypeDiscount,
  setPassengerTypeDiscountsFilter,
} from 'features/passengerTypeDiscount/passengerTypeDiscountActions';

export interface PassengerTypeDiscountState {
  list?: Pagination<PassengerTypeDiscount>;
  filter: Partial<PassengerTypeDiscountFilter>;
  current?: PassengerTypeDiscount;
}

const initialState: PassengerTypeDiscountState = {
  filter: {},
};

export const passengerTypeDiscountReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setPassengerTypeDiscountsFilter, (state, action) => {
        state.filter = action.payload;
      })
      .addCase(clearPassengerTypeDiscounts, (state) => {
        state.list = undefined;
      })
      .addCase(getPassengerTypeDiscounts.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setPassengerTypeDiscount, (state, action) => {
        state.current = action.payload;
      });
  }
);
