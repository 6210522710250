import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { SelectField } from '@fleet/shared/form';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import {
  Button,
  FormControl,
  FormProvider,
  Icon,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import type { Config as FormConfig } from 'final-form';

enum NameSearchBehaviourType {
  CONTAINS = 'contains',
  STARTS_WITH = 'startsWith',
  EXACT = 'exact',
}

export interface StopsSearchParams {
  name: string;
  externalReference: string;
  countryId: number;
  countyName: string;
  cityName: string;
  streetAddress: string;
  nameSearchBehaviour: NameSearchBehaviourType;
}

interface FareModelStopsSelectFormProps {
  onSubmit: FormConfig<StopsSearchParams>['onSubmit'];
  allowSearch: boolean;
}

export const FareModelStopsSelectForm: FC<FareModelStopsSelectFormProps> = ({
  onSubmit,
  allowSearch,
}) => {
  const { form, handleSubmit, submitting } = useForm<StopsSearchParams>({
    initialValues: { nameSearchBehaviour: NameSearchBehaviourType.CONTAINS },
    onSubmit,
    subscription: { submitting: true },
  });
  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);
  const nameSearchBehaviourOptions = useMemo(
    () => [
      {
        value: NameSearchBehaviourType.CONTAINS,
        label: <TransField i18nKey="nameSearchBehaviour.CONTAINS" />,
      },
      {
        value: NameSearchBehaviourType.STARTS_WITH,
        label: <TransField i18nKey="nameSearchBehaviour.STARTS_WITH" />,
      },
      {
        value: NameSearchBehaviourType.EXACT,
        label: <TransField i18nKey="nameSearchBehaviour.EXACT" />,
      },
    ],
    []
  );

  return (
    <FormProvider form={form}>
      <Grid
        container
        columns={3}
        spacing={2}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid item xs={1}>
          <TextField label={<TransField i18nKey="name" />} name="name" />
        </Grid>
        {allowSearch && (
          <>
            <Grid item xs={1}>
              <SelectField
                label={<TransField i18nKey="filteringMethod" />}
                name="nameSearchBehaviour"
                options={nameSearchBehaviourOptions}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                label={<TransField i18nKey="externalReference" />}
                name="externalReference"
              />
            </Grid>
          </>
        )}
        <Grid item xs={1}>
          <SelectField
            label={<TransField i18nKey="country" />}
            name="countryId"
            options={countryOptions}
            showEmptyOption
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label={<TransField i18nKey="county" />}
            name="countyName"
          />
        </Grid>
        <Grid item xs={1}>
          <TextField label={<TransField i18nKey="city" />} name="cityName" />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label={<TransField i18nKey="streetAndHouseNumber" />}
            name="streetAddress"
          />
        </Grid>

        <Grid item xs="auto" sx={{ ml: 'auto' }}>
          <Stack direction="row" spacing={2}>
            <FormControl label="&nbsp;">
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                variant="text"
                onClick={handleReset}
              >
                <TransButton i18nKey="resetFilters" />
              </Button>
            </FormControl>
            <FormControl label="&nbsp;">
              {allowSearch ? (
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<Icon name="search" />}
                  disabled={submitting}
                >
                  <TransButton i18nKey="search" />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<Icon name="filter" />}
                >
                  <TransButton i18nKey="filter" />
                </Button>
              )}
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
