import {
  addUpdateOriginDestinationFare,
  createFareModel,
  deleteFareModel,
  getFareModel,
  getFareModelOriginDestinationFares,
  getFareModels,
  getFareModelOriginDestinationStops,
  updateFareModel,
  updateFareModelStops,
} from 'features/fareModel/fareModelActions';
import {
  deletePassengerTypeDiscount,
  deletePassengerTypeDiscountInfo,
  deletePassengerTypeDiscountInfosBulk,
  getPassengerTypeDiscount,
  getPassengerTypeDiscounts,
  updateOrCreatePassengerTypeDiscountInfo,
} from 'features/passengerTypeDiscount/passengerTypeDiscountActions';
import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
  getInventoryClasses,
} from 'features/classification/classificationActions';
import {
  createPassengerType,
  getPassengerType,
  getPassengerTypes,
  updateOrCreatePassengerTypeTranslation,
  updateOrCreatePassengerTypeValidityCriteria,
  updatePassengerType,
} from 'features/passengerType/passengerTypeActions';
import {
  createCoTaxationGroup,
  getCoTaxationGroup,
  getCoTaxationGroups,
  updateCoTaxationGroup,
} from 'features/coTaxationGroup/coTaxationGroupActions';
import {
  createVoucherTemplate,
  deleteVoucherTemplate,
  getVoucherTemplate,
  getVoucherTemplates,
  updateVoucherTemplate,
} from 'features/vouchersTemplate/vouchersTemplateActions';
import {
  getVoucher,
  getVouchers,
  updateVoucher,
} from 'features/voucher/vouchersActions';
import {
  addPriceListZone,
  deletePriceList,
  deletePriceListZone,
  getPriceList,
  getPriceLists,
  updatePriceList,
  updatePriceListPrice,
  updatePriceListPrices,
  updatePriceListZone,
} from 'features/zonePriceList/zonePriceListActions';
import {
  addStopsToZone,
  createZoneMap,
  deleteStopsFromZone,
  deleteZoneMaps,
  deleteZones,
  getZone,
  getZoneMap,
  getZoneMapList,
  updateZoneMap,
} from 'features/zoneMap/zoneMapActions';
import {
  createPromotionalDiscount,
  getPromotionalDiscount,
  getPromotionalDiscounts,
  updatePromotionalDiscount,
} from 'features/promotionalDiscount/promotionalDiscountActions';
import {
  getFareModelCommercialDistanceStops,
  getFareModelDistanceFares,
  getFareModelCommercialDistances,
  updateFareModelCommercialDistanceStops,
  updateFareModelCommercialDistance,
  deleteFareModelCommercialDistance,
} from 'features/fareModel/fareModelDistanceActions';
import {
  createOrUpdateFixedFare,
  deleteFixedFares,
  getFixedFares,
} from 'features/fareModel/fareModelFixedActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);

export const passengerTypesLoadingSelector =
  createLoadingSelector(getPassengerTypes);

export const passengerTypeLoadingSelector = createLoadingSelector(
  getPassengerType,
  createPassengerType,
  updatePassengerType,
  updateOrCreatePassengerTypeTranslation,
  updateOrCreatePassengerTypeValidityCriteria
);

export const passengerTypeDiscountsLoadingSelector = createLoadingSelector(
  getPassengerTypeDiscounts,
  deletePassengerTypeDiscount
);

export const passengerTypeDiscountLoadingSelector = createLoadingSelector(
  getPassengerTypeDiscount,
  updateOrCreatePassengerTypeDiscountInfo,
  deletePassengerTypeDiscountInfo,
  deletePassengerTypeDiscountInfosBulk
);

export const promotionalDiscountsTableLoadingSelector = createLoadingSelector(
  getPromotionalDiscounts
);

export const promotionalDiscountLoadingSelector = createLoadingSelector(
  getPromotionalDiscount,
  createPromotionalDiscount,
  updatePromotionalDiscount
);

export const coTaxationGroupsLoading =
  createLoadingSelector(getCoTaxationGroups);
export const coTaxationGroupLoading = createLoadingSelector(
  getCoTaxationGroup,
  createCoTaxationGroup,
  updateCoTaxationGroup
);

export const vouchersTemplatesLoadingSelector =
  createLoadingSelector(getVoucherTemplates);
export const vouchersTemplateLoadingSelector = createLoadingSelector(
  getVoucherTemplate,
  createVoucherTemplate,
  updateVoucherTemplate,
  deleteVoucherTemplate
);

export const vouchersLoadingSelector = createLoadingSelector(getVouchers);
export const voucherLoadingSelector = createLoadingSelector(
  getVoucher,
  updateVoucher
);

export const priceListsLoadingSelector = createLoadingSelector(
  getPriceLists,
  deletePriceList
);
export const priceLoadingSelector = createLoadingSelector(
  getPriceList,
  updatePriceList,
  updatePriceListPrices,
  updatePriceListPrice,
  addPriceListZone,
  deletePriceListZone,
  updatePriceListZone
);

export const fareModelsLoadingSelector = createLoadingSelector(getFareModels);
export const fareModelLoadingSelector = createLoadingSelector(
  getFareModel,
  createFareModel,
  updateFareModel,
  deleteFareModel
);

export const fareModelStopsLoadingSelector = createLoadingSelector(
  getFareModel,
  getFareModelOriginDestinationStops,
  getFareModelOriginDestinationFares,
  getInventoryClasses,
  updateFareModelStops,
  addUpdateOriginDestinationFare
);

export const zoneMapListLoading = createLoadingSelector(
  deleteZoneMaps,
  getZoneMapList
);

export const zoneMapLoading = createLoadingSelector(
  getZoneMap,
  createZoneMap,
  updateZoneMap,
  deleteZones
);

export const zoneLoading = createLoadingSelector(getZone, deleteStopsFromZone);

export const stopsLoading = createLoadingSelector(addStopsToZone);

export const fareModelDistancesLoadingSelector = createLoadingSelector(
  getFareModel,
  getFareModelDistanceFares,
  getFareModelCommercialDistanceStops,
  updateFareModelCommercialDistanceStops,
  getFareModelCommercialDistances,
  updateFareModelCommercialDistance,
  deleteFareModelCommercialDistance
);

export const fareModelFixedLoadingSelector = createLoadingSelector(
  getFareModel,
  getFixedFares,
  createOrUpdateFixedFare,
  deleteFixedFares
);
