import { FC, useCallback, useMemo } from 'react';
import {
  TableColumns,
  decimalToPercentage,
  percentageToDecimal,
  renderPercentageError,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { validate } from '@fleet/shared/form/validate';
import {
  createOrUpdatePromotionalDiscountLineType,
  removePromotionalDiscountLineTypes,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import { isDiscountAmountFieldDisabled } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/utils';

interface PromotionalDiscountTabLineTypeProps {}

type Entity = PromotionalDiscount['lineType'][number];

export const PromotionalDiscountTabLineType: FC<PromotionalDiscountTabLineTypeProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { lineType } = currentDiscount;
    const lineTypeOptions = useClassificationOptions(
      ClassificationGroup.BUS_LINE_TYPE
    );
    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          id: 'lineType.id',
          accessor: ({ lineType }) => lineType?.id,
          type: 'select',
          editableProps: {
            options: lineTypeOptions,
            validate: validate.required(),
          },
          Header: <TransTableHead i18nKey="lineType" />,
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'lineType',
            }),
          },
        },
      ],
      [currentDiscount, lineTypeOptions]
    );

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({ lineType, discountPercentageAmount, ...rest }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountLineType({
            ...rest,
            lineTypeId: lineType.id,
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountLineTypes(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={lineType.map((lineType) => ({
          ...lineType,
          discountPercentageAmount: lineType.discountPercentageAmount
            ? decimalToPercentage(lineType.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
