import { FC, useCallback, useMemo } from 'react';
import {
  TableColumns,
  decimalToPercentage,
  percentageToDecimal,
  renderPercentageError,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { validate } from '@fleet/shared/form/validate';
import {
  createOrUpdatePromotionalDiscountTransactionTime,
  removePromotionalDiscountTransactionTimes,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { isDiscountAmountFieldDisabled } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/utils';

interface PromotionalDiscountTabTransactionTimeProps {}

type Entity = PromotionalDiscount['transactionTime'][number];

export const PromotionalDiscountTabTransactionTime: FC<PromotionalDiscountTabTransactionTimeProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { transactionTime } = currentDiscount;
    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          id: 'transactionTimeFrom',
          accessor: ({ transactionTimeFrom }) =>
            formatDate(
              transactionTimeFrom,
              currentLocaleConfiguration.timeFormat
            ),
          Header: <TransTableHead i18nKey="from" />,
          type: 'date',
          editableProps: {
            validate: validate.required(),
            showTimeSelect: true,
            showTimeSelectOnly: true,
            dateFormat: currentLocaleConfiguration.timeFormat,
            timeFormat: currentLocaleConfiguration.timeFormat,
            timeIntervals: 5,
          },
        },
        {
          id: 'transactionTimeTo',
          accessor: ({ transactionTimeTo }) =>
            formatDate(
              transactionTimeTo,
              currentLocaleConfiguration.timeFormat
            ),
          type: 'date',
          editableProps: {
            validate: validate.required(),
            showTimeSelect: true,
            showTimeSelectOnly: true,
            dateFormat: currentLocaleConfiguration.timeFormat,
            timeFormat: currentLocaleConfiguration.timeFormat,
            timeIntervals: 5,
          },
          Header: <TransTableHead i18nKey="to" />,
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'transactionTime',
            }),
          },
        },
      ],
      [currentDiscount]
    );

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({
        transactionTimeTo,
        transactionTimeFrom,
        discountPercentageAmount,
        ...rest
      }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountTransactionTime({
            ...rest,
            transactionTimeFrom: formatDate(
              transactionTimeFrom,
              isoDateTimeFormat.split("'T'")[1]
            ),
            transactionTimeTo: formatDate(
              transactionTimeTo,
              isoDateTimeFormat.split("'T'")[1]
            ),
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountTransactionTimes(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={transactionTime.map((time) => ({
          ...time,
          transactionTimeTo: `2001-01-01T${time.transactionTimeTo}`,
          transactionTimeFrom: `2001-01-01T${time.transactionTimeFrom}`,
          discountPercentageAmount: time.discountPercentageAmount
            ? decimalToPercentage(time.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
