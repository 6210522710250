import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { FareModelStopsTable } from 'routes/FareModels/FareModelStops/FareModelStopsTable';
import { FareModelStopsMatrix } from 'routes/FareModels/FareModelStops/FareModelStopsMatrix';
import { SearchResult } from '@fleet/shared';
import { useContext } from 'react';
import { FareModelStopsContext } from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { FareModelStopsMatrixCellFares } from 'routes/FareModels/FareModelStops/FareModelStopsMatrixCellFares';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      height: '100%',
      minHeight: 232,
      flex: 1,
    },
    data: {
      height: '100%',
      flex: 1,
    },
  }),
  {
    name: 'FareModelStopsResult',
  }
);

export type FareModelStopsResultViewMode = 'matrix' | 'list';

interface FareModelStopsResultProps {
  view: FareModelStopsResultViewMode;
}

export const FareModelStopsResult: FC<FareModelStopsResultProps> = ({
  view,
}) => {
  const { originStopUuids, destinationStopUuids } = useContext(
    FareModelStopsContext
  );

  const classes = useStyles();
  return (
    <SearchResult
      results={
        originStopUuids.length &&
        !(
          originStopUuids.length === 1 &&
          originStopUuids === destinationStopUuids
        )
      }
    >
      <div className={classes.root}>
        {view === 'matrix' ? (
          <FareModelStopsMatrix
            contentComponent={FareModelStopsMatrixCellFares}
          />
        ) : (
          <FareModelStopsTable />
        )}
      </div>
    </SearchResult>
  );
};
