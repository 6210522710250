import { createAsyncThunk } from 'store/utils';
import qs from 'qs';
import {
  Infos,
  PriorityVouchersTemplate,
  UnitPrice,
  VouchersTemplateBase,
  VouchersTemplateSearchFormValues,
  VoucherTemplate,
  VoucherTemplatePayload,
} from 'dto/vouchersTemplate';
import { createAction } from '@reduxjs/toolkit';
import { selectCurrentTemplateId } from 'features/vouchersTemplate/vouchersTemplateSelector';
import { api } from '@fleet/shared';

export const setVoucherTemplate = createAction<VoucherTemplate | undefined>(
  'voucherTemplate/setVoucherTemplate'
);

export const setVoucherTemplateFilter = createAction<
  Partial<VouchersTemplateSearchFormValues>
>('voucherTemplate/setVoucherTemplateFilter');

export const getVoucherTemplates = createAsyncThunk<
  Array<VouchersTemplateBase>,
  Partial<VouchersTemplateSearchFormValues> | undefined
>(
  'voucherTemplate/getVouchersTemplate',
  async (values, { dispatch, getState }) => {
    if (values) {
      dispatch(setVoucherTemplateFilter(values));
    }

    const { filter } = getState().vouchersTemplate;

    return (
      await api.get(
        `/voucher-templates${qs.stringify(filter, { addQueryPrefix: true })}`
      )
    ).data.items;
  }
);

export const getPriorityVoucherTemplates = createAsyncThunk<
  Array<PriorityVouchersTemplate>,
  object
>('voucherTemplate/getPriorityVoucherTemplates', async (params) => {
  return (
    await api.get(
      `/voucher-templates${qs.stringify(params, { addQueryPrefix: true })}`
    )
  ).data.items;
});

export const getVoucherTemplate = createAsyncThunk<VoucherTemplate, string>(
  'voucherTemplate/getSingleVoucherTemplate',
  async (id, { dispatch }) =>
    dispatch(
      setVoucherTemplate((await api.get(`/voucher-templates/${id}`)).data)
    ).payload!
);

export const createVoucherTemplate = createAsyncThunk<
  VoucherTemplate,
  VoucherTemplatePayload
>(
  'voucherTemplate/createVoucherTemplate',
  async (payload) => (await api.post(`/voucher-templates`, payload)).data
);

export const updateVoucherTemplate = createAsyncThunk<
  VoucherTemplate,
  VoucherTemplatePayload
>(
  'voucherTemplate/updateVoucherTemplate',
  async ({ id, ...payload }) =>
    (await api.put(`/voucher-templates/${id}`, payload)).data
);

export const deleteVoucherTemplate = createAsyncThunk<object, string>(
  'voucherTemplate/deleteVoucherTemplate',
  async (id) => (await api.delete(`/voucher-templates/${id}`)).data
);

export const createVoucherInfo = createAsyncThunk<void, Partial<Infos>>(
  'voucherTemplate/deleteVoucherInfo',
  async (payload, { getState }) =>
    (
      await api.post(
        `/voucher-templates/${selectCurrentTemplateId(getState())}/info`,
        payload
      )
    ).data
);

export const updateVoucherInfo = createAsyncThunk<Infos, Partial<Infos>>(
  'voucherTemplate/deleteVoucherInfo',
  async (payload, { getState }) =>
    (
      await api.put(
        `/voucher-templates/${selectCurrentTemplateId(getState())}/info/${
          payload.id
        }`,
        payload
      )
    ).data
);

export const deleteVoucherInfo = createAsyncThunk<void, number>(
  'voucherTemplate/deleteVoucherInfo',
  async (id, { getState }) =>
    (
      await api.delete(
        `/voucher-templates/${selectCurrentTemplateId(getState())}/info/${id}`
      )
    ).data
);

export const createTermsAndConditions = createAsyncThunk<void, Partial<Infos>>(
  'voucherTemplate/createTermsAndConditions',
  async (payload, { getState }) =>
    (
      await api.post(
        `/voucher-templates/${selectCurrentTemplateId(
          getState()
        )}/terms-and-conditions`,
        payload
      )
    ).data
);

export const updateTermsAndConditions = createAsyncThunk<Infos, Partial<Infos>>(
  'voucherTemplate/updateTermsAndConditions',
  async (payload, { getState }) =>
    (
      await api.put(
        `/voucher-templates/${selectCurrentTemplateId(
          getState()
        )}/terms-and-conditions/${payload.id}`,
        payload
      )
    ).data
);

export const deleteTermsAndConditions = createAsyncThunk<void, number>(
  'voucherTemplate/deleteTermsAndConditions',
  async (id, { getState }) =>
    (
      await api.delete(
        `/voucher-templates/${selectCurrentTemplateId(
          getState()
        )}/terms-and-conditions/${id}`
      )
    ).data
);

export const createUnitPrice = createAsyncThunk<void, Partial<UnitPrice>>(
  'voucherTemplate/createUnitPrice',
  async (payload, { getState }) =>
    (
      await api.post(
        `/voucher-templates/${selectCurrentTemplateId(getState())}/unit-price`,
        payload
      )
    ).data
);

export const updateUnitPrice = createAsyncThunk<UnitPrice, Partial<UnitPrice>>(
  'voucherTemplate/updateUnitPrice',
  async (payload, { getState }) =>
    (
      await api.put(
        `/voucher-templates/${selectCurrentTemplateId(getState())}/unit-price/${
          payload.id
        }`,
        payload
      )
    ).data
);

export const deleteUnitPrice = createAsyncThunk<void, number>(
  'voucherTemplate/deleteUnitPrice',
  async (id, { getState }) =>
    (
      await api.delete(
        `/voucher-templates/${selectCurrentTemplateId(
          getState()
        )}/unit-price/${id}`
      )
    ).data
);

export const clearVoucherTemplates = createAction(
  'voucherTemplate/clearVoucherTemplates'
);
