import { FC, useCallback } from 'react';
import { Accordion, AccordionPanel } from '@fleet/shared';
import { Typography } from '@mui/material';
import { TransTitle, TransTitleKeys } from 'i18n/trans/title';
import { PassengerTypeTranslations } from 'routes/PassengerTypes/Accordion/PassengerTypeTranslations';
import { PassengerTypeValidityCriterias } from 'routes/PassengerTypes/Accordion/PassengerTypeValidityCriterias';
import { currentPassengerTypeSelector } from 'features/passengerType/passengerTypeSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { PassengerType } from 'dto/passengerType';
import { useSelector } from 'store/utils';

const panels: Array<{
  key: TransTitleKeys;
  Component: FC;
  field: keyof Pick<PassengerType, 'translations' | 'validityCriterias'>;
  isMandatory: boolean;
}> = [
  {
    key: 'validityCriteria',
    Component: PassengerTypeValidityCriterias,
    isMandatory: true,
    field: 'validityCriterias',
  },
  {
    key: 'translations',
    Component: PassengerTypeTranslations,
    isMandatory: false,
    field: 'translations',
  },
];

export const PassengerTypeAccordion: FC = () => {
  const currentPassengerType = useSelector(currentPassengerTypeSelector);

  const renderSubtitle = useCallback(
    (
      field: keyof Pick<PassengerType, 'translations' | 'validityCriterias'>,
      isMandatory: boolean
    ) => {
      if (!currentPassengerType) {
        return null;
      }

      return isMandatory && currentPassengerType[field]?.length === 0 ? (
        <TransSubtitle i18nKey="mandatory" />
      ) : (
        `(${currentPassengerType[field]?.length})`
      );
    },
    [currentPassengerType]
  );

  return (
    <Accordion controlled={false}>
      {panels.map(({ key, Component, field, isMandatory }) => (
        <AccordionPanel
          key={key}
          id={key}
          summary={
            <Typography variant="subtitle">
              <TransTitle i18nKey={key} />
              &nbsp;
              <Typography component="span" variant="body2">
                {renderSubtitle(field, isMandatory)}
              </Typography>
            </Typography>
          }
          disabled={!Component}
        >
          {currentPassengerType && (Component ? <Component /> : <span />)}
        </AccordionPanel>
      ))}
    </Accordion>
  );
};
