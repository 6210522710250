import { useSelector } from 'store/utils';
import { currentVoucherSelector } from 'features/voucher/vouchersSelector';
import { useMemo } from 'react';
import { Table, TableColumns } from '@fleet/shared';
import { Change } from 'dto/voucher';
import { TransTableHead } from 'i18n/trans/table';
import { useTable } from 'react-table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';

export const ChangeHistory = () => {
  const current = useSelector(currentVoucherSelector);

  //TODO field refundedTo is missing
  const columns = useMemo<TableColumns<Change>>(
    () => [
      {
        accessor: 'field',
        Header: <TransTableHead i18nKey="field" />,
      },
      {
        accessor: 'oldValue',
        Header: <TransTableHead i18nKey="change" />,
        Cell: ({ value, row }) =>
          `${formatDate(value, currentDateTimeFormat)} -> ${formatDate(
            row.original.newValue,
            currentDateTimeFormat
          )}`,
      },
      {
        accessor: 'changedBy',
        Header: <TransTableHead i18nKey="changedBy" />,
        Cell: ({ value }) => value.name,
      },
      {
        accessor: 'changedOn',
        Header: <TransTableHead i18nKey="changedOn" />,
        Cell: ({ value }) => formatDate(value.value, currentDateTimeFormat),
      },
    ],
    []
  );
  const table = useTable({
    data: current?.changes ?? [],
    columns,
  });

  return (
    <>
      <Table table={table} />
    </>
  );
};
