import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { TabPanel, Tabs } from '@fleet/shared';
import { PriceDetailsTabPrices } from 'routes/ZonePriceList/PriceDetailsTabPrices';
import { PriceDetailsTabZones } from 'routes/ZonePriceList/PriceDetailsTabZones';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';
import { selectCurrentPriceList } from 'features/zonePriceList/zonePriceListSelectors';
import { Typography } from '@mui/material';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
      padding: theme.spacing(3),
      paddingTop: 0,
      '& > .MuiPaper-root': {
        flex: 1,
        minHeight: 0,
      },
    },
    tab: {
      height: '100%',
    },
  }),
  {
    name: 'PriceDetailsTabs',
  }
);

interface PriceDetailsTabsProps {}

export const PriceDetailsTabs: FC<PriceDetailsTabsProps> = () => {
  const classes = useStyles();
  const { zones: assignedZones = [], prices = [] } =
    useSelector(selectCurrentPriceList) ?? {};

  return (
    <div className={classes.root}>
      <Tabs value="zones">
        <TabPanel
          className={classes.tab}
          value="zones"
          label={
            <>
              <TransSubtitle i18nKey="zones" />
              <Typography variant="body2" component="span">
                &nbsp;({assignedZones.length})
              </Typography>
            </>
          }
        >
          <PriceDetailsTabZones />
        </TabPanel>
        <TabPanel
          className={classes.tab}
          value="prices"
          label={
            <>
              <TransSubtitle i18nKey="prices" />
              <Typography variant="body2" component="span">
                &nbsp;({prices.length})
              </Typography>
            </>
          }
        >
          <PriceDetailsTabPrices />
        </TabPanel>
      </Tabs>
    </div>
  );
};
