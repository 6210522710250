import { selectCurrentPriceList } from 'features/zonePriceList/zonePriceListSelectors';
import { TransAlert } from 'i18n/trans/alert';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import { PriceForm } from 'routes/ZonePriceList/PriceForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  createPriceList,
  getPriceLists,
  updatePriceList,
} from 'features/zonePriceList/zonePriceListActions';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { PriceListFormValues } from 'dto/zonePriceList';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { ClassificationGroup } from 'dto/classification';
import { classificationsSelector } from 'features/classification/classificationSelectors';

interface PriceDetailsFormProps {
  isCreation: boolean;
  isEdit: boolean;
}

export const PriceDetailsForm: FC<PriceDetailsFormProps> = ({
  isCreation,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const alert = useAlert();
  const history = useHistory();
  const priceList = useSelector(selectCurrentPriceList);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const allZoneMaps = useSelector(classificationsSelector)[
    ClassificationGroup.ZONE_MAP
  ];

  const handleGoBack = useCallback(() => {
    history.replace('/zone-fares/pricelists');
  }, [history]);

  const onSubmit = useCallback(
    (values) => {
      const payload = {
        ...values,
        validity: values.validity && {
          ...values.validity,
          from: values.validity.from,
          to: values.validity.to,
        },
      };
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        if (isCreation) {
          const { id } = await dispatch(createPriceList(payload)).unwrap();
          alert.success(<TransAlert i18nKey="priceCreated" />);
          history.replace(`/zone-fares/pricelists/edit/${id}`);
        }
        if (isEdit) {
          await dispatch(updatePriceList({ id, ...payload })).unwrap();
          alert.success(<TransAlert i18nKey="priceUpdated" />);
        }

        dispatch(getPriceLists());
      });
    },
    [alert, dispatch, history, id, isCreation, isEdit]
  );

  const initialValues = useMemo(
    () => ({
      ownerId: currentBusinessEntityId,
      validity: {
        from: formatDate(new Date(), isoDateTimeFormat) as unknown as Date,
        to: '' as unknown as Date,
        timeZone: 'Europe/Stockholm',
      },
      ...(priceList && {
        name: priceList.name,
        zoneMapId: priceList.zoneMap?.id,
        currencyId: priceList.currency?.id,
        fareCategoryId: priceList.fareCategory?.id,
        validity: priceList.validity,
        ownerId: allZoneMaps.find(({ id }) => id === priceList.zoneMap?.id)!
          .owner.id,
      }),
    }),
    [allZoneMaps, currentBusinessEntityId, priceList]
  );

  const { form, handleSubmit, dirty } = useForm<Partial<PriceListFormValues>>({
    onSubmit,
    initialValues,
    subscription: { dirty: true, values: true },
  });
  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <Box sx={{ p: 2, pt: 1.5 }}>
      <Stack sx={{ mb: 2.5 }}>
        <Typography variant="subtitle">
          <TransSubtitle i18nKey="details" />
        </Typography>
      </Stack>
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <PriceForm>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    {...(!isEdit && { onClick: handleGoBack })}
                    {...(isEdit && { onClick: handleReset, disabled: !dirty })}
                  >
                    <TransButton i18nKey={isEdit ? 'resetChanges' : 'cancel'} />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button
                    variant="contained"
                    type="submit"
                    {...(!isEdit && { icon: 'plus' })}
                    disabled={!dirty}
                  >
                    <TransButton i18nKey={isEdit ? 'save' : 'create'} />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </PriceForm>
        </form>
      </FormProvider>
    </Box>
  );
};
