import { createSelector, selector } from 'store/utils';

export const selectCoTaxationGroups = selector(
  (state) => state.coTaxationGroup.list
);

export const coTaxationGroupsSelector = createSelector(selectCoTaxationGroups);

export const selectCurrentCoTaxationGroup = selector(
  (state) => state.coTaxationGroup.current
);

export const currentCoTaxationGroupSelector = createSelector(
  selectCurrentCoTaxationGroup
);
