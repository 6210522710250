import { createSelector, selector } from 'store/utils';
import { RootState } from 'store';

export const selectVouchersTemplateList = selector(
  (state) => state.vouchersTemplate.list
);
export const vouchersTemplateListSelector = createSelector(
  selectVouchersTemplateList
);

export const selectVouchersTemplateFilter = selector(
  (state) => state.vouchersTemplate.filter
);
export const vouchersTemplateFilterSelector = createSelector(
  selectVouchersTemplateFilter
);

export const selectVouchersPriorityTemplateList = selector(
  (state) => state.vouchersTemplate.priorityList
);

export const vouchersPriorityTemplateListSelector = createSelector(
  selectVouchersPriorityTemplateList
);

export const selectCurrentVoucherTemplate = selector(
  (state: RootState) => state.vouchersTemplate.current
);
export const currentVoucherTemplate = createSelector(
  selectCurrentVoucherTemplate,
  (template) => template
);

export const selectCurrentTemplateId = selector(
  (state) => state.vouchersTemplate.current!.id
);
