import { FC } from 'react';
import { Layout } from '@fleet/shared/components/Layout';
import { ZoneMapTable } from 'routes/ZoneMap/ZoneMapTable';

interface ZoneMapListProps {}

export const ZoneMapList: FC<ZoneMapListProps> = () => (
  <Layout>
    <ZoneMapTable />
  </Layout>
);
