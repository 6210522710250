import { RouteComponentProps } from 'react-router-dom';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  Button,
  CardHeader,
  FormProvider,
  Icon,
  Loadable,
  TabPanel,
  Tabs,
  useForm,
} from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import { ZoneLocalizations } from 'routes/ZoneMap/ZoneLocalizations';
import { useDispatch, useSelector } from 'store/utils';
import {
  clearCurrentZone,
  createZone,
  getZone,
  getZoneMapList,
  updateZone,
} from 'features/zoneMap/zoneMapActions';
import { zoneSelector } from 'features/zoneMap/zoneMapSelectors';
import { ZoneStops } from 'routes/ZoneMap/ZoneStops';
import { zoneLoading } from 'features/loading/loadingSelectors';
import { Zone } from 'dto/zoneMap';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface ZoneFormProps
  extends RouteComponentProps<{ id: string; zoneId?: string }> {}

export const ZoneForm: FC<ZoneFormProps> = ({
  match: {
    params: { id: zoneMapId, zoneId },
  },
  history,
}) => {
  const current = useSelector(zoneSelector);
  const loading = useSelector(zoneLoading);
  const alert = useAlert();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (payload: Zone) => {
      await dispatch(
        (payload.id ? updateZone : createZone)({ zoneMapId, payload })
      ).unwrap();
      !payload.id && history.replace(`/zone-fares/maps/edit/${zoneMapId}`);

      alert.success(
        <TransAlert
          i18nKey="zoneCreated"
          {...(payload.id && { i18nKey: 'zoneUpdated' })}
        />
      );

      dispatch(getZoneMapList());
    },
    [dispatch, zoneMapId, history, alert]
  );

  const initialValues = useMemo(() => current ?? {}, [current]);

  const { form, handleSubmit } = useForm<Zone>({
    initialValues,
    onSubmit,
  });

  const redirectBack = useCallback(
    () => history.push(`/zone-fares/maps/edit/${zoneMapId}`),
    [history, zoneMapId]
  );

  useEffect(() => {
    if (zoneId) {
      dispatch(getZone({ zoneId, zoneMapId }));
    } else {
      dispatch(clearCurrentZone());
    }
  }, [dispatch, zoneMapId, zoneId]);

  return (
    <Loadable loading={loading}>
      <Stack sx={{ p: 3 }} spacing={2}>
        <Card>
          <CardHeader
            title={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h2">
                  <TransSubtitle
                    i18nKey={zoneId ? 'zoneTitle' : 'newZone'}
                    values={{ name: current?.name }}
                  />
                </Typography>
                <Button
                  variant="text"
                  startIcon={<Icon name="arrow-left-circle" />}
                  onClick={redirectBack}
                  sx={{ p: 0, mr: 2 }}
                  color="yellow"
                >
                  <TransButton i18nKey="back" />
                </Button>
              </Stack>
            }
          />
          <Divider />
          <CardContent
            sx={{ p: 2, pt: 1.5 }}
            component="form"
            onSubmit={handleSubmit}
          >
            <FormProvider form={form}>
              <Grid container columns={5} spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={5} sx={{ mb: 0.5 }}>
                  <Typography variant="subtitle">
                    <TransSubtitle i18nKey="details" />
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="name"
                    label={<TransField i18nKey="zoneName" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="code"
                    label={<TransField i18nKey="code" />}
                    required
                  />
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end">
                {zoneId ? (
                  <>
                    <Button variant="text" onClick={() => form.reset()}>
                      <TransButton i18nKey="resetChanges" />
                    </Button>
                    <Button type="submit" startIcon={<Icon name="check" />}>
                      <TransButton i18nKey="save" />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="text"
                      onClick={() =>
                        history.push(`/zone-fares/maps/edit/${zoneMapId}`)
                      }
                    >
                      <TransButton i18nKey="cancel" />
                    </Button>
                    <Button type="submit" startIcon={<Icon name="plus" />}>
                      <TransButton i18nKey="create" />
                    </Button>
                  </>
                )}
              </Stack>
            </FormProvider>
          </CardContent>
        </Card>

        {zoneId && current && (
          <Stack sx={{ '& .MuiTabPanel-root': { p: 3, pt: 2 } }}>
            <Tabs>
              <TabPanel
                label={
                  <TransSubtitle
                    i18nKey="stopsQty"
                    values={{
                      num: `(${current?.stops.length})`,
                    }}
                  />
                }
                value="stops"
                defaultChecked
              >
                <ZoneStops />
              </TabPanel>
              <TabPanel
                label={
                  <TransSubtitle
                    i18nKey="langDependantParamsQty"
                    values={{
                      num: `(${current?.localizations.length})`,
                    }}
                  />
                }
                value="langDependantParams"
              >
                <ZoneLocalizations zoneMapId={zoneMapId} />
              </TabPanel>
            </Tabs>
          </Stack>
        )}
      </Stack>
    </Loadable>
  );
};
