import {
  PromotionalDiscount,
  promotionalDiscountTabs,
} from 'dto/promotionalDiscount';

export const isDiscountAmountFieldDisabled = ({
  discount,
  tabKey,
}: {
  discount?: PromotionalDiscount;
  tabKey?: typeof promotionalDiscountTabs[number];
}) => {
  if (!discount) {
    return false;
  }

  const {
    transactionTime,
    departureDays,
    bookingQuantity,
    salesChannel,
    lineType,
    passengerGroup,
    cardType,
    feederDefeeder,
    accompanyingPassengerTypeRestrictions,
    discountPercentageAmount,
  } = discount;
  const mergedDiscountConfigurations = [
    ...transactionTime,
    ...departureDays,
    ...bookingQuantity,
    ...salesChannel,
    ...lineType,
    ...passengerGroup,
    ...cardType,
    ...feederDefeeder,
    ...accompanyingPassengerTypeRestrictions,
  ];

  if (discountPercentageAmount) {
    return true;
  }

  const currentTabConfigIds = tabKey
    ? discount[tabKey].map(({ id }) => id)
    : [];
  const configWithDefinedDiscountAmountOutsideOfCurrentTab =
    mergedDiscountConfigurations.find(
      ({ id, discountPercentageAmount }) =>
        Boolean(discountPercentageAmount) && !currentTabConfigIds.includes(id)
    );

  return Boolean(configWithDefinedDiscountAmountOutsideOfCurrentTab);
};
