import {
  deletePassengerTypeDiscountInfosBulk,
  updateOrCreatePassengerTypeDiscountInfo,
} from 'features/passengerTypeDiscount/passengerTypeDiscountActions';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import {
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { Button, Stack } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { PassengerTypeDiscountInfo } from 'dto/passengerTypeDiscount';
import { passengerTypeDiscountSelector } from 'features/passengerTypeDiscount/passengerTypeDiscountsSelector';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';

interface PassengerTypeDiscountsManageFormInfoProps {}

export const Info: FC<PassengerTypeDiscountsManageFormInfoProps> = () => {
  const { id, information = [] } = useSelector(passengerTypeDiscountSelector)!;
  const cultures = useClassificationOptions(ClassificationGroup.CULTURE);
  const dispatch = useDispatch();

  const columns: TableColumns<PassengerTypeDiscountInfo> = useMemo(
    () => [
      {
        accessor: 'discountName',
        Header: <TransTableHead i18nKey="discountName" />,
      },
      {
        id: 'language.id',
        accessor: ({ language }) => language?.id,
        Header: <TransTableHead i18nKey="language" />,
        type: 'select',
        editableProps: { options: cultures },
        width: 100,
      },
      {
        accessor: 'information',
        Header: <TransTableHead i18nKey="ticketAndSalesChannelsInformation" />,
      },
    ],
    [cultures]
  );
  const { form } = useForm<{ rows: Array<PassengerTypeDiscountInfo> }>({
    initialValues: {
      rows: information,
    },
  });

  const table = useFormTable<PassengerTypeDiscountInfo>(
    {
      data: information,
      columns,
      form,
      onRowUpdate: (payload) =>
        dispatch(
          updateOrCreatePassengerTypeDiscountInfo({ id: id!, payload })
        ).unwrap(),
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<PassengerTypeDiscountInfo>) => {
      dispatch(
        deletePassengerTypeDiscountInfosBulk({
          ids: rows.map(({ id }) => id).filter(Boolean),
          discountId: id!,
        })
      );
    },
    [dispatch, id]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  return (
    <FormProvider form={form}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Button
          startIcon={<Icon name="delete" />}
          onClick={removeSelectedRows}
          disabled={!Object.keys(table.state.selectedRowIds).length}
          color="error"
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>

        <Button
          variant="text"
          onClick={addRow}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        getTableProps={{ sx: { tableLayout: 'fixed' } }}
        getHeaderProps={{ style: { backgroundColor: 'white' } }}
        table={table}
      />
    </FormProvider>
  );
};
