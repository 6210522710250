import { Classifier } from '@fleet/shared/dto/classifier';
import { BusinessEntityType } from '@fleet/shared';

export const ALLOWED_BUSINESS_ENTITY_ROLES = [
  BusinessEntityType.CARRIER,
  BusinessEntityType.CO_TAXATION_GROUP,
  BusinessEntityType.RETAILER,
];

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  BONUS_SCHEME_DISCOUNT_TYPE = 'BONUS_SCHEME_DISCOUNT_TYPE',
  BUS_LINE_TYPE = 'BUS_LINE_TYPE',
  COMFORT_LEVEL = 'COMFORT_LEVEL',
  COMMENT_TYPE = 'COMMENT_TYPE',
  CONTACT_ROLE = 'CONTACT_ROLE',
  CULTURE = 'CULTURE',
  CURRENCY = 'CURRENCY',
  DISCOUNT_CALCULATION_BASIS = 'DISCOUNT_CALCULATION_BASIS',
  DISTANCE_FARE_IMPLEMENTATION_METHOD = 'DISTANCE_FARE_IMPLEMENTATION_METHOD',
  FARE_CATEGORY = 'FARE_CATEGORY',
  JOURNEY_DIRECTION = 'JOURNEY_DIRECTION',
  JOURNEY_TYPE = 'JOURNEY_TYPE',
  JOURNEY_WAY = 'JOURNEY_WAY',
  JOURNEY_WAY_TYPE = 'JOURNEY_WAY_TYPE',
  PASSENGER_TYPE_BOOKING_RESTRICTION = 'PASSENGER_TYPE_BOOKING_RESTRICTION',
  PASSENGER_TYPE_CATEGORY = 'PASSENGER_TYPE_CATEGORY',
  PERIOD_TYPE = 'PERIOD_TYPE',
  SALES_POINT_TYPE = 'SALES_POINT_TYPE',
  SEAT_PROPERTY = 'SEAT_PROPERTY',
  TICKET_SALES_LIMIT_TYPE = 'TICKET_SALES_LIMIT_TYPE',
  VALIDITY_PERIOD_INTERPRETATION = 'VALIDITY_PERIOD_INTERPRETATION',
  VOUCHER_TYPE = 'VOUCHER_TYPE',
  VOUCHER_USAGE_TYPE = 'VOUCHER_USAGE_TYPE',

  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  COUNTY = 'COUNTY',
  INVENTORY_CLASS = 'INVENTORY_CLASS',
  PASSENGER_TYPE = 'PASSENGER_TYPE',
  PRINTOUT_THEMES = 'PRINTOUT_THEMES',
  ZONE_MAP = 'ZONE_MAP',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.BONUS_SCHEME_DISCOUNT_TYPE,
  ClassificationGroup.BUS_LINE_TYPE,
  ClassificationGroup.COMFORT_LEVEL,
  ClassificationGroup.COMMENT_TYPE,
  ClassificationGroup.CONTACT_ROLE,
  ClassificationGroup.CULTURE,
  ClassificationGroup.CURRENCY,
  ClassificationGroup.DISCOUNT_CALCULATION_BASIS,
  ClassificationGroup.DISTANCE_FARE_IMPLEMENTATION_METHOD,
  ClassificationGroup.FARE_CATEGORY,
  ClassificationGroup.JOURNEY_DIRECTION,
  ClassificationGroup.JOURNEY_TYPE,
  ClassificationGroup.JOURNEY_WAY,
  ClassificationGroup.JOURNEY_WAY_TYPE,
  ClassificationGroup.PASSENGER_TYPE_BOOKING_RESTRICTION,
  ClassificationGroup.PASSENGER_TYPE_CATEGORY,
  ClassificationGroup.PERIOD_TYPE,
  ClassificationGroup.TICKET_SALES_LIMIT_TYPE,
  ClassificationGroup.SALES_POINT_TYPE,
  ClassificationGroup.SEAT_PROPERTY,
  ClassificationGroup.VALIDITY_PERIOD_INTERPRETATION,
  ClassificationGroup.VOUCHER_TYPE,
  ClassificationGroup.VOUCHER_USAGE_TYPE,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface PrintoutTheme extends Classifier {}

export interface Country extends Classifier {
  culture?: string;
  currency?: string;
  vatRate?: string;
  code?: string;
  areaPhoneCode?: string;
  unixTimeZone?: string;
}

export interface County extends Classifier<number> {
  timezone: string;
  country: Classifier<number>;
}

export interface City extends Classifier<number> {
  code: string;
  timezone: string;
  country: Classifier<number>;
  county: Classifier<number>;
}

export type ZoneMapZone = Classifier<number> & { code: string };

export interface ZoneMap extends Classifier<number> {
  code: string;
  owner: Classifier<number>;
  zones: Array<ZoneMapZone>;
}

export enum InventoryType {
  COMPARTMENT = 'INVENTORY_TYPE.COMPARTMENT',
  CARGO = 'INVENTORY_TYPE.CARGO',
  SEAT = 'INVENTORY_TYPE.SEAT',
  BED = 'INVENTORY_TYPE.BED',
}

export interface InventoryClass extends Classifier {
  comfortLevel: Classifier;
  inventoryType: Classifier<InventoryType>;
}

export interface Localization {
  id: string;
  languageId: string;
  name: string;
}
