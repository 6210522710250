import { createSelector, selector } from 'store/utils';
import { RootState } from 'store';

export const selectFareModelFixedList = selector(
  (state: RootState) => state.fareModel.fixedFaresList
);
export const fareModelFixedListSelector = createSelector(
  selectFareModelFixedList
);

export const selectFareModelFixedFilter = selector(
  (state: RootState) => state.fareModel.fixedFaresFilter
);
export const fareModelFixedFilterSelector = createSelector(
  selectFareModelFixedFilter
);
