import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSearch = createTrans({
  noFaresFound: (
    <Trans
      i18nKey="search.noFaresFound"
      defaults="No fares found. Narrow the search criteria above."
    />
  ),
  noStopsFound: (
    <Trans
      i18nKey="search.noStopsFound"
      defaults={`No origin and destination stops defined.\r\nAdd origin and destination stops in details section.`}
    />
  ),
});
