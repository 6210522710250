import { api } from '@fleet/shared';
import { FareModel } from 'dto/fareModel';
import qs from 'qs';

export const fetchFareModels = async (ownerId?: number) =>
  (
    await api.get<{ items: Array<FareModel> }>(
      `/fare-models${qs.stringify({ ownerId }, { addQueryPrefix: true })}`
    )
  ).data.items;
