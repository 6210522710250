import type { FC } from 'react';
import {
  FormProvider,
  Table,
  TableColumns,
  useForm,
  useFormTableControls,
} from '@fleet/shared';
import { useCallback, useMemo } from 'react';
import { TransTableHead } from 'i18n/trans/table';
import {
  useFormTable,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { useRowSelect } from 'react-table';
import { useDispatch } from 'store/utils';
import { Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { UnitPrice } from 'dto/vouchersTemplate';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  createUnitPrice,
  deleteUnitPrice,
  getVoucherTemplate,
  updateUnitPrice,
} from 'features/vouchersTemplate/vouchersTemplateActions';
import { useParams } from 'react-router-dom';
import { formatDate } from '@fleet/shared/utils/date';
import { validate } from '@fleet/shared/form/validate';

interface UnitPriceTableProps {
  data: Array<UnitPrice>;
}

export const UnitPriceTable: FC<UnitPriceTableProps> = ({ data }) => {
  const dispatch = useDispatch();
  const currencies = useClassificationOptions(ClassificationGroup.CURRENCY);
  const { id } = useParams<{ id?: string }>();
  const { form } = useForm<{ rows: Array<UnitPrice> }>({
    initialValues: {
      rows: data,
    },
  });

  const columns: TableColumns<UnitPrice> = useMemo(
    () => [
      {
        id: 'id',
      },
      {
        accessor: 'unitPrice',
        Header: <TransTableHead i18nKey="unitPrice" />,
        editableProps: {
          validate: validate.required(),
        },
      },
      {
        accessor: 'value',
        Header: <TransTableHead i18nKey="value" />,
        editableProps: {
          validate: validate.required(),
        },
      },
      {
        id: 'currency.id',
        accessor: ({ currency }) => currency?.id,
        type: 'select',
        editableProps: {
          options: currencies,
          required: false,
        },
        Header: <TransTableHead i18nKey="currency" />,
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) =>
          validity?.from ? formatDate(validity.from) : '',
        type: 'date',
        Header: <TransTableHead i18nKey="salesDatesFrom" />,
        editableProps: {
          validate: validate.required(),
        },
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) =>
          validity?.to ? formatDate(validity.to) : '',
        type: 'date',
        Header: <TransTableHead i18nKey="salesDatesTo" />,
        editableProps: {
          validate: validate.required(),
        },
      },
    ],
    [currencies]
  );
  const formTable = useFormTable<UnitPrice>(
    {
      data,
      columns,
      form,
      initialState: { pageSize: 20, hiddenColumns: ['id'] },
      onRowUpdate: useCallback(
        async ({ currency, ...values }) => {
          const mutatedValue = {
            ...values,
            currencyId: currency.id,
          };
          values.id
            ? await dispatch(updateUnitPrice(mutatedValue))
            : await dispatch(createUnitPrice(mutatedValue));
          await dispatch(getVoucherTemplate(id!));
        },
        [dispatch, id]
      ),
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: Array<UnitPrice>) => {
      await Promise.all(
        payload.map((row: UnitPrice) => dispatch(deleteUnitPrice(row.id)))
      );
      await dispatch(getVoucherTemplate(id!));
    },
    [dispatch, id]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table: formTable,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider form={form}>
      <Stack direction="row" alignItems="center" justifyContent="end">
        <div>
          <Button
            startIcon={<Icon name="trash" />}
            onClick={removeSelectedRows}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button startIcon={<Icon name="plus24" />} onClick={addRow}>
            <TransButton i18nKey="addNew" />
          </Button>
        </div>
      </Stack>
      <Table
        table={formTable}
        getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
      />
    </FormProvider>
  );
};
