import { type FC } from 'react';
import { Layout } from '@fleet/shared';
import { VouchersTable } from 'routes/Vouchers/VouchersTable';

interface VouchersProps {}

export const Vouchers: FC<VouchersProps> = () => (
  <Layout>
    <VouchersTable />
  </Layout>
);
