import { validate } from '@fleet/shared/form/validate';
import type { FC } from 'react';
import { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import {
  api,
  Button,
  CheckboxField,
  ConfirmDeleteModal,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  TableProps,
  useForm,
  useFormTable,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { useModal, useRowEditActions } from '@fleet/shared/hooks';
import { Stack, Typography } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import type { FormTableRowUpdate } from '@fleet/shared/hooks/useFormTable';
import type { FareModelFare } from 'dto/fareModel';
import {
  addUpdateOriginDestinationFare,
  getFareModelOriginDestinationFares,
} from 'features/fareModel/fareModelActions';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTableHead } from 'i18n/trans/table';
import { FareModelStopsContext } from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { TransSearch } from 'i18n/trans/search';
import { useDispatch } from 'store/utils';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@fleet/shared/utils/date';
import { TransModal } from 'i18n/trans/modal';
import { useFilters, useRowSelect } from 'react-table';
import { useValidAccessor } from 'hooks/useValidAccessor';

interface FareModelStopsFaresProps extends Partial<TableProps<FareModelFare>> {
  originStopId: string;
  destinationStopId: string;
  fareModelId: string;
  fares: Array<FareModelFare>;
}

interface FormValues {
  rows: Array<FareModelFare>;
  isValid?: boolean;
}

export const FareModelStopsFares: FC<FareModelStopsFaresProps> = ({
  fares: data,
  fareModelId,
  originStopId,
  destinationStopId,
  ...props
}) => {
  const { t } = useTranslation();
  const { form } = useForm<FormValues>({
    initialValues: {
      rows: data,
    },
  });

  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );
  const categoryOptions = useClassificationOptions(
    ClassificationGroup.FARE_CATEGORY
  );
  const { filters } = useContext(FareModelStopsContext);
  const { open: isOpen, onOpen, onClose } = useModal();
  const isValidAccessor = useValidAccessor();

  const columns = useMemo<TableColumns<FareModelFare>>(
    () => [
      {
        accessor: 'fare',
        Header: <TransField i18nKey="fare" />,
        editableProps: {
          required: false,
          validate: validate.required(),
          placeholder: t('hint.required', 'Required'),
        },
        width: 'auto',
      },
      {
        id: 'currency.id',
        type: 'select',
        accessor: ({ currency }) => currency?.id,
        Header: <TransField i18nKey="currency" />,
        editableProps: {
          multiple: false,
          options: currencyOptions,
          required: false,
          validate: validate.required(),
          placeholder: t('hint.required', 'Required'),
        },
        width: 'auto',
      },
      {
        id: 'fareCategory.id',
        type: 'select',
        accessor: ({ fareCategory }) => fareCategory?.id,
        Header: <TransField i18nKey="category" />,
        editableProps: {
          multiple: false,
          options: categoryOptions,
          required: false,
          validate: validate.required(),
          placeholder: t('hint.required', 'Required'),
        },
        width: 'auto',
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) =>
          validity?.from ? formatDate(validity.from) : '',
        type: 'date',
        Header: <TransTableHead i18nKey="validFrom" />,
        editableProps: {
          required: false,
        },
        width: 'auto',
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) =>
          validity?.to ? formatDate(validity.to) : '',
        type: 'date',
        Header: <TransTableHead i18nKey="validTo" />,
        editableProps: {
          required: false,
          dateType: 'end',
        },
        width: 'auto',
      },
      {
        id: 'isValid',
        accessor: isValidAccessor,
      },
    ],
    [categoryOptions, currencyOptions, t, isValidAccessor]
  );

  const dispatch = useDispatch();
  const handleRowUpdate = useCallback<FormTableRowUpdate<FareModelFare>>(
    async ({ fareId, currency, fareCategory, fare, validity }) => {
      const data = await dispatch(
        addUpdateOriginDestinationFare({
          fareModelId,
          fareId,
          originId: originStopId,
          destinationId: destinationStopId,
          currencyId: currency.id,
          fareCategoryId: fareCategory.id,
          validity,
          fare,
        })
      ).unwrap();
      await dispatch(getFareModelOriginDestinationFares(filters));
      if (!fareId) return data;
    },
    [dispatch, fareModelId, originStopId, destinationStopId, filters]
  );

  const { getState } = form;
  const onRowsRemove = useCallback(
    async (rows: Array<FareModelFare>) => {
      const { isValid } = getState().values;
      await Promise.all(
        rows
          .filter((row) => (isValid ? isValidAccessor(row) : true))
          .map((row) =>
            api.delete(
              `/fare-models/${fareModelId}/origin-destination-fares/${row.fareId}`
            )
          )
      );

      onClose();
      await dispatch(getFareModelOriginDestinationFares(filters));
    },
    [dispatch, fareModelId, filters, getState, isValidAccessor, onClose]
  );

  const table = useFormTable<FareModelFare, Pick<FormValues, 'isValid'>>(
    {
      form,
      data,
      columns,
      initialState: {
        selectedRowIds: {},
        hiddenColumns: ['isValid'],
      },
      onRowUpdate: handleRowUpdate,
      onRowsDelete: onRowsRemove,
    },
    useFilters,
    useRowEditActions,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );
  const { addRow: handleAddRow, removeSelectedRows } = table;

  const handleValidFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isValid', e.target.checked || undefined),
    [table]
  );

  return (
    <FormProvider form={form}>
      <Stack direction="row" alignItems="center">
        <CheckboxField
          name="isValid"
          size="small"
          label={
            <Typography variant="body2" color="text.primary" component="span">
              <TransField i18nKey="validFares" />
            </Typography>
          }
          onChange={handleValidFilterToggle}
          inline
        />
        <div style={{ flex: 1 }}></div>
        <Stack direction="row">
          <Button
            variant="text"
            onClick={onOpen}
            startIcon={<Icon name="delete" />}
            disabled={!Object.keys(table.selectedFlatRows).length}
            color="error"
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <ConfirmDeleteModal
            handleDelete={removeSelectedRows}
            title={<TransModal i18nKey="fareModelFareDeletionTitle" />}
            description={
              <TransModal i18nKey="fareModelFareDeletionDescription" />
            }
            isOpen={isOpen}
            onClose={onClose}
          />
          <Button
            variant="text"
            size="small"
            startIcon={<Icon name="plus" />}
            type="button"
            onClick={handleAddRow}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
      </Stack>
      <Table
        table={table}
        {...props}
        getTableProps={() => ({
          style: { tableLayout: 'fixed' },
        })}
      />
      {Boolean(!table.rows.length) && (
        <Typography
          variant="body1"
          lineHeight="24px"
          sx={{ pt: 2, color: 'text.secondary' }}
        >
          <TransSearch i18nKey="noFaresFound" />
        </Typography>
      )}
    </FormProvider>
  );
};
