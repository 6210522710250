import { createReducer } from '@reduxjs/toolkit';
import {
  getPassengerTypes,
  setPassengerType,
  setPassengerTypeFilter,
} from 'features/passengerType/passengerTypeActions';
import { PassengerType, PassengerTypeFilter } from 'dto/passengerType';
import { Pagination } from '@fleet/shared/dto/pagination';

export interface PassengerTypeState {
  current?: PassengerType;
  list?: Pagination<PassengerType>;
  filter: Partial<PassengerTypeFilter>;
}

const initialState: PassengerTypeState = {
  filter: {},
};

export const passengerTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPassengerType, (state, action) => {
      state.current = action.payload;
    })
    .addCase(getPassengerTypes.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(setPassengerTypeFilter, (state, action) => {
      state.filter = action.payload;
    });
});
