import type { FC } from 'react';
import { useCallback, useState } from 'react';
import {
  fareModelDestinationStopsSelector,
  fareModelOriginDestinationFaresSelector,
  fareModelOriginStopsSelector,
  fareModelSelector,
} from 'features/fareModel/fareModelSelectors';
import { TransField } from 'i18n/trans/field';
import {
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  SwitchProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FareModelStopsContextProvider } from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { FareModelStopsFilterForm } from 'routes/FareModels/FareModelStops/FareModelStopsFilterForm';
import { useSelector } from 'store/utils';
import { SearchResult } from '@fleet/shared';
import { TransSearch } from 'i18n/trans/search';
import { FareModelStopsResult } from 'routes/FareModels/FareModelStops/FareModelStopsResult';
import classNames from 'classnames';
import { FareModelStopsFaresContextProvider } from 'routes/FareModels/FareModelStops/FareModelStopsFaresContext';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      padding: `0 ${theme.spacing(3)} ${theme.spacing(2)}`,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    paper: {
      padding: theme.spacing(2),
    },
  }),
  {
    name: 'FareModelStopsContent',
  }
);

interface FareModelStopsContentProps {}

export const FareModelStopsContent: FC<FareModelStopsContentProps> = () => {
  const { id } = useSelector(fareModelSelector) ?? {};
  const originStops = useSelector(fareModelOriginStopsSelector);
  const destinationStops = useSelector(fareModelDestinationStopsSelector);
  const originDestinationFares = useSelector(
    fareModelOriginDestinationFaresSelector
  );

  const [view, setView] = useState<'matrix' | 'list'>('list');
  const handleChangeView = useCallback<Required<SwitchProps>['onChange']>(
    (e, checked) => {
      setView(checked ? 'matrix' : 'list');
    },
    []
  );

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FareModelStopsContextProvider
        view={view}
        id={id}
        originStops={originStops}
        destinationStops={destinationStops}
      >
        <Paper className={classes.content}>
          <FareModelStopsFilterForm />
          <Divider sx={{ mb: 2 }} />
          <Paper className={classNames(classes.content, classes.paper)}>
            <Stack
              sx={{ mb: 2 }}
              direction="row"
              justifyContent="space-between"
            >
              <FormControlLabel
                control={
                  <Switch
                    sx={{ mr: 1 }}
                    checked={view === 'matrix'}
                    onChange={handleChangeView}
                  />
                }
                label={<TransField i18nKey="matrixView" />}
              />
            </Stack>
            <SearchResult
              results={originStops.length}
              message={<TransSearch i18nKey="noStopsFound" />}
            >
              <FareModelStopsFaresContextProvider
                originDestinationFares={originDestinationFares}
              >
                <FareModelStopsResult view={view} />
              </FareModelStopsFaresContextProvider>
            </SearchResult>
          </Paper>
        </Paper>
      </FareModelStopsContextProvider>
    </div>
  );
};
