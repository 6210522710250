import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTab = createTrans({
  activityLog: <Trans i18nKey="tab.activityLog" defaults="Activity log" />,
  changeHistory: (
    <Trans i18nKey="tab.changeHistory" defaults="Change history" />
  ),
  'distanceFares.commercialDistances': (
    <Trans
      i18nKey="distanceFares.tab.commercialDistances"
      defaults="Commercial distances"
    />
  ),
  'distanceFares.distanceRanges': (
    <Trans
      i18nKey="distanceFares.tab.distanceRanges"
      defaults="Distance ranges"
    />
  ),
  'promotionalDiscount.accompanyingPassengerTypeRestrictions': (
    <Trans
      i18nKey="promotionalDiscount.tab.accompanyingPassengerTypeRestrictions"
      defaults="Accompanying passenger"
    />
  ),
  'promotionalDiscount.transactionTime': (
    <Trans
      i18nKey="promotionalDiscount.tab.transactionTime"
      defaults="Booking time"
    />
  ),
  'promotionalDiscount.brand': (
    <Trans i18nKey="promotionalDiscount.tab.brand" defaults="Brand" />
  ),
  'promotionalDiscount.cardType': (
    <Trans i18nKey="promotionalDiscount.tab.cardType" defaults="Card type" />
  ),
  'promotionalDiscount.departureDays': (
    <Trans
      i18nKey="promotionalDiscount.tab.departureDays"
      defaults="Days prior to departure"
    />
  ),
  'promotionalDiscount.feederDefeeder': (
    <Trans
      i18nKey="promotionalDiscount.tab.feederDefeeder"
      defaults="Feeder/defeeder"
    />
  ),
  'promotionalDiscount.information': (
    <Trans i18nKey="promotionalDiscount.tab.information" defaults="Info" />
  ),
  'promotionalDiscount.lineType': (
    <Trans i18nKey="promotionalDiscount.tab.lineType" defaults="Line type" />
  ),
  'promotionalDiscount.bookingQuantity': (
    <Trans
      i18nKey="promotionalDiscount.tab.bookingQuantity"
      defaults="Passenger count"
    />
  ),
  'promotionalDiscount.passengerGroup': (
    <Trans
      i18nKey="promotionalDiscount.tab.passengerGroup"
      defaults="Passenger group"
    />
  ),
  'promotionalDiscount.salesChannel': (
    <Trans
      i18nKey="promotionalDiscount.tab.salesChannel"
      defaults="Sales channel"
    />
  ),
});
