import { Typography } from '@mui/material';
import type { FC } from 'react';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon } from '@fleet/shared';
import classNames from 'classnames';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { makeStyles } from '@mui/styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  VariableSizeList as List,
  VariableSizeListProps as ListProps,
  ListChildComponentProps,
  areEqual,
} from 'react-window';
import { FareModelStopsContext } from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { FareModelStopsFares } from 'routes/FareModels/FareModelStops/FareModelStopsFares';
import { useSelector } from 'store/utils';
import { TransTableHead } from 'i18n/trans/table';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import _sum from 'lodash/sum';
import { FareModelStopsFaresContext } from 'routes/FareModels/FareModelStops/FareModelStopsFaresContext';

interface FareModelStopsTableProps {}

const headSize = 48;
const itemSize = 32;
const rowSize = 40;
const useStyles = makeStyles(
  (theme) => ({
    header: {
      position: 'sticky',
      top: 0,
      zIndex: 3,
      background: theme.palette.background.default,
      height: headSize,
      padding: '0 16px 0 48px',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      position: 'relative',
    },
    cell: {
      paddingRight: theme.spacing(2),
      boxSizing: 'content-box',
      minWidth: 120,
      '&:first-child, &:first-child + $cell': {
        flex: 1,
      },
    },
    accordion: {
      minWidth: '100%',
      width: 'auto !important' as 'auto',
      boxShadow: 'none',
      '&.Mui-expanded': {
        margin: 0,
        '&:before': {
          opacity: 1,
        },
        '& $accordionSummary': {
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    region: {
      background: theme.palette.background.default,
      padding: theme.spacing(1),
    },
    accordionSummary: {
      minHeight: itemSize,
      flexDirection: 'row-reverse',
      '&.Mui-expanded': {
        minHeight: itemSize,
      },
    },
    accordionSummaryContent: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    accordionSummaryExpandIconWrapper: {
      marginRight: theme.spacing(2),
    },
    accordionDetails: {
      padding: 0,
      '& $row': {
        height: rowSize,
      },
    },
    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    rowSelected: {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  }),
  {
    name: 'FareModelStopsTable',
  }
);

const FareModelStopsTableInnerElementType = forwardRef<HTMLDivElement>(
  ({ children, ...props }, ref) => {
    const classes = useStyles();
    return (
      <>
        <div className={classes.header}>
          <div className={classes.cell}>
            <TransTableHead i18nKey="originStop" />
          </div>
          <div className={classes.cell}>
            <TransTableHead i18nKey="destinationStop" />
          </div>
          <div className={classes.cell}>
            <TransTableHead i18nKey="fares" />
          </div>
          <div className={classes.cell}>
            <TransTableHead i18nKey="categories" />
          </div>
        </div>
        <div ref={ref} className={classes.content} {...props}>
          {children}
        </div>
      </>
    );
  }
);

interface FareModelStopsTableRowProps {
  fareModelId?: string;
  expandedStops: Map<string, false | Array<string>>;
  toggleOriginStop: (obj: {
    originStopId: string;
    originStopIndex: number;
    expanded: boolean;
  }) => void;
  toggleDestinationStop: (obj: {
    originStopId: string;
    destinationStopId: string;
    originStopIndex: number;
    expanded: boolean;
  }) => void;
}

const filterDestinationStopIds = (
  destinationStopIds: Map<string, number>,
  originStopId: number
) =>
  new Map(
    [...destinationStopIds].filter(
      ({ 1: destinationStopId }) => destinationStopId !== originStopId
    )
  );

const FareModelStopsTableRow: FC<
  ListChildComponentProps<FareModelStopsTableRowProps>
> = memo(({ index, style, data }) => {
  const { originStopUuids, destinationStopIds, stopsMap } = useContext(
    FareModelStopsContext
  );
  const { faresMap, qtyMap } = useContext(FareModelStopsFaresContext);
  const originStopId = originStopUuids[index];
  const originStop = stopsMap.get(originStopId)!;
  const destinationStopUuids = useMemo(
    () => [
      ...filterDestinationStopIds(destinationStopIds, originStop.id).keys(),
    ],
    [destinationStopIds, originStop.id]
  );

  const { expandedStops, toggleOriginStop, toggleDestinationStop } = data;
  const handleToggleOriginStop = useCallback<
    Required<AccordionProps>['onChange']
  >(
    (event, expanded) => {
      toggleOriginStop({ originStopId, originStopIndex: index, expanded });
    },
    [index, toggleOriginStop, originStopId]
  );
  const handleToggleDestinationStop = useCallback<
    Required<AccordionProps>['onChange']
  >(
    (event, expanded) => {
      const { id: destinationStopId } = event.currentTarget;
      toggleDestinationStop({
        originStopId,
        destinationStopId,
        originStopIndex: index,
        expanded,
      });
    },
    [index, toggleDestinationStop, originStopId]
  );

  const classes = useStyles();
  const expandedDestinationStops = expandedStops.get(originStopId);
  const expanded = Boolean(expandedDestinationStops);
  const qty = qtyMap.get(originStopId);
  const prices = [...(qty?.fares ?? [])];
  const priceMin = Math.min(...prices);
  const priceMax = Math.max(...prices);

  return (
    <Accordion
      style={style}
      classes={{ root: classes.accordion, region: classes.region }}
      expanded={expanded}
      onChange={handleToggleOriginStop}
      TransitionProps={{ timeout: 0, unmountOnExit: true }}
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummary,
          expandIconWrapper: classes.accordionSummaryExpandIconWrapper,
          content: classNames(classes.row, classes.accordionSummaryContent),
        }}
        expandIcon={<Icon name="chevron-down" size={16} color="secondary" />}
      >
        <div className={classes.cell}>{originStop.name}</div>
        <Typography fontSize={12} fontWeight={700} className={classes.cell}>
          <TransTableHead
            i18nKey="pairsQty"
            values={{ count: destinationStopUuids.length }}
            tOptions={{ postProcess: 'interval' }}
          />
        </Typography>
        <Typography fontSize={12} fontWeight={700} className={classes.cell}>
          {Boolean(prices.length) &&
            `${priceMin === priceMax ? 0 : priceMin}–${priceMax} ${
              qty?.currency
            }`}
        </Typography>
        <Typography fontSize={12} className={classes.cell}>
          {qty &&
            (qty.categories.size === 1
              ? [...qty.categories].join('')
              : [...qty.categories].length)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {destinationStopUuids.map((destinationStopId) => {
          const destinationStop = stopsMap.get(destinationStopId)!;
          const fares =
            faresMap.get(originStopId)?.get(destinationStopId) ?? [];
          const qty = qtyMap.get(`${originStopId}_${destinationStopId}`);
          const prices = [...(qty?.fares ?? [])];
          const priceMin = Math.min(...prices);
          const priceMax = Math.max(...prices);
          return (
            <Accordion
              key={destinationStop.id}
              classes={{ root: classes.accordion, region: classes.region }}
              expanded={
                Array.isArray(expandedDestinationStops) &&
                expandedDestinationStops.includes(destinationStopId)
              }
              onChange={handleToggleDestinationStop}
              TransitionProps={{ timeout: 0, unmountOnExit: true }}
            >
              <AccordionSummary
                id={destinationStopId}
                classes={{
                  root: classes.accordionSummary,
                  expandIconWrapper: classes.accordionSummaryExpandIconWrapper,
                  content: classNames(
                    classes.row,
                    classes.accordionSummaryContent
                  ),
                }}
                sx={{ pl: 1, pr: 1 }}
                expandIcon={
                  <Icon name="chevron-down" size={16} color="secondary" />
                }
              >
                <div className={classes.cell}>{originStop.name}</div>
                <div className={classes.cell}>{destinationStop.name}</div>
                <Typography
                  fontSize={12}
                  fontWeight={700}
                  className={classes.cell}
                >
                  {Boolean(prices.length) &&
                    `${priceMin === priceMax ? 0 : priceMin}–${priceMax} ${
                      qty?.currency
                    }`}
                </Typography>
                <Typography fontSize={12} className={classes.cell}>
                  {qty &&
                    (qty.categories.size === 1
                      ? [...qty.categories].join('')
                      : [...qty.categories].length)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <FareModelStopsFares
                  fareModelId={data.fareModelId!}
                  fares={fares}
                  originStopId={originStopId}
                  destinationStopId={destinationStopId}
                  getHeaderGroupProps={{
                    sx: { backgroundColor: 'common.white' },
                  }}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}, areEqual);

export const FareModelStopsTable: FC<FareModelStopsTableProps> = () => {
  const fareModel = useSelector(fareModelSelector);
  const { originStopUuids, destinationStopIds, filters, stopsMap } = useContext(
    FareModelStopsContext
  );
  const { faresMap } = useContext(FareModelStopsFaresContext);
  const listRef = useRef<List>(null);
  const [expandedStops, setExpandedStops] = useState<
    FareModelStopsTableRowProps['expandedStops']
  >(new Map());
  const [expandedOriginIndex, setExpandedOriginIndex] = useState<number>(-1);
  const [expandedDestinationId, setExpandedDestinationId] = useState<string>();
  const handleToggleOriginStop = useCallback<
    FareModelStopsTableRowProps['toggleOriginStop']
  >(({ originStopId, originStopIndex, expanded }) => {
    setExpandedStops((old) => new Map(old.set(originStopId, expanded && [])));
    setExpandedOriginIndex((old) =>
      old === -1 ? originStopIndex : expanded ? originStopIndex : -1
    );
  }, []);
  const handleToggleDestinationStop = useCallback<
    FareModelStopsTableRowProps['toggleDestinationStop']
  >(({ originStopId, destinationStopId, expanded, originStopIndex }) => {
    setExpandedStops((old) => {
      const expandedDestinationStopIds = [
        ...(old.get(originStopId) as Array<string>),
      ];
      return new Map(
        old.set(
          originStopId,
          expanded
            ? [...expandedDestinationStopIds, destinationStopId]
            : expandedDestinationStopIds.filter(
                (stopId) => stopId !== destinationStopId
              )
        )
      );
    });
    setExpandedDestinationId(
      `${originStopId}-${destinationStopId}-${expanded}`
    );
    setExpandedOriginIndex(originStopIndex);
  }, []);

  useEffect(() => {
    listRef.current?.resetAfterIndex(Math.max(0, expandedOriginIndex));
  }, [expandedOriginIndex, expandedDestinationId, faresMap]);
  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [filters]);

  const getItemSize = useCallback<ListProps['itemSize']>(
    (index) => {
      const originStopId = originStopUuids[index];
      const originStop = stopsMap.get(originStopId)!;

      const expandedDestinationStops = expandedStops.get(originStopId);
      const expanded = Boolean(expandedDestinationStops);
      if (!expanded) return itemSize;

      return [
        itemSize,
        8 * 2, // inner Y padding
        filterDestinationStopIds(destinationStopIds, originStop.id).size *
          rowSize,
        (expandedDestinationStops || []).reduce(
          (acc, destinationStopId) =>
            _sum([
              acc,
              rowSize, // Fares title
              rowSize, // Table head
              Math.max(
                (faresMap.get(originStopId)?.get(destinationStopId) ?? [])
                  .length,
                1 // display one row as minimum, No fares found
              ) * rowSize,
              8 * 2, // inner table Y padding,
            ]),
          0
        ),
      ].reduce((prev, cur) => prev + cur, 0);
    },
    [destinationStopIds, expandedStops, faresMap, originStopUuids, stopsMap]
  );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List<FareModelStopsTableRowProps>
          ref={listRef}
          width={width}
          height={height}
          innerElementType={FareModelStopsTableInnerElementType}
          itemCount={originStopUuids.length}
          itemSize={getItemSize}
          estimatedItemSize={itemSize}
          itemData={{
            fareModelId: fareModel?.id,
            expandedStops,
            toggleOriginStop: handleToggleOriginStop,
            toggleDestinationStop: handleToggleDestinationStop,
          }}
        >
          {FareModelStopsTableRow}
        </List>
      )}
    </AutoSizer>
  );
};
