import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ZoneMapList } from 'routes/ZoneMap/ZoneMapList';
import { ZoneMapForm } from 'routes/ZoneMap/ZoneMapForm';
import { ZoneForm } from 'routes/ZoneMap/ZoneForm';

interface ZoneMapsProps {}

export const ZoneMap: FC<ZoneMapsProps> = () => {
  return (
    <Switch>
      <Route path="/zone-fares/maps" component={ZoneMapList} exact />
      <Route
        path="/zone-fares/maps/:action(create|edit)?/:id?"
        component={ZoneMapForm}
        exact
      />
      <Route
        path="/zone-fares/maps/edit/:id/zone/:zoneId?"
        component={ZoneForm}
      />
    </Switch>
  );
};
