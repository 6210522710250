import { createSelector, selector } from 'store/utils';
import { RootState } from 'store';

export const selectVouchersList = selector((state) => state.vouchers.list);
export const vouchersListSelector = createSelector(selectVouchersList);

export const selectVoucherFilter = selector((state) => state.vouchers.filter);
export const voucherFilterSelector = createSelector(selectVoucherFilter);

export const selectVoucher = selector(
  (state: RootState) => state.vouchers.current
);
export const currentVoucherSelector = createSelector(
  selectVoucher,
  (template) => template
);
