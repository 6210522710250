import type { FC } from 'react';
import { FareModelStopsMatrix } from 'routes/FareModels/FareModelStops/FareModelStopsMatrix';
import { SearchResult } from '@fleet/shared';
import { useContext } from 'react';
import { FareModelStopsContext } from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { FareModelCommercialDistanceField } from 'routes/FareModels/FareModelDistances/FareModelCommercialDistanceField';

interface FareModelStopsResultProps {}

export const FareModelCommercialDistanceResult: FC<FareModelStopsResultProps> =
  () => {
    const { originStopUuids, destinationStopUuids } = useContext(
      FareModelStopsContext
    );
    return (
      <SearchResult
        results={
          originStopUuids.length &&
          !(
            originStopUuids.length === 1 &&
            originStopUuids === destinationStopUuids
          )
        }
      >
        <FareModelStopsMatrix
          contentComponent={FareModelCommercialDistanceField}
        />
      </SearchResult>
    );
  };
