import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  decimalToPercentage,
  makeClassificationOptions,
  percentageToDecimal,
  renderPercentageError,
  TableColumns,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { validate } from '@fleet/shared/form/validate';
import {
  createOrUpdatePromotionalDiscountPassengerGroup,
  removePromotionalDiscountPassengerGroups,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import { Option } from '@fleet/shared/dto/option';
import { isDiscountAmountFieldDisabled } from 'routes/PromotionalDiscounts/PromotionalDiscountTabs/utils';
import { fetchPassengerTypes } from 'features/promotionalDiscount/promotionalDiscountService';

interface PromotionalDiscountTabPassengerGroupProps {}

type Entity = PromotionalDiscount['passengerGroup'][number];

export const PromotionalDiscountTabPassengerGroup: FC<PromotionalDiscountTabPassengerGroupProps> =
  () => {
    const currentDiscount = useSelector(promotionalDiscountSelector)!;
    const { passengerGroup, ownerId } = currentDiscount;
    const [passengerTypeOptions, setPassengerTypeOptions] = useState<
      Array<Option<number>>
    >([]);

    useEffect(() => {
      const fetchPassengerTypeOptions = async () => {
        setPassengerTypeOptions(
          makeClassificationOptions(await fetchPassengerTypes(ownerId))
        );
      };

      fetchPassengerTypeOptions();
    }, [ownerId]);

    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          id: 'passengerType.id',
          accessor: ({ passengerType }) => passengerType?.id,
          Header: <TransTableHead i18nKey="passengerType" />,
          type: 'select',
          editableProps: {
            validate: validate.required(),
            options: passengerTypeOptions,
          },
        },
        {
          accessor: 'ticketCountFrom',
          Header: <TransTableHead i18nKey="from" />,
          editableProps: {
            validate: validate.required(),
          },
        },

        {
          accessor: 'ticketCountTo',
          editableProps: {
            validate: validate.required(),
          },
          Header: <TransTableHead i18nKey="to" />,
        },
        {
          accessor: 'discountPercentageAmount',
          Header: <TransTableHead i18nKey="discountWithPercentage" />,
          editableProps: {
            required: false,
            type: 'number',
            validate: renderPercentageError,
            disabled: isDiscountAmountFieldDisabled({
              discount: currentDiscount,
              tabKey: 'passengerGroup',
            }),
          },
        },
      ],
      [currentDiscount, passengerTypeOptions]
    );

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({ discountPercentageAmount, passengerType, ...rest }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountPassengerGroup({
            ...rest,
            passengerTypeId: passengerType.id,
            discountPercentageAmount: discountPercentageAmount
              ? percentageToDecimal(discountPercentageAmount)
              : null,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountPassengerGroups(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={passengerGroup.map((group) => ({
          ...group,
          discountPercentageAmount: group.discountPercentageAmount
            ? decimalToPercentage(group.discountPercentageAmount)
            : null,
        }))}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
