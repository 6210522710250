import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { PassengerTypeDiscountValues } from 'dto/passengerTypeDiscount';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { passengerTypeDiscountLoadingSelector } from 'features/loading/loadingSelectors';
import {
  createPassengerTypeDiscount,
  deletePassengerTypeDiscount,
  getPassengerTypeDiscount,
  getPassengerTypeDiscounts,
  setPassengerTypeDiscount,
  updatePassengerTypeDiscount,
} from 'features/passengerTypeDiscount/passengerTypeDiscountActions';
import { passengerTypeDiscountSelector } from 'features/passengerTypeDiscount/passengerTypeDiscountsSelector';
import { TransAlert } from 'i18n/trans/alert';
import {
  Button,
  CardHeader,
  decimalToPercentage,
  DrawerForm,
  DrawerFormProps,
  FormControl,
  FormProvider,
  Icon,
  Loadable,
  percentageToDecimal,
  useForm,
} from '@fleet/shared';
import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import {
  ButtonGroup,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { PassengerTypeDiscountsFields } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscountsFields';
import { useDispatch, useSelector } from 'store/utils';
import { TransButton } from 'i18n/trans/button';
import _cloneDeep from 'lodash/cloneDeep';
import { Tooltip } from '@fleet/shared/mui';
import { PassengerTypeDiscountAccordion } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscountAccordion';

interface PassengerTypeDiscountsManageFormProps {}

export const PassengerTypeDiscountsForm: FC<PassengerTypeDiscountsManageFormProps> =
  () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { action, id } = useParams<{ action: string; id?: string }>();
    const isEditing = useMemo(
      () => action === 'edit' && Boolean(id),
      [action, id]
    );
    const passengerTypeDiscount = useSelector(passengerTypeDiscountSelector);
    const currentBusinessEntityId = useSelector(
      currentBusinessEntityIdSelector
    );
    const loading = useSelector(passengerTypeDiscountLoadingSelector);

    const handleGoBack = useCallback(() => {
      history.replace('/passenger-type-discounts');
    }, [history]);

    useEffect(() => {
      dispatch(setPassengerTypeDiscount());
      if (isEditing && id) {
        dispatch(getPassengerTypeDiscount(id)).unwrap().catch(handleGoBack);
      }

      return () => {
        dispatch(setPassengerTypeDiscount());
      };
    }, [action, dispatch, handleGoBack, id, isEditing]);

    const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
      (event, reason) => {
        if (reason === 'close') {
          dispatch(setPassengerTypeDiscount());
          handleGoBack();
        }
      },
      [dispatch, handleGoBack]
    );

    const initialValues = useMemo<Partial<PassengerTypeDiscountValues>>(
      () => ({
        ownerId: currentBusinessEntityId,
        discountTypeId: 'BONUS_SCHEME_DISCOUNT_TYPE.PERCENTAGE',
        ...(passengerTypeDiscount && {
          ...passengerTypeDiscount,
          discountPercentageAmount: decimalToPercentage(
            passengerTypeDiscount.discountPercentageAmount
          ),
          discountTypeId: passengerTypeDiscount.discountType.id,
          ticketMinimalCost: passengerTypeDiscount.ticketMinimalCost.map(
            ({ amount, currency: { id: currencyId } }) => ({
              amount,
              currencyId,
            })
          ),
          inventoryClasses:
            passengerTypeDiscount.inventoryClasses?.map(({ id }) => id) ?? [],
        }),
      }),
      [currentBusinessEntityId, passengerTypeDiscount]
    );

    const alert = useAlert();
    const onSubmit = useCallback(
      async ({
        discountPercentageAmount,
        ...rest
      }: PassengerTypeDiscountValues) => {
        const data = await dispatch(
          (!rest.id
            ? createPassengerTypeDiscount
            : updatePassengerTypeDiscount)({
            ...rest,
            discountPercentageAmount: percentageToDecimal(
              discountPercentageAmount
            ),
          })
        ).unwrap();
        if (!rest.id) {
          alert.success(
            <TransAlert
              i18nKey="passengerTypeDiscountCreated"
              values={{ name: data.name }}
            />
          );
          history.replace(`/passenger-type-discounts/edit/${data.id}`);
        } else {
          alert.success(<TransAlert i18nKey="passengerTypeDiscountUpdated" />);
        }
        dispatch(getPassengerTypeDiscounts());
      },
      [alert, dispatch, history]
    );

    const { form, dirty, submitting, handleSubmit } = useForm({
      initialValues,
      onSubmit,
      subscription: { dirty: true, submitting: true },
    });

    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    const handleCopy = useCallback(async () => {
      const copyValues = _cloneDeep<Partial<PassengerTypeDiscountValues>>({
        ...initialValues,
        discountPercentageAmount: percentageToDecimal(
          initialValues.discountPercentageAmount!
        ),
      });
      if (!copyValues) return;
      delete copyValues?.id;
      copyValues.name = `${copyValues.name} (copy)`;
      delete copyValues?.information;
      const data = await dispatch(
        createPassengerTypeDiscount(copyValues as PassengerTypeDiscountValues)
      ).unwrap();
      alert.success(
        <TransAlert
          i18nKey="passengerTypeDiscountCreated"
          values={{ name: data.name }}
        />
      );
      history.replace(`/passenger-type-discounts/edit/${data.id}`);
      dispatch(getPassengerTypeDiscounts());
    }, [alert, dispatch, history, initialValues]);

    const handleDelete = useCallback(async () => {
      await dispatch(deletePassengerTypeDiscount(id!));
      dispatch(setPassengerTypeDiscount());
      alert.success(<TransAlert i18nKey="passengerTypeDiscountDeleted" />);
    }, [alert, dispatch, id]);

    return (
      <DrawerForm open onClose={handleCloseEditForm}>
        <Loadable loading={loading}>
          <FormProvider form={form}>
            <CardHeader
              isLight
              title={
                <Typography variant="subtitle">
                  {isEditing ? (
                    passengerTypeDiscount?.name
                  ) : loading ? (
                    <>&nbsp;</>
                  ) : (
                    <TransSubtitle i18nKey="newPassengerTypeDiscount" />
                  )}
                </Typography>
              }
              action={
                <ButtonGroup>
                  {isEditing && (
                    <>
                      <Button
                        variant="text"
                        startIcon={<Icon name="clone" />}
                        onClick={handleCopy}
                      >
                        <TransButton i18nKey="copy" />
                      </Button>
                      <Button
                        variant="text"
                        color="error"
                        startIcon={<Icon name="trash" />}
                        onClick={handleDelete}
                      >
                        <TransButton i18nKey="delete" />
                      </Button>
                    </>
                  )}
                  <IconButton aria-label="close" onClick={handleGoBack}>
                    <Tooltip
                      content={<TransButton i18nKey="close" />}
                      delay={500}
                    >
                      <Icon name="close" size={24} />
                    </Tooltip>
                  </IconButton>
                </ButtonGroup>
              }
            />
            <CardContent component="form" onSubmit={handleSubmit}>
              <Grid container columns={4} spacing={2}>
                <PassengerTypeDiscountsFields />

                <Grid item xs="auto" sx={{ ml: 'auto' }}>
                  <Stack direction="row" flexWrap="nowrap">
                    <FormControl label="&nbsp;">
                      <Button
                        variant="text"
                        sx={{ whiteSpace: 'nowrap' }}
                        {...(!isEditing && { onClick: handleGoBack })}
                        {...(isEditing && {
                          onClick: handleReset,
                          disabled: !dirty,
                        })}
                      >
                        <TransButton
                          i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                        />
                      </Button>
                    </FormControl>
                    <FormControl label="&nbsp;">
                      <Button
                        variant="contained"
                        icon={isEditing ? 'check' : 'plus'}
                        type="submit"
                        disabled={submitting}
                      >
                        <TransButton i18nKey={isEditing ? 'save' : 'create'} />
                      </Button>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </FormProvider>

          {passengerTypeDiscount && <PassengerTypeDiscountAccordion />}
        </Loadable>
      </DrawerForm>
    );
  };
