import { FC } from 'react';
import { Accordion, AccordionPanel } from '@fleet/shared';
import { Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Info } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscountAccordion/Info';
import { useSelector } from 'store/utils';
import { passengerTypeDiscountSelector } from 'features/passengerTypeDiscount/passengerTypeDiscountsSelector';

export const PassengerTypeDiscountAccordion: FC = () => {
  const passengerTypeDiscount = useSelector(passengerTypeDiscountSelector);

  return (
    <Accordion>
      <AccordionPanel
        id="info"
        summary={
          <Typography variant="subtitle">
            <TransSubtitle i18nKey="info" />
            <Typography component="span" variant="body2" sx={{ ml: 1 }}>
              {`(${passengerTypeDiscount?.information?.length})`}
            </Typography>
          </Typography>
        }
      >
        <Info />
      </AccordionPanel>
    </Accordion>
  );
};
