import { Layout } from '@fleet/shared';
import { PromotionalDiscountsTable } from 'routes/PromotionalDiscounts/PromotionalDiscountsTable';

export default function PromotionalDiscounts() {
  return (
    <Layout>
      <PromotionalDiscountsTable />
    </Layout>
  );
}
