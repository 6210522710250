import { FC, useCallback, useEffect, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Row, useRowSelect, useTable } from 'react-table';
import {
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  tableRowClickEventWrapper,
  useRowActive,
  useTableRowHighlight,
} from '@fleet/shared';
import { CoTaxationGroup } from 'dto/coTaxationGroup';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { TransTableHead } from 'i18n/trans/table';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { getCoTaxationGroups } from 'features/coTaxationGroup/coTaxationGroupActions';
import { coTaxationGroupsLoading } from 'features/loading/loadingSelectors';
import { coTaxationGroupsSelector } from 'features/coTaxationGroup/coTaxationGroupSelectors';

export const CoTaxationGroupTable: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const coTaxationGroups = useSelector(coTaxationGroupsSelector);
  const listLoading = useSelector(coTaxationGroupsLoading);
  const data = useMemo(() => coTaxationGroups ?? [], [coTaxationGroups]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCoTaxationGroups());
  }, [dispatch]);

  const link = useCallback(
    (row: Row<CoTaxationGroup>) =>
      `/co-taxation-groups/edit/${row.original.id}`,
    []
  );

  const getRowId = useCallback((row: CoTaxationGroup) => row.id.toString(), []);

  const columns = useMemo<TableColumns<CoTaxationGroup>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="groupName" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        id: 'contacts',
        accessor: ({ contacts }) =>
          contacts?.map(({ name }) => name).join(', '),
        Header: <TransTableHead i18nKey="carriers" />,
      },
    ],
    [link]
  );

  const table = useTable<CoTaxationGroup>(
    {
      data,
      columns,
      getRowId,
    },
    useRowActive,
    useRowSelect
  );

  useTableRowHighlight(id, table);

  return (
    <Loadable loading={listLoading}>
      <SearchResult
        results={data.length}
        loading={listLoading}
        i18nKey="noDataAdded"
      >
        <Table
          caption={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              height={56}
            >
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', pl: 2 }}
              >
                <TransSubtitle
                  i18nKey="coTaxationGroupsQty"
                  values={{ count: data.length }}
                  tOptions={{ postProcess: 'interval' }}
                />
              </Typography>
            </Stack>
          }
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </Loadable>
  );
};
