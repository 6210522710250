import { createReducer } from '@reduxjs/toolkit';
import {
  ZoneMap,
  ZoneMapSearchFilter,
  ZoneWithLocalizations,
} from 'dto/zoneMap';
import {
  clearCurrentZone,
  clearCurrentZoneMap,
  clearZoneMapList,
  getZone,
  getZoneMap,
  getZoneMapList,
  setZoneMapFilter,
  updateZoneMap,
} from 'features/zoneMap/zoneMapActions';

export interface ZoneMapState {
  currentZoneMap?: ZoneMap;
  currentZone?: ZoneWithLocalizations;
  filter: Partial<ZoneMapSearchFilter>;
  list: ZoneMap[];
}
const initialState: ZoneMapState = {
  list: [],
  filter: {},
};
export const zoneMapReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(getZoneMapList.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(clearZoneMapList, (state) => {
      state.list = [];
    })
    .addCase(getZoneMap.fulfilled, (state, action) => {
      state.currentZoneMap = action.payload;
    })
    .addCase(clearCurrentZoneMap, (state) => {
      state.currentZoneMap = undefined;
    })
    .addCase(getZone.fulfilled, (state, action) => {
      state.currentZone = action.payload;
    })
    .addCase(clearCurrentZone, (state) => {
      state.currentZone = undefined;
    })
    .addCase(updateZoneMap.fulfilled, (state, action) => {
      state.currentZoneMap = action.payload;
    })
    .addCase(setZoneMapFilter, (state, action) => {
      state.filter = action.payload;
    });
});
