import { useMemo } from 'react';
import type { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { classificationsSelector } from 'features/classification/classificationSelectors';
import { makeClassificationOptions } from '@fleet/shared';

type ArrayType<T> = T extends (infer Item)[] ? Item : T;

export const useClassificationOptions = <T extends ClassificationGroup>(
  groupName: T
) => {
  const classifications = useSelector(classificationsSelector);
  return useMemo(
    () =>
      makeClassificationOptions<ArrayType<typeof classifications[T]>>(
        classifications[groupName] ?? []
      ),
    [classifications, groupName]
  );
};

export const useClassificationMap = <T extends ClassificationGroup>(
  groupName: T
) => {
  const classifications = useClassificationOptions(groupName);
  return useMemo(
    () => new Map(classifications.map(({ value, label }) => [value, label])),
    [classifications]
  );
};
