import { currentPriceListSelector } from 'features/zonePriceList/zonePriceListSelectors';
import type { FC, MouseEvent, SyntheticEvent } from 'react';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { TransTableHead } from 'i18n/trans/table';
import { makeStyles } from '@mui/styles';
import { PriceDetailsTabPricesContext } from 'routes/ZonePriceList/context/PriceDetailsTabPricesContext';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  VariableSizeList as List,
  ListChildComponentProps,
} from 'react-window';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Icon } from '@fleet/shared/mui/Icon';
import { PriceDetailsTabPricesTextField } from 'routes/ZonePriceList/PriceDetailsTabPricesTextField';
import { useSelector } from 'store/utils';

const itemSize = 32;
const useStyles = makeStyles(
  (theme) => ({
    sticky: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: theme.palette.background.default,
      height: 48,
      padding: '0 16px 0 48px',
      display: 'flex',
      alignItems: 'center',
    },
    innerElementType: {
      position: 'relative',
    },
    accordion: {
      minWidth: '100%',
      width: 'auto !important' as 'auto',
      '&.Mui-expanded': {
        margin: 0,
        '&:before': {
          opacity: 1,
        },
        '& $accordionSummary': {
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    region: {
      background: theme.palette.background.default,
      padding: theme.spacing(1),
    },
    accordionSummary: {
      minHeight: itemSize,
      flexDirection: 'row-reverse',
      '&.Mui-expanded': {
        minHeight: itemSize,
      },
    },
    accordionSummaryContent: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    accordionSummaryExpandIconWrapper: {
      marginRight: theme.spacing(2),
    },
    accordionDetails: {
      background: theme.palette.common.white,
      padding: 0,
      '& $row': {
        height: 40,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(5),
      },
    },
    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    rowSelected: {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    cell: {
      paddingRight: theme.spacing(2),
      boxSizing: 'content-box',
    },
    cellNo: {
      width: 24,
    },
    cellZone: {
      width: 240,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      lineHeight: '14px',
    },
    cellPrice: {
      flex: 1,
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
    },
  }),
  {
    name: 'PriceDetailsTabPricesTable',
  }
);

const PriceDetailsTabPricesTableInnerElementType = forwardRef<HTMLDivElement>(
  ({ children, ...props }, ref) => {
    const classes = useStyles();
    return (
      <>
        <div className={classNames(classes.sticky, classes.accordion)}>
          <div className={classNames(classes.cell, classes.cellNo)}>
            <TransTableHead i18nKey="number" />
          </div>
          <div className={classNames(classes.cell, classes.cellZone)}>
            <TransTableHead i18nKey="zoneA" />
          </div>
          <div className={classNames(classes.cell, classes.cellNo)}>
            <TransTableHead i18nKey="number" />
          </div>
          <div className={classNames(classes.cell, classes.cellZone)}>
            <TransTableHead i18nKey="zoneB" />
          </div>
          <div className={classNames(classes.cell, classes.cellPrice)}>
            <TransTableHead i18nKey="price" />
          </div>
        </div>
        <div ref={ref} className={classes.innerElementType} {...props}>
          {children}
        </div>
      </>
    );
  }
);

interface PriceDetailsTabPricesTableRowProps {
  priceListId: number;
  expanded: Map<number, boolean>;
  toggleAccordion: (
    id: number,
    index: number
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
}

const PriceDetailsTabPricesTableRow: FC<
  ListChildComponentProps<PriceDetailsTabPricesTableRowProps>
> = memo(({ index: rowIndex, style, data }) => {
  const { zones, zoneIds, prices, selectCell } = useContext(
    PriceDetailsTabPricesContext
  );

  const { priceListId, expanded, toggleAccordion } = data;

  const zone = zones.get(Number(zoneIds[rowIndex]));

  const handleAccordionChange = useCallback<
    Required<AccordionProps>['onChange']
  >(
    (event, expanded) => {
      if (!zone) return;
      toggleAccordion(zone.id, rowIndex)(event, expanded);
    },
    [rowIndex, toggleAccordion, zone]
  );

  const classes = useStyles();
  const handleClick = useCallback(
    (colIndex: number) => (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      selectCell(colIndex, rowIndex);
    },
    [rowIndex, selectCell]
  );

  return (
    <Accordion
      classes={{
        root: classes.accordion,
        region: classes.region,
      }}
      style={style}
      {...(zone && {
        expanded: Boolean(expanded.get(zone.id)),
        onChange: handleAccordionChange,
      })}
      TransitionProps={{
        timeout: 0,
      }}
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummary,
          expandIconWrapper: classes.accordionSummaryExpandIconWrapper,
          content: classNames(classes.row, classes.accordionSummaryContent),
        }}
        expandIcon={<Icon name="chevron-down" size={16} color="secondary" />}
      >
        <div className={classNames(classes.cell, classes.cellNo)}>
          {zone?.orderNumber ?? '-'}
        </div>
        <div className={classNames(classes.cell, classes.cellZone)}>
          {zone?.name}
        </div>
        <div className={classNames(classes.cell, classes.cellNo)} />
        <div className={classNames(classes.cell, classes.cellZone)} />
        <div className={classNames(classes.cell, classes.cellPrice)} />
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {zoneIds.map((id, colIndex) => {
          const subZone = zones.get(Number(id));
          const { selected } = prices.get(`${rowIndex}-${colIndex}`) ?? {};
          if (!zone || colIndex > rowIndex || !subZone) return null;
          return (
            <div
              key={id}
              className={classNames(classes.row, {
                [classes.rowSelected]: selected,
              })}
              onClick={handleClick(colIndex)}
            >
              <div className={classNames(classes.cell, classes.cellNo)}>
                {zone.orderNumber}
              </div>
              <div className={classNames(classes.cell, classes.cellZone)}>
                {zone.name}
              </div>
              <div className={classNames(classes.cell, classes.cellNo)}>
                {subZone.orderNumber}
              </div>
              <div className={classNames(classes.cell, classes.cellZone)}>
                {subZone.name}
              </div>
              <div className={classNames(classes.cell, classes.cellPrice)}>
                <PriceDetailsTabPricesTextField
                  id={priceListId}
                  rowIndex={rowIndex}
                  colIndex={colIndex}
                />
              </div>
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
});

export const PriceDetailsTabPricesTable: FC = () => {
  const { zones, zoneIds } = useContext(PriceDetailsTabPricesContext);

  const listRef = useRef<List>(null);
  const [expanded, setExpanded] = useState<
    PriceDetailsTabPricesTableRowProps['expanded']
  >(new Map());
  const [index, setIndex] = useState<number>(-1);
  const handleToggleAccordion = useCallback<
    PriceDetailsTabPricesTableRowProps['toggleAccordion']
  >(
    (id, index) => (event, isExpanded) => {
      setExpanded((old) => new Map(old.set(id, isExpanded)));
      setIndex((old) => (old === -1 ? index : isExpanded ? index : -1));
    },
    []
  );
  useEffect(() => {
    listRef.current?.resetAfterIndex(Math.max(0, index));
  }, [index]);

  const getId = useCallback((index: number) => zoneIds[index], [zoneIds]);
  const getZoneByIndex = useCallback(
    (index: number) => zones.get(Number(getId(index))),
    [getId, zones]
  );
  const getItemSize = useCallback(
    (index: number) => {
      const zone = getZoneByIndex(index);
      const isExpanded = Boolean(zone && expanded.get(zone.id));
      if (!isExpanded) return itemSize;
      return [itemSize, 8 * 2, (index + 1) * 40].reduce(
        (prev, cur) => prev + cur,
        0
      );
    },
    [expanded, getZoneByIndex]
  );

  const priceList = useSelector(currentPriceListSelector)!;

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List<PriceDetailsTabPricesTableRowProps>
          ref={listRef}
          width={width}
          height={height}
          innerElementType={PriceDetailsTabPricesTableInnerElementType}
          itemCount={zoneIds.length}
          itemData={{
            priceListId: priceList.id,
            expanded,
            toggleAccordion: handleToggleAccordion,
          }}
          itemSize={getItemSize}
          estimatedItemSize={itemSize}
        >
          {PriceDetailsTabPricesTableRow}
        </List>
      )}
    </AutoSizer>
  );
};
