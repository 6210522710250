import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransNav = createTrans({
  coTaxationGroups: (
    <Trans i18nKey="nav.coTaxationGroups" defaults="Co-taxation groups" />
  ),
  fareModels: <Trans i18nKey="nav.fareModels" defaults="Fare models" />,
  passengerTypeDiscounts: (
    <Trans
      i18nKey="nav.passengerTypeDiscounts"
      defaults="Passenger type discounts"
    />
  ),
  passengerTypes: (
    <Trans i18nKey="nav.passengerTypes" defaults="Passenger types" />
  ),
  priceLists: <Trans i18nKey="nav.priceLists" defaults="Pricelists" />,
  promotionalDiscounts: (
    <Trans
      i18nKey="nav.promotionalDiscounts"
      defaults="Promotional discounts"
    />
  ),
  vouchers: <Trans i18nKey="nav.vouchers" defaults="Vouchers" />,
  vouchersTemplate: (
    <Trans i18nKey="nav.vouchersTemplate" defaults="Vouchers template" />
  ),
  zoneFares: <Trans i18nKey="nav.zoneFares" defaults="Zone fares" />,
  zoneMapPriceLists: (
    <Trans i18nKey="nav.zoneMapPriceLists" defaults="Zone map pricelists" />
  ),
  zoneMaps: <Trans i18nKey="nav.zoneMaps" defaults="Zone maps" />,
});
