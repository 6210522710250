import { createSelector, selector } from 'store/utils';
import { RootState } from 'store';

export const selectCurrentPassengerType = selector(
  (state: RootState) => state.passengerType.current
);
export const currentPassengerTypeSelector = createSelector(
  selectCurrentPassengerType
);

export const selectPassengerTypes = selector(
  (state: RootState) => state.passengerType.list
);
export const passengerTypesSelector = createSelector(selectPassengerTypes);

export const selectPassengerTypeFilter = selector(
  (state: RootState) => state.passengerType.filter
);
export const passengerTypeFilterSelector = createSelector(
  selectPassengerTypeFilter
);
