import type { Dispatch, FC, SetStateAction } from 'react';
import { renderToString } from 'react-dom/server';
import {
  createContext,
  ElementType,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { areEqual } from 'react-window';
import type { GridChildComponentProps } from 'react-window';
import { populate } from 'helpers/array';
import {
  FareModelStopsContext,
  FareModelStopsMatrixCellContentProps,
} from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import { Typography } from '@mui/material';
import { noop } from '@fleet/shared/utils/noop';
import { alpha } from '@mui/material/styles';
import { TransField } from 'i18n/trans/field';
import { TransTableHead } from 'i18n/trans/table';

interface FareModelStopsMatrixContextProps {
  areFaresBidirectional: boolean;
  rowCount: number;
  columnCount: number;
  hovered: { row: number; col: number };
  setHovered: Dispatch<
    SetStateAction<FareModelStopsMatrixContextProps['hovered']>
  >;
}
export const FareModelStopsMatrixContext =
  createContext<FareModelStopsMatrixContextProps>({
    areFaresBidirectional: false,
    rowCount: 0,
    columnCount: 0,
    hovered: { row: -1, col: -1 },
    setHovered: noop,
  });
FareModelStopsMatrixContext.displayName = 'FareModelStopsMatrixContext';

export const rowDescHeight = 32; // row + row description
export const stickyRowHeight = 48; // row + row description
export const stickyRowUnidirectionalHeight = stickyRowHeight + rowDescHeight; // row + row description
export const rowHeight = 68;
export const columnWidth = 136;

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    innerElementType: {},
    cell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: '4px 32px 4px 16px',
      fontSize: 12,
      lineHeight: '16px',
      border: `solid ${theme.palette.divider}`,
      borderWidth: '0 1px 1px 0',
    },
    stop: {
      display: '-webkit-box',
      maxWidth: '100%',
      height: 24,
      lineHeight: 1,
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    corner: { cursor: 'default' },
    disabled: { background: theme.palette.background.default },
    hovered: { background: alpha(theme.palette.primary.main, 0.1) },
    sticky: {
      position: 'sticky !important' as 'sticky',
      zIndex: 1,
      background: theme.palette.background.default,
      '&:first-child': {
        borderWidth: 1,
        paddingRight: 16,
      },
    },
    stickyTop: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      zIndex: 2,
      borderWidth: '1px 1px 1px 0',
    },
    stickyTopUnidirectional: {
      paddingTop: rowDescHeight,
      '&:before': {
        content: 'attr(data-content)',
        fontWeight: 700,
        lineHeight: '32px',
        paddingLeft: 'inherit',
        height: rowDescHeight,
        position: 'absolute',
        zIndex: '1',
        top: 0,
        left: -1,
        right: 0,
        background: 'inherit',
        borderBottomWidth: 1,
        borderBottomStyle: 'inherit',
        borderColor: 'inherit',
      },
      '&:first-child:before': {
        left: 0,
      },
    },
    stickyLeft: {
      borderWidth: '0 1px 1px 1px',
    },
  }),
  {
    name: 'FareModelStopsMatrixCell',
  }
);

interface FareModelStopsMatrixCellProps {
  contentComponent: ElementType<FareModelStopsMatrixCellContentProps>;
}

export const FareModelStopsMatrixCell: FC<
  GridChildComponentProps<FareModelStopsMatrixCellProps>
> = memo(({ data, rowIndex, columnIndex: colIndex, style }) => {
  const {
    originStopUuids,
    destinationStopUuids,
    originStopIds,
    destinationStopIds,
    stopsMap,
  } = useContext(FareModelStopsContext);
  const { areFaresBidirectional, hovered, setHovered } = useContext(
    FareModelStopsMatrixContext
  );
  const { contentComponent: ContentComponent } = data;

  const coords = useMemo(
    () => ({
      x: colIndex - 1,
      y: rowIndex - 1,
    }),
    [colIndex, rowIndex]
  );
  const originStopId = useMemo(
    () => originStopUuids[coords.x],
    [coords.x, originStopUuids]
  );
  const destinationStopId = useMemo(
    () => destinationStopUuids[coords.y],
    [coords.y, destinationStopUuids]
  );

  const isSameStop = useMemo(
    () =>
      originStopIds.get(originStopId) ===
      destinationStopIds.get(destinationStopId),
    [destinationStopId, destinationStopIds, originStopId, originStopIds]
  );

  const classes = useStyles();
  const content = useMemo(() => {
    if (rowIndex === 0) {
      if (colIndex === 0)
        return (
          <Typography fontWeight={700} fontSize={12}>
            {areFaresBidirectional ? (
              <TransTableHead i18nKey="stops" />
            ) : (
              <TransField i18nKey="destinationStops" />
            )}
          </Typography>
        );

      return (
        <div className={classes.stop}>{stopsMap.get(originStopId)!.name}</div>
      );
    }

    if (colIndex === 0) {
      return (
        <div className={classes.stop}>
          {stopsMap.get(destinationStopId)!.name}
        </div>
      );
    }

    if (isSameStop) return null;

    return (
      <ContentComponent
        coords={coords}
        originStopId={originStopId}
        destinationStopId={destinationStopId}
      />
    );
  }, [
    ContentComponent,
    areFaresBidirectional,
    classes.stop,
    colIndex,
    coords,
    destinationStopId,
    isSameStop,
    originStopId,
    rowIndex,
    stopsMap,
  ]);

  const handleMouseEnter = useCallback(() => {
    if ([colIndex, rowIndex].includes(0))
      setHovered({ row: rowIndex, col: colIndex });
  }, [colIndex, rowIndex, setHovered]);
  const handleMouseLeave = useCallback(() => {
    setHovered({ row: -1, col: -1 });
  }, [setHovered]);

  return (
    <div
      className={classNames(classes.cell, {
        [classes.corner]: rowIndex === 0 && colIndex === 0,
        [classes.sticky]: rowIndex === 0 || colIndex === 0,
        [classes.stickyLeft]: colIndex === 0,
        [classes.stickyTop]: rowIndex === 0,
        [classes.stickyTopUnidirectional]:
          rowIndex === 0 && !areFaresBidirectional,
        [classes.disabled]: isSameStop,
        [classes.hovered]: hovered.row === rowIndex || hovered.col === colIndex,
      })}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-content=""
      {...(rowIndex === 0 &&
        colIndex === 1 && {
          'data-content': renderToString(<TransField i18nKey="originStops" />),
        })}
    >
      {content}
    </div>
  );
}, areEqual);

export const FareModelStopsMatrixInnerElementType = forwardRef<HTMLDivElement>(
  ({ children, ...props }, ref) => {
    const { areFaresBidirectional, rowCount, columnCount } = useContext(
      FareModelStopsMatrixContext
    );
    const rows = useMemo(() => populate(rowCount!), [rowCount]);
    const columns = useMemo(() => populate(columnCount!), [columnCount]);

    const classes = useStyles();
    return (
      <div className={classes.innerElementType} ref={ref} {...props}>
        {rows.map((rowIndex) =>
          (rowIndex === 0 ? columns : [0]).map((colIndex) => (
            <FareModelStopsMatrixCell
              key={`${rowIndex}.${colIndex}`}
              rowIndex={rowIndex}
              columnIndex={colIndex}
              style={{
                width: columnWidth,
                height: rowHeight,
                ...(rowIndex === 0 && {
                  height: stickyRowHeight,
                  ...(!areFaresBidirectional && {
                    height: stickyRowUnidirectionalHeight,
                  }),
                  top: 0,
                  ...(colIndex === 1 && { marginLeft: 0 }),
                  ...(colIndex === 0 && { zIndex: 3 }),
                }),
                ...(colIndex === 0 && {
                  left: 0,
                  ...(rowIndex === 1 && { marginTop: 0 }),
                }),
              }}
              data={{} as FareModelStopsMatrixCellProps}
            />
          ))
        )}
        {children}
      </div>
    );
  }
);
export const FareModelStopsMatrixItemWrapper: FC<GridChildComponentProps> = ({
  data,
  rowIndex,
  columnIndex,
  style,
}) => {
  const { ItemRenderer } = data;
  // Skip virtualized render for headings
  if ([rowIndex, columnIndex].includes(0)) return null;

  return (
    <ItemRenderer
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      style={style}
      data={data}
    />
  );
};
