import { FC, useMemo } from 'react';
import { joinJSX } from 'helpers/array';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FareModel } from 'dto/fareModel';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { formatDate } from '@fleet/shared/utils/date';
import Card from '@mui/material/Card';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import { alpha } from '@mui/material/styles';
import { TransField } from 'i18n/trans/field';
import { Icon } from '@fleet/shared/mui';
import classNames from 'classnames';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      position: 'relative',
      height: '100%',
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      '& .MuiListItem-root': {
        color: 'inherit',
      },
      '& .MuiListItemText-primary': {
        color: theme.palette.text.secondary,
      },
      '& .MuiListItemText-secondary': {
        color: 'inherit',
      },

      '&:hover': {
        background: alpha(theme.palette.primary.main, 0.1),
        '& $edit': {
          visibility: 'visible',
        },
      },
    },
    disabled: {
      color: theme.palette.action.disabled,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      '& i': {
        fontSize: 12,
        fontWeight: 400,
      },
    },
    edit: {
      visibility: 'hidden',
      position: 'absolute',
      display: 'inline-flex',
      top: 5,
      right: 5,
      width: 24,
      height: 24,
      padding: 4,
      borderRadius: 3,
      background: theme.palette.common.white,
    },
  }),
  {
    name: 'FareModelCard',
  }
);

interface FareModelCardProps extends FareModel {}

const listItemProps: Partial<ListItemTextProps> = {
  primaryTypographyProps: {
    variant: 'body2',
    color: 'secondary.light',
  },
  secondaryTypographyProps: {
    variant: 'body2',
    color: 'text.primary',
  },
};

export const FareModelCard: FC<FareModelCardProps> = (fareModel) => {
  const classes = useStyles();
  const history = useHistory();
  const businessEntities = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );

  const { id, name, ownerId, validity, isActive, fareTypes } = fareModel;
  const connectedFareTypes = useMemo(
    () => fareTypes.map(({ id }) => <TransLabel key={id} i18nKey={id} />),
    [fareTypes]
  );
  const hasConnectedFareTypes = Boolean(fareTypes.length);

  return (
    <Box
      p={2}
      component={Card}
      variant="outlined"
      className={classNames(classes.root, {
        [classes.disabled]: !isActive,
      })}
      square
      onClick={() => history.push(`/fare-models/edit/${id}`)}
    >
      <span className={classes.edit}>
        <Icon name="edit" />
      </span>
      <Typography className={classes.title} variant="body1" color="inherit">
        <strong>{name}</strong>
        {!isActive && (
          <i>
            <TransField i18nKey="inactiveHint" />
          </i>
        )}
      </Typography>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemText
            primary={<TransField i18nKey="owner" />}
            secondary={
              businessEntities.find(({ value }) => value === ownerId)?.label
            }
            {...listItemProps}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={<TransField i18nKey="validityPeriod" />}
            secondary={[validity.from, validity.to]
              .filter(Boolean)
              .map((date) => formatDate(date))
              .join(' - ')}
            {...listItemProps}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <>
                <TransSubtitle i18nKey="connectedFareTypes" />
                {hasConnectedFareTypes && ` (${connectedFareTypes.length})`}
              </>
            }
            secondary={
              <>{hasConnectedFareTypes ? joinJSX(connectedFareTypes) : '–'}</>
            }
            {...listItemProps}
          />
        </ListItem>
      </List>
    </Box>
  );
};
