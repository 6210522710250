import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useModal } from '@fleet/shared/hooks';
import { Button, Grid } from '@mui/material';
import { Modal } from '@fleet/shared/mui';
import {
  FormProvider,
  PeriodField,
  formSubmit,
  useForm,
  SelectOwnerField,
} from '@fleet/shared';
import { CheckboxGroupField, TextField } from '@fleet/shared/form';
import { Icon } from '@fleet/shared';
import { FareModelCopy, fareTypes } from 'dto/fareModel';
import { useDispatch, useSelector } from 'store/utils';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import {
  copyFareModel,
  getFareModels,
} from 'features/fareModel/fareModelActions';
import { TransModal } from 'i18n/trans/modal';
import { TransAlert } from 'i18n/trans/alert';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';

const useStyles = makeStyles(
  () => ({
    fareTypes: {
      '& .MuiFormGroup-root': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
  }),
  {
    name: 'FareModelCopyModal',
  }
);

interface FareModelCopyModalProps {}

export const FareModelCopyModal: FC<FareModelCopyModalProps> = () => {
  const { open: isOpen, onOpen, onClose } = useModal();
  const currentFareModel = useSelector(fareModelSelector)!;
  const initialValues = useMemo<FareModelCopy>(
    () => ({
      id: currentFareModel.id!,
      name: `${currentFareModel.name} (copy)`,
      ownerId: currentFareModel.ownerId,
      validity: {
        ...currentFareModel.validity,
        from: '' as unknown as Date,
        to: '' as unknown as Date,
      },
      fareTypes: currentFareModel.fareTypes.map(({ id }) => id),
    }),
    [currentFareModel]
  );
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const businessEntities = useSelector(businessEntitiesSelector);

  const onSubmit = useCallback(
    async (values: FareModelCopy) =>
      formSubmit(async () => {
        const { id } = await dispatch(
          copyFareModel({
            id: values.id,
            name: values.name,
            validity: values.validity,
            fareTypes: values.fareTypes,
          })
        ).unwrap();
        alert.success(<TransAlert i18nKey="fareModelCreated" />);
        onClose();
        await dispatch(getFareModels());
        history.push(`/fare-models/edit/${id}`);
      }),
    [alert, dispatch, history, onClose]
  );

  const { form, handleSubmit } = useForm<FareModelCopy>({
    initialValues,
    onSubmit,
  });

  const classes = useStyles();
  return (
    <>
      <Button
        startIcon={<Icon name="duplicate" size={16} />}
        size="small"
        onClick={onOpen}
      >
        <TransButton i18nKey="copy" />
      </Button>
      <Modal
        open={isOpen}
        title={<TransModal i18nKey="fareModelCopyTitle" />}
        onClose={onClose}
        actionButton={
          <Button
            variant="contained"
            startIcon={<Icon name="duplicate" size={16} />}
            onClick={() => handleSubmit()}
          >
            <TransButton i18nKey="copy" />
          </Button>
        }
        maxWidth="xs"
      >
        <FormProvider form={form}>
          <Grid container rowSpacing={2}>
            <TextField
              label={<TransField i18nKey="name" />}
              name="name"
              margin="normal"
              required
            />
            <SelectOwnerField
              businessEntities={businessEntities}
              allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
              margin="normal"
              disabled
            />
            <PeriodField
              from={{
                name: 'validity.from',
                label: <TransField i18nKey="validFrom" />,
                required: true,
                margin: 'normal',
                popperProps: { strategy: 'fixed' },
              }}
              to={{
                name: 'validity.to',
                label: <TransField i18nKey="validTo" />,
                isClearable: true,
                margin: 'normal',
                popperProps: { strategy: 'fixed' },
              }}
              grid={false}
            />
            <CheckboxGroupField
              className={classes.fareTypes}
              name="fareTypes"
              label={<TransField i18nKey="copyDataFromConnectedFareTypes" />}
              options={fareTypes.map((value) => ({
                value,
                label: <TransLabel i18nKey={value} />,
                disabled: !initialValues.fareTypes.includes(value),
              }))}
              margin="normal"
              inline
            />
          </Grid>
        </FormProvider>
      </Modal>
    </>
  );
};
