import { FC, useMemo, useCallback } from 'react';
import { TableColumns } from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { PromotionalDiscount } from 'dto/promotionalDiscount';
import { promotionalDiscountSelector } from 'features/promotionalDiscount/promotionalDiscountSelector';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  createOrUpdatePromotionalDiscountInformation,
  removePromotionalDiscountInformation,
} from 'features/promotionalDiscount/promotionalDiscountTabActions';
import { PromotionalDiscountTab } from 'routes/PromotionalDiscounts/PromotionalDiscountTab';
import { validate } from '@fleet/shared/form/validate';

interface PromotionalDiscountTabInformationProps {}

type Entity = PromotionalDiscount['information'][number];

export const PromotionalDiscountTabInformation: FC<PromotionalDiscountTabInformationProps> =
  () => {
    const { information } = useSelector(promotionalDiscountSelector)!;
    const languageOptions = useClassificationOptions(
      ClassificationGroup.CULTURE
    );
    const columns: TableColumns<Entity> = useMemo(
      () => [
        {
          accessor: 'discountName',
          Header: <TransTableHead i18nKey="discountName" />,
          editableProps: {
            validate: validate.required(),
          },
        },
        {
          id: 'language.id',
          accessor: ({ language }) => language?.id,
          Header: <TransTableHead i18nKey="language" />,
          type: 'select',
          editableProps: {
            options: languageOptions,
            validate: validate.required(),
          },
        },
        {
          accessor: 'information',
          Header: <TransTableHead i18nKey="description" />,
          editableProps: {
            multiline: true,
            validate: validate.required(),
          },
        },
      ],
      [languageOptions]
    );

    const dispatch = useDispatch();
    const onRowUpdate = useCallback(
      async ({ language, ...payload }: Entity) => {
        await dispatch(
          createOrUpdatePromotionalDiscountInformation({
            ...payload,
            languageId: language.id,
          })
        ).unwrap();
      },
      [dispatch]
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Entity>) => {
        await dispatch(removePromotionalDiscountInformation(payload));
      },
      [dispatch]
    );

    return (
      <PromotionalDiscountTab
        data={information}
        columns={columns}
        onRowUpdate={onRowUpdate}
        onRowsRemove={onRowsRemove}
      />
    );
  };
