import type { FC } from 'react';
import { PricesSearchTable } from 'routes/ZonePriceList/PricesSearchTable';
import { Route, Switch } from 'react-router-dom';
import { PriceListsDetails } from 'routes/ZonePriceList/PriceListsDetails';

interface PriceListProps {}

export const PriceLists: FC<PriceListProps> = () => {
  return (
    <Switch>
      <Route
        path="/zone-fares/pricelists"
        component={PricesSearchTable}
        exact
      />
      <Route
        path={`/zone-fares/pricelists/:action(create|edit)?/:id?`}
        component={PriceListsDetails}
        exact
      />
    </Switch>
  );
};
