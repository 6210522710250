import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  activate: <Trans i18nKey="button.activate" defaults="Activate" />,
  add: <Trans i18nKey="button.add" defaults="Add" />,
  addEdit: <Trans i18nKey="button.addEdit" defaults="Add/Edit" />,
  addNew: <Trans i18nKey="button.addNew" defaults="Add new" />,
  addToSelection: (
    <Trans i18nKey="button.addToSelection" defaults="Add to selection" />
  ),
  addTranslation: (
    <Trans i18nKey="button.addTranslation" defaults="Add translation" />
  ),
  back: <Trans i18nKey="button.back" defaults="Back" />,
  calculate: <Trans i18nKey="button.calculate" defaults="Calculate" />,
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  close: <Trans i18nKey="button.close" defaults="Close" />,
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  copy: <Trans i18nKey="button.copy" defaults="Copy" />,
  create: <Trans i18nKey="button.create" defaults="Create" />,
  deactivate: <Trans i18nKey="button.deactivate" defaults="Deactivate" />,
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  deleteSelected: (
    <Trans i18nKey="button.deleteSelected" defaults="Delete selected" />
  ),
  edit: <Trans i18nKey="button.edit" defaults="Edit" />,
  export: <Trans i18nKey="button.export" defaults="Export" />,
  filter: <Trans i18nKey="button.filter" defaults="Filter" />,
  massEdit: <Trans i18nKey="button.massEdit" defaults="Mass edit" />,
  removeFromSelection: (
    <Trans
      i18nKey="button.removeFromSelection"
      defaults="Remove from selection"
    />
  ),
  removeTranslation: (
    <Trans i18nKey="button.removeTranslation" defaults="Remove translation" />
  ),
  resetChanges: (
    <Trans i18nKey="button.resetChanges" defaults="Reset changes" />
  ),
  resetFilters: (
    <Trans i18nKey="button.resetFilters" defaults="Reset filters" />
  ),
  save: <Trans i18nKey="button.save" defaults="Save" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  viewAll: <Trans i18nKey="button.viewAll" defaults="View all" />,
});
