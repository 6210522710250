import { createSelector, selector } from 'store/utils';

export const selectZoneMapList = selector((state) => state.zoneMap.list);
export const zoneMapListSelector = createSelector(selectZoneMapList);

export const selectCurrentZoneMap = selector(
  (state) => state.zoneMap.currentZoneMap
);
export const zoneMapSelector = createSelector(selectCurrentZoneMap);

export const selectCurrentZone = selector((state) => state.zoneMap.currentZone);
export const zoneSelector = createSelector(selectCurrentZone);

export const selectMapFilter = selector((state) => state.zoneMap.filter);
export const mapFilterSelector = createSelector(selectMapFilter);

export const selectCurrentZoneId = selector(
  (state) => state.zoneMap.currentZone?.id
);
export const selectCurrentZoneMapId = selector(
  (state) => state.zoneMap.currentZoneMap?.id
);
