import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransHint = createTrans({
  categories: <Trans i18nKey="hint.categories" defaults="{{num}} categories" />,
  destinationStops: (
    <Trans
      i18nKey="hint.destinationStops"
      defaults="If left empty, the destination stops are filled with the origin stops"
    />
  ),
  inventoryClassesIncludesCompartment: (
    <Trans
      i18nKey="hint.inventoryClassesIncludesCompartment"
      defaults="Passenger based configurations are applied to all but compartment inventory class."
    />
  ),
  fareDirection: (
    <Trans
      i18nKey="hint.fareDirection"
      defaults="Bidirectional fares between two stops are the same, no matter the direction"
    />
  ),
  plusMore: <Trans i18nKey="hint.plusMore" defaults="+ {{num}} more" />,
  required: <Trans i18nKey="hint.required" defaults="Required" />,
  reservationNotAllowed: (
    <Trans
      i18nKey="hint.reservationNotAllowed"
      defaults="If enabled, the passenger can not have their own seat"
    />
  ),
  validityCriteriaMandatory: (
    <Trans
      i18nKey="hint.validityCriteriaMandatory"
      defaults="At least one validity criteria needs to exist in order to activate passenger type"
    />
  ),
});
