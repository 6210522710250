import {
  AddButton,
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  RadioGroupField,
  SearchForm,
  SelectOwnerField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { PassengerTypeFilter } from 'dto/passengerType';
import {
  getPassengerTypes,
  setPassengerTypeFilter,
} from 'features/passengerType/passengerTypeActions';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { passengerTypeFilterSelector } from 'features/passengerType/passengerTypeSelectors';
import { SelectField, TextField } from '@fleet/shared/form';
import { TransField } from 'i18n/trans/field';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { TransHint } from 'i18n/trans/hint';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { TransTitle } from 'i18n/trans/title';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { pick } from 'lodash';

export const PassengerTypeSearchForm = () => {
  const dispatch = useDispatch();
  const filter = useSelector(passengerTypeFilterSelector);
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const passengerTypeCategoryOptions = useClassificationOptions(
    ClassificationGroup.PASSENGER_TYPE_CATEGORY
  );

  const defaultValues = useMemo(
    () => ({ ownerId: currentBusinessEntityId }),
    [currentBusinessEntityId]
  );

  const initialValues = useMemo(
    () => ({ ...defaultValues, ...filter }),
    [defaultValues, filter]
  );

  const onSubmit = useCallback(
    (values: Partial<PassengerTypeFilter>) => {
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getPassengerTypes({ ...values, offset: 0 }));
      });
    },
    [dispatch]
  );

  const { form, handleSubmit } = useForm({ initialValues, onSubmit });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(
      setPassengerTypeFilter({
        ...defaultValues,
        ...pick(filter, ['limit', 'offset']),
      })
    );
  }, [defaultValues, dispatch, filter, form]);

  return (
    <SearchForm
      title={<TransTitle i18nKey="passengerTypes" />}
      action={
        <AddButton component={Link} to="/passenger-types/create">
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={3} spacing={2}>
            <Grid item xs={1}>
              <SelectOwnerField
                businessEntities={businessEntities}
                allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
                required={false}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <TextField label={<TransField i18nKey="name" />} name="name" />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                label={<TransField i18nKey="category" />}
                name="passengerTypeCategoryId"
                options={passengerTypeCategoryOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                options="BOOL"
                name="isActive"
                label={<TransField i18nKey="isActive" />}
                inline
              />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                options="BOOL"
                name="isWithoutSeatReservation"
                label={
                  <Stack direction="row" alignItems="center">
                    <span>
                      <TransField i18nKey="seatReservationNotAllowed" />
                    </span>
                    <Tooltip
                      placement="top"
                      content={<TransHint i18nKey="reservationNotAllowed" />}
                    >
                      <Icon name="question" margin size={14} />
                    </Tooltip>
                  </Stack>
                }
                inline
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
