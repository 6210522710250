import { createSelector, selector } from 'store/utils';

export const selectPriceListList = selector((state) => state.zonePriceList.list);
export const pricesListListSelector = createSelector(selectPriceListList);

export const selectPricesListFilter = selector((state) => state.zonePriceList.filter);
export const pricesListFilterSelector = createSelector(selectPricesListFilter);

export const selectCurrentPriceList = selector((state) => state.zonePriceList.current);
export const currentPriceListSelector = createSelector(selectCurrentPriceList);
