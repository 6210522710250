import type { ReactElement } from 'react';
import { cloneElement, Fragment } from 'react';

export const groupByToMap = <K, V>(array: V[], grouper: (item: V) => K) =>
  array.reduce((store, item) => {
    const key = grouper(item);
    if (!store.has(key)) {
      store.set(key, [item]);
    } else {
      store.get(key)?.push(item);
    }
    return store;
  }, new Map<K, V[]>());

export const groupByToMapTransform = <T, K, R>(
  array: T[],
  grouper: (x: T) => K,
  transformer: (x: T[]) => R
) => transformMap(groupByToMap(array, grouper), transformer);

export function transformMap<K, V, R>(
  source: Map<K, V>,
  transformer: (value: V, key: K) => R
) {
  return new Map(
    Array.from(source, ({ 0: key, 1: value }) => [key, transformer(value, key)])
  );
}

export const populate = (count: number) =>
  new Array(count).fill(null).map((_, i) => i);

export const joinJSX = <T extends Array<ReactElement>>(
  array: T,
  separator: JSX.Element | null = <>,&nbsp;</>
) =>
  array.reduce<Array<ReactElement>>(
    (acc, item) => [
      ...acc,
      <Fragment key={`${item.key}_separator`}>
        {acc.length ? separator : null}
      </Fragment>,
      cloneElement(item as JSX.Element, { key: item.key }),
    ],
    []
  );
