import { createAsyncThunk } from 'store/utils';
import { CoTaxationGroup } from 'dto/coTaxationGroup';
import { createAction } from '@reduxjs/toolkit';
import { api } from '@fleet/shared';

const conf = { baseURL: process.env.REACT_APP_API_OM };

export const setCoTaxationGroup = createAction(
  'coTaxationGroups/setCoTaxationGroup'
);

export const getCoTaxationGroups = createAsyncThunk<Array<CoTaxationGroup>>(
  'coTaxationGroups/list',
  async () =>
    (
      await api.get<{ items: Array<CoTaxationGroup> }>(
        '/co-taxation-groups',
        conf
      )
    ).data.items
);

export const getCoTaxationGroup = createAsyncThunk<
  CoTaxationGroup,
  string | number
>(
  'coTaxationGroups/read',
  async (id) => (await api.get(`/co-taxation-groups/${id}`, conf)).data
);

export const createCoTaxationGroup = createAsyncThunk<
  { id: number },
  Omit<CoTaxationGroup, 'id'>
>(
  'coTaxationGroups/create',
  async (payload) => (await api.post('/co-taxation-groups', payload, conf)).data
);

export const updateCoTaxationGroup = createAsyncThunk<never, CoTaxationGroup>(
  'coTaxationGroups/update',
  async ({ id, ...payload }) =>
    await api.put(`/co-taxation-groups/${id}`, payload, conf)
);
