import { priceLoadingSelector } from 'features/loading/loadingSelectors';
import { currentPriceListSelector } from 'features/zonePriceList/zonePriceListSelectors';
import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo } from 'react';
import {
  getPriceList,
  setPriceList,
} from 'features/zonePriceList/zonePriceListActions';
import { TransTitle } from 'i18n/trans/title';
import { Layout } from '@fleet/shared/components/Layout';
import { useHistory, useParams } from 'react-router-dom';
import { PriceDetailsForm } from 'routes/ZonePriceList/PriceDetailsForm';
import { PriceDetailsTabs } from 'routes/ZonePriceList/PriceDetailsTabs';
import { useDispatch, useSelector } from 'store/utils';
import { Loadable } from '@fleet/shared/components/Loadable';
import { useLoaded } from '@fleet/shared/hooks';
import { CardHeader } from '@fleet/shared';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      overflowX: 'hidden',
      zIndex: 1,
      flex: 1,
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    paper: { overflow: 'unset' },
    col: { overflow: 'unset' },
  }),
  {
    name: 'PriceListsDetails',
  }
);

interface PriceListsDetailsProps {}

export const PriceListsDetails: FC<PriceListsDetailsProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const isCreation = useMemo(() => Boolean(action === 'create'), [action]);
  const isEdit = useMemo(() => Boolean(action === 'edit' && id), [action, id]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isEdit) {
      dispatch(getPriceList(id!))
        .unwrap()
        .catch(() => history.replace('/zone-fares/pricelists'));
    }

    return () => {
      dispatch(setPriceList());
    };
  }, [isEdit, dispatch, id, history]);

  const loading = useSelector(priceLoadingSelector);
  const loaded = useLoaded(loading);
  const priceList = useSelector(currentPriceListSelector);
  const classes = useStyles();
  return (
    <Loadable loading={loading}>
      <div className={classes.root}>
        <Layout
          sx={{ flex: '0 !important', minHeight: 'unset !important' }}
          classes={{ paper: classes.paper, col: classes.col }}
          header={
            <CardHeader
              title={
                <>
                  {priceList && priceList.id ? (
                    <>
                      <TransTitle i18nKey="priceList" />: {priceList.name}
                    </>
                  ) : (
                    <TransTitle i18nKey="addPriceList" />
                  )}
                </>
              }
            />
          }
        >
          <PriceDetailsForm isEdit={isEdit} isCreation={isCreation} />
        </Layout>
        {Boolean(loaded && isEdit) && <PriceDetailsTabs />}
      </div>
    </Loadable>
  );
};
