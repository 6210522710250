import { Pagination } from '@fleet/shared/dto/pagination';
import { createAsyncThunk } from 'store/utils';
import qs from 'qs';
import { DateValue, Voucher, VoucherBase, VoucherFilter } from 'dto/voucher';
import { createAction } from '@reduxjs/toolkit';
import { api } from '@fleet/shared';

export const getVouchers = createAsyncThunk<
  Pagination<VoucherBase>,
  Partial<VoucherFilter>
>('voucher/getVouchers', async (values, { dispatch }) => {
  values && dispatch(setVoucherFilter(values));
  return (
    await api.get(
      `/vouchers${qs.stringify(values, {
        addQueryPrefix: true,
      })}`,
      {
        baseURL: process.env.REACT_APP_API_BO,
        data: {},
      }
    )
  ).data;
});

export const getVoucher = createAsyncThunk<Voucher, string>(
  'voucher/getVoucher',
  async (id) => {
    return (
      await api.get(`/vouchers/${id}`, {
        baseURL: process.env.REACT_APP_API_BO,
      })
    ).data;
  }
);

export const updateVoucher = createAsyncThunk<
  Voucher,
  { validUntil: DateValue; isCancelled: boolean; id: string }
>('voucher/updateVoucher', async (data) => {
  return (
    await api.put(`/vouchers/${data.id}`, data, {
      baseURL: process.env.REACT_APP_API_BO,
    })
  ).data;
});

export const clearVouchers = createAction('voucher/clearVouchers');

export const setVoucherFilter = createAction<Partial<VoucherFilter>>(
  'voucher/setVoucherFilter'
);
