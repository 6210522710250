import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  coTaxationGroupCarrierDeletionDescription: (
    <Trans
      i18nKey="modal.title.coTaxationGroupCarrierDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected co-taxation group carrier? It cannot be undone];(2-inf)[Are you sure you want to delete selected co-taxation group carriers? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  coTaxationGroupCarrierDeletionTitle: (
    <Trans
      i18nKey="modal.title.coTaxationGroupCarrierDeletionTitle"
      defaults="Delete co-taxation group carrier"
    />
  ),
  distanceFareDeletionDescription: (
    <Trans
      i18nKey="modal.title.distanceFareDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected distance fare? It cannot be undone];(2-inf)[Are you sure you want to delete selected distance fares? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  distanceFareDeletionTitle: (
    <Trans
      i18nKey="modal.title.distanceFareDeletionTitle"
      defaults="Delete distance fare"
    />
  ),
  fareModelCopyTitle: (
    <Trans
      i18nKey="modal.title.fareModelCopyTitle"
      defaults="Copy fare model"
    />
  ),
  fareModelDistanceRangeFareDeletionDescription: (
    <Trans
      i18nKey="modal.title.fareModelDistanceRangeFareDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected fare model distance range fare? It cannot be undone];(2-inf)[Are you sure you want to delete selected fare model distance range fares? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  fareModelDistanceRangeFareDeletionTitle: (
    <Trans
      i18nKey="modal.title.fareModelDistanceRangeFareDeletionTitle"
      defaults="Delete fare model distance range fare"
    />
  ),
  fareModelFareDeletionDescription: (
    <Trans
      i18nKey="modal.title.fareModelFareDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected fare? It cannot be undone];(2-inf)[Are you sure you want to delete selected fares? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  fareModelFareDeletionTitle: (
    <Trans
      i18nKey="modal.title.fareModelFareDeletionTitle"
      defaults="Delete origin destination fare"
    />
  ),
  fixedFareDeletionDescription: (
    <Trans
      i18nKey="modal.title.fixedFareDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected fixed fare? It cannot be undone];(2-inf)[Are you sure you want to delete selected fixed fares? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  fixedFareDeletionTitle: (
    <Trans
      i18nKey="modal.title.fixedFareDeletionTitle"
      defaults="Delete fixed fare"
    />
  ),
  localizationDeletionDescription: (
    <Trans
      i18nKey="modal.title.localizationDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected localization? It cannot be undone];(2-inf)[Are you sure you want to delete selected localizations? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  localizationDeletionTitle: (
    <Trans
      i18nKey="modal.title.localizationDeletionTitle"
      defaults="Delete localization"
    />
  ),
  passengerTypeDiscountDeletionDescription: (
    <Trans
      i18nKey="modal.title.passengerTypeDiscountDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected passenger type discount? It cannot be undone];(2-inf)[Are you sure you want to delete selected passenger type discounts? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengerTypeDiscountDeletionTitle: (
    <Trans
      i18nKey="modal.title.passengerTypeDiscountDeletionTitle"
      defaults="Delete passenger type discount"
    />
  ),
  passengerGroupDeletionDescription: (
    <Trans
      i18nKey="modal.title.passengerGroupDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected passenger group? It cannot be undone];(2-inf)[Are you sure you want to delete selected passenger groups? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengerGroupDeletionTitle: (
    <Trans
      i18nKey="modal.title.passengerGroupDeletionTitle"
      defaults="Delete passenger group"
    />
  ),
  passengerTypeDiscountInfoDeletionDescription: (
    <Trans
      i18nKey="modal.title.passengerTypeDiscountInfoDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected passenger type discount info? It cannot be undone];(2-inf)[Are you sure you want to delete selected passenger type discount infos? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengerTypeDiscountInfoDeletionTitle: (
    <Trans
      i18nKey="modal.title.passengerTypeDiscountInfoDeletionTitle"
      defaults="Delete passenger type discount info"
    />
  ),
  passengerTypeTranslationDeletionDescription: (
    <Trans
      i18nKey="modal.title.passengerTypeTranslationDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected passenger type translation? It cannot be undone];(2-inf)[Are you sure you want to delete selected passenger type translations? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengerTypeTranslationDeletionTitle: (
    <Trans
      i18nKey="modal.title.passengerTypeTranslationDeletionTitle"
      defaults="Delete passenger type translation"
    />
  ),
  passengerTypeValidityDeletionDescription: (
    <Trans
      i18nKey="modal.title.passengerTypeValidityDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected passenger type validity? It cannot be undone];(2-inf)[Are you sure you want to delete selected passenger type validities? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengerTypeValidityDeletionTitle: (
    <Trans
      i18nKey="modal.title.passengerTypeValidityDeletionTitle"
      defaults="Delete passenger type validity"
    />
  ),
  selectStops: (
    <Trans i18nKey="modal.title.selectStops" defaults="Select stops" />
  ),
});
