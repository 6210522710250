import type { ElementType, FC } from 'react';
import { useCallback, useContext, useState } from 'react';
import type { VariableSizeGridProps } from 'react-window';
import { VariableSizeGrid as Grid } from 'react-window';
import {
  FareModelStopsContext,
  FareModelStopsMatrixCellContentProps,
} from 'routes/FareModels/FareModelStops/FareModelStopsContext';
import {
  columnWidth,
  FareModelStopsMatrixCell,
  FareModelStopsMatrixContext,
  FareModelStopsMatrixInnerElementType,
  FareModelStopsMatrixItemWrapper,
  rowHeight,
  stickyRowHeight,
  stickyRowUnidirectionalHeight,
} from 'routes/FareModels/FareModelStops/FareModelStopsMatrixCell';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSelector } from 'store/utils';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';

interface FareModelStopsMatrixProps {
  contentComponent: ElementType<FareModelStopsMatrixCellContentProps>;
}

const FareModelStopsMatrixGrid: FC<
  VariableSizeGridProps & { areFaresBidirectional: boolean }
> = ({
  areFaresBidirectional,
  children: ItemRenderer,
  rowCount,
  columnCount,
  itemData,
  ...props
}) => {
  const [hovered, setHovered] = useState({ row: -1, col: -1 });

  return (
    <FareModelStopsMatrixContext.Provider
      value={{
        areFaresBidirectional,
        rowCount,
        columnCount,
        hovered,
        setHovered,
      }}
    >
      <Grid
        itemData={{ ItemRenderer, ...itemData }}
        rowCount={rowCount}
        columnCount={columnCount}
        {...props}
      >
        {FareModelStopsMatrixItemWrapper}
      </Grid>
    </FareModelStopsMatrixContext.Provider>
  );
};

export const FareModelStopsMatrix: FC<FareModelStopsMatrixProps> = ({
  contentComponent,
}) => {
  const { areFaresBidirectional } = useSelector(fareModelSelector)!;
  const { originStopUuids, destinationStopUuids } = useContext(
    FareModelStopsContext
  );
  const getRowHeight = useCallback<VariableSizeGridProps['rowHeight']>(
    (index: number) =>
      index === 0
        ? !areFaresBidirectional
          ? stickyRowUnidirectionalHeight
          : stickyRowHeight
        : rowHeight,
    [areFaresBidirectional]
  );
  const getColumnWidth = useCallback<VariableSizeGridProps['columnWidth']>(
    () => columnWidth,
    []
  );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <FareModelStopsMatrixGrid
          areFaresBidirectional={areFaresBidirectional}
          width={width}
          height={height}
          innerElementType={FareModelStopsMatrixInnerElementType}
          rowCount={destinationStopUuids.length + 1}
          columnCount={originStopUuids.length + 1}
          estimatedRowHeight={rowHeight}
          rowHeight={getRowHeight}
          estimatedColumnWidth={columnWidth}
          columnWidth={getColumnWidth}
          itemData={{ contentComponent }}
        >
          {FareModelStopsMatrixCell}
        </FareModelStopsMatrixGrid>
      )}
    </AutoSizer>
  );
};
