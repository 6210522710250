import { FC, useCallback, useMemo } from 'react';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  Icon,
  SearchForm,
  useForm,
} from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { useDispatch, useSelector } from 'store/utils';
import { fareModelFixedFilterSelector } from 'features/fareModel/fareModelFixedSelectors';
import { FareModelFixedFareFilter } from 'dto/fareModelFixed';
import {
  getFixedFares,
  setFixedFaresFilter,
} from 'features/fareModel/fareModelFixedActions';
import { SelectField } from '@fleet/shared/form';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { fareModelSelector } from 'features/fareModel/fareModelSelectors';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';

export const FareModelFixedSearchForm: FC = () => {
  const dispatch = useDispatch();
  const fareModel = useSelector(fareModelSelector);
  const filter = useSelector(fareModelFixedFilterSelector);
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );
  const fareCategoryOptions = useClassificationOptions(
    ClassificationGroup.FARE_CATEGORY
  );

  const onSubmit = useCallback(
    (values: Partial<FareModelFixedFareFilter>) => {
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        dispatch(getFixedFares({ ...values, offset: 0 }));
      });
    },
    [dispatch]
  );

  const initialValues = useMemo(() => filter, [filter]);
  const { form, handleSubmit } = useForm({ initialValues, onSubmit });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setFixedFaresFilter(pick(filter, ['limit', 'offset'])));
  }, [dispatch, filter, form]);

  return (
    <SearchForm
      title={
        <Stack direction="row" gap={2}>
          <TransSubtitle
            i18nKey="fixedFares"
            values={{ name: fareModel?.name }}
          />
          <Button
            variant="text"
            startIcon={<Icon name="arrow-left-circle" />}
            component={Link}
            to={`/fare-models/edit/${fareModel?.id}`}
            color="yellow"
          >
            <TransButton i18nKey="back" />
          </Button>
        </Stack>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2}>
            <Grid item xs={1}>
              <SelectField
                label={<TransField i18nKey="currency" />}
                name="currencyId"
                options={currencyOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                label={<TransField i18nKey="fareCategory" />}
                name="fareCategoryId"
                options={fareCategoryOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
