import type { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PassengerTypeDetails } from 'routes/PassengerTypes/PassengerTypeDetails';
import { useSelector } from 'store/utils';
import { passengerTypesLoadingSelector } from 'features/loading/loadingSelectors';
import { Layout } from '@fleet/shared/components/Layout';
import { Loadable } from '@fleet/shared';
import { PassengerTypeTable } from './PassengerTypeTable';

interface PassengerTypesProps {}

export const PassengerTypes: FC<PassengerTypesProps> = () => {
  const loading = useSelector(passengerTypesLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Switch>
            <Route
              path="/passenger-types/:action(create|edit)/:id?"
              component={PassengerTypeDetails}
              exact
            />
          </Switch>
        }
      >
        <PassengerTypeTable />
      </Layout>
    </Loadable>
  );
};
