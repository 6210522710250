import type { FC } from 'react';
import { useState } from 'react';
import { selectCurrentPriceList } from 'features/zonePriceList/zonePriceListSelectors';
import { FormControlLabel, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { makeStyles } from '@mui/styles';
import { PriceDetailsTabPricesContextProvider } from 'routes/ZonePriceList/context/PriceDetailsTabPricesContext';
import { PriceDetailsTabPricesMatrix } from 'routes/ZonePriceList/PriceDetailsTabPricesMatrix';
import { PriceDetailsTabPricesTable } from 'routes/ZonePriceList/PriceDetailsTabPricesTable';
import { useSelector } from 'store/utils';
import { Switch } from '@fleet/shared';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    content: {
      height: '100%',
      flex: 1,
    },
    drawer: {
      position: 'absolute',
      minWidth: 0,
      width: 368,
      left: 'auto',
      '& .MuiPaper-surface': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  }),
  {
    name: 'PriceDetailsTabPrices',
  }
);

interface PriceDetailsTabPricesProps {}

export const PriceDetailsTabPrices: FC<PriceDetailsTabPricesProps> = () => {
  const classes = useStyles();
  const { zones = [], prices = [] } = useSelector(selectCurrentPriceList) ?? {};
  const [view, setView] = useState<'matrix' | 'list'>('list');

  return (
    <div className={classes.root}>
      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
        <FormControlLabel
          control={
            <Switch
              sx={{ mr: 1 }}
              checked={view === 'matrix'}
              onChange={(e, checked) => {
                setView(checked ? 'matrix' : 'list');
              }}
            />
          }
          label={<TransField i18nKey="matrixView" />}
        />
      </Stack>
      <PriceDetailsTabPricesContextProvider
        view={view}
        zones={zones}
        prices={prices}
      >
        <div
          className={classes.content}
          style={{ ...(view === 'list' && { display: 'none' }) }}
        >
          <PriceDetailsTabPricesMatrix />
        </div>
        <div
          className={classes.content}
          style={{ ...(view === 'matrix' && { display: 'none' }) }}
        >
          <PriceDetailsTabPricesTable />
        </div>
      </PriceDetailsTabPricesContextProvider>
    </div>
  );
};
