import { createReducer } from '@reduxjs/toolkit';
import {
  FareModel,
  FareModelOriginDestinationFare,
  FareModelStop,
} from 'dto/fareModel';
import {
  getFareModelOriginDestinationFares,
  getFareModelOriginDestinationStops,
  getFareModels,
  setFareModel,
} from 'features/fareModel/fareModelActions';
import {
  getFareModelCommercialDistances,
  getFareModelCommercialDistanceStops,
  getFareModelDistanceFares,
  setFareModelDistanceFaresFilter,
} from 'features/fareModel/fareModelDistanceActions';
import {
  FareModelDistanceInterval,
  FareModelDistanceSearchParams,
  FareModelOriginDestinationCommercialDistance,
} from 'dto/fareModelDistance';
import { Pagination } from '@fleet/shared/dto/pagination';
import {
  FareModelFixedFare,
  FareModelFixedFareFilter,
} from 'dto/fareModelFixed';
import {
  getFixedFares,
  setFixedFaresFilter,
} from 'features/fareModel/fareModelFixedActions';

export interface FareModelState {
  list: Array<FareModel>;
  current?: FareModel;
  originStops: Array<FareModelStop>;
  destinationStops: Array<FareModelStop>;
  originDestinationFares: Array<FareModelOriginDestinationFare>;
  distanceFares?: Pagination<FareModelDistanceInterval>;
  distanceFaresFilter: Partial<FareModelDistanceSearchParams>;
  commercialDistances: Array<FareModelOriginDestinationCommercialDistance>;
  commercialDistanceOriginStops: Array<FareModelStop>;
  commercialDistanceDestinationStops: Array<FareModelStop>;
  fixedFaresFilter: Partial<FareModelFixedFareFilter>;
  fixedFaresList?: Pagination<FareModelFixedFare>;
}

const initialState: FareModelState = {
  list: [],
  originStops: [],
  destinationStops: [],
  originDestinationFares: [],
  distanceFaresFilter: {},
  commercialDistances: [],
  commercialDistanceOriginStops: [],
  commercialDistanceDestinationStops: [],
  fixedFaresFilter: {},
};

export const fareModelReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFareModels.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(setFareModel, (state, { payload: fareModel }) => {
      state.current = fareModel;
      if (!fareModel) {
        state.originStops = [];
        state.destinationStops = [];
        state.originDestinationFares = [];
      }
    })
    .addCase(getFareModelOriginDestinationStops.fulfilled, (state, action) => {
      state.originStops = action.payload.originStops;
      state.destinationStops = action.payload.destinationStops;
    })
    .addCase(getFareModelOriginDestinationFares.fulfilled, (state, action) => {
      state.originDestinationFares = action.payload;
    })
    .addCase(setFareModelDistanceFaresFilter, (state, action) => {
      state.distanceFaresFilter = action.payload;
    })
    .addCase(getFareModelDistanceFares.fulfilled, (state, action) => {
      state.distanceFares = action.payload;
    })
    .addCase(getFareModelCommercialDistanceStops.fulfilled, (state, action) => {
      state.commercialDistanceOriginStops = action.payload.originStops;
      state.commercialDistanceDestinationStops =
        action.payload.destinationStops;
    })
    .addCase(getFareModelCommercialDistances.fulfilled, (state, action) => {
      state.commercialDistances = action.payload;
    })
    .addCase(getFixedFares.fulfilled, (state, action) => {
      state.fixedFaresList = action.payload;
    })
    .addCase(setFixedFaresFilter, (state, action) => {
      state.fixedFaresFilter = action.payload;
    });
});
