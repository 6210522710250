import type { Pagination } from '@fleet/shared/dto/pagination';
import { PriceListsPrice } from 'dto/zonePriceList';
import type { PriceListsItem, PriceListFormValues } from 'dto/zonePriceList';
import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import qs from 'qs';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import { api } from '@fleet/shared';

export const setPriceListsFilter = createAction<Partial<PriceListFormValues>>(
  'zonePriceList/setPriceListsFilter'
);

export const setPriceLists = createAction<
  Pagination<PriceListsItem> | undefined
>('zonePriceList/setPriceLists');

export const updatePriceLists = createAction<PriceListsItem>(
  'zonePriceList/updatePriceLists'
);

export const getPriceLists = createAsyncThunk<
  void,
  Partial<PriceListFormValues> | undefined
>('zonePriceList/getPriceLists', async (values, { dispatch, getState }) => {
  values && dispatch(setPriceListsFilter(values));
  const { filter } = getState().zonePriceList;
  dispatch(
    setPriceLists(
      (
        await api.get<Pagination<PriceListsItem>>(
          `/zone-price-lists${qs.stringify(
            {
              ownerId: selectCurrentBusinessEntityId(getState()),
              ...filter,
            },
            {
              addQueryPrefix: true,
              arrayFormat: 'comma',
              allowDots: true,
            }
          )}`
        )
      ).data
    )
  );
});

export const setPriceList = createAction<PriceListsItem | undefined>(
  'zonePriceList/setPriceList'
);

export const createPriceList = createAsyncThunk<
  PriceListsItem,
  PriceListFormValues
>(
  'zonePriceList/createPriceList',
  async (data) => (await api.post(`/zone-price-lists`, data)).data
);

export const getPriceList = createAsyncThunk<PriceListsItem, number | string>(
  'zonePriceList/getPriceList',
  async (id, { dispatch }) => {
    const priceList = (await api.get(`/zone-price-lists/${id}`)).data;
    dispatch(setPriceList(priceList));
    return priceList;
  }
);

export const updatePriceList = createAsyncThunk<
  PriceListsItem,
  Required<PriceListFormValues>
>('zonePriceList/updatePriceList', async ({ id, ...data }, { dispatch }) => {
  await api.put(`/zone-price-lists/${id}`, data);
  const priceList = await dispatch(getPriceList(id)).unwrap();
  dispatch(updatePriceLists(priceList));
  return priceList;
});

export const deletePriceList = createAsyncThunk<unknown, number>(
  'zonePriceList/deletePriceList',
  async (id) => (await api.delete(`/zone-price-lists/${id}`)).data
);

export const updatePriceListPrice = createAsyncThunk<
  unknown,
  { id: string; price: PriceListsPrice }
>(
  'zonePriceList/updatePriceListPrice',
  async ({ id, price: { firstZoneId, secondZoneId, price } }) =>
    (
      await api.put(
        `/zone-price-lists/${id}/prices/${firstZoneId}/${secondZoneId}`,
        {
          price,
        }
      )
    ).data
);
export const deletePriceListPrice = createAsyncThunk<
  unknown,
  { id: string; price: PriceListsPrice }
>(
  'zonePriceList/deletePriceListPrice',
  async ({ id, price: { id: priceId } }) =>
    priceId
      ? (await api.delete(`/zone-price-lists/${id}/prices/${priceId}`)).data
      : Promise.reject('No price id provided')
);

export const updatePriceListPrices = createAsyncThunk<
  unknown,
  { id: string; prices: Array<PriceListsPrice> }
>(
  'zonePriceList/updatePriceListPrices',
  async ({ id, prices }) =>
    (await api.put(`/zone-price-lists/${id}/prices`, prices)).data
);

export const addPriceListZone = createAsyncThunk<
  unknown,
  { id: number; zoneId: number; orderNumber: number }
>(
  'zonePriceList/addPriceListZone',
  async ({ id, ...data }) =>
    (await api.post(`/zone-price-lists/${id}/zones`, data)).data
);

export const deletePriceListZone = createAsyncThunk<
  unknown,
  { id: number; zoneId: number }
>(
  'zonePriceList/deletePriceListZone',
  async ({ id, zoneId }) =>
    (await api.delete(`/zone-price-lists/${id}/zones/${zoneId}`)).data
);

export const updatePriceListZone = createAsyncThunk<
  unknown,
  { id: number; zoneId: number; orderNumber: number }
>(
  'zonePriceList/updatePriceListZone',
  async ({ id, zoneId, ...data }) =>
    (await api.put(`/zone-price-lists/${id}/zones/${zoneId}`, data)).data
);
