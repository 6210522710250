import { createSelector, selector } from 'store/utils';

export const selectPromotionalDiscounts = selector(
  (state) => state.promotionalDiscount.list
);
export const promotionalDiscountsSelector = createSelector(
  selectPromotionalDiscounts
);

export const selectPromotionalDiscountsFilter = selector(
  (state) => state.promotionalDiscount.filter
);
export const promotionalDiscountsFilterSelector = createSelector(
  selectPromotionalDiscountsFilter
);

export const selectPromotionalDiscount = selector(
  (state) => state.promotionalDiscount.current
);
export const promotionalDiscountSelector = createSelector(
  selectPromotionalDiscount
);
