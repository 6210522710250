import { createContext, useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import type { FareModel, FareModelStop } from 'dto/fareModel';
import { noop } from '@fleet/shared/utils/noop';

type ViewMode = 'matrix' | 'list';

interface FareModelStopsContextShared {
  id?: FareModel['id'];
  originStops: Array<FareModelStop>;
  destinationStops: Array<FareModelStop>;
}

type StopId = string;
export interface FareModelStopsFilter {
  originStopIds: Array<number>;
  destinationStopIds: Array<number>;
  currencyId?: string;
  fareCategoryId?: string;
}

interface FareModelStopsContextValue extends FareModelStopsContextShared {
  view: ViewMode;
  fareModelId?: FareModel['id'];
  originStopUuids: Array<StopId>;
  destinationStopUuids: Array<StopId>;
  originStopIds: Map<StopId, number>;
  destinationStopIds: Map<StopId, number>;
  stopsMap: Map<StopId, FareModelStop['stop']>;
  filters: FareModelStopsFilter;
  setFilters: Dispatch<SetStateAction<FareModelStopsFilter>>;
}

export const FareModelStopsContext = createContext<FareModelStopsContextValue>({
  view: 'list',
  originStops: [],
  destinationStops: [],
  originStopUuids: [],
  destinationStopUuids: [],
  originStopIds: new Map(),
  destinationStopIds: new Map(),
  stopsMap: new Map(),
  filters: {
    originStopIds: [],
    destinationStopIds: [],
  },
  setFilters: noop,
});

interface FareModelStopsContextProps extends FareModelStopsContextShared {
  view: ViewMode;
}

export interface FareModelStopsMatrixCellContentProps {
  coords: {
    x: number;
    y: number;
  };
  originStopId: string;
  destinationStopId: string;
}

const mapStopIds = (stops: Array<FareModelStop>) =>
  new Map(stops.map(({ id, stop }) => [id, stop.id]));
const filterStopIds = (stops: Array<FareModelStop>, filter: Array<number>) => {
  if (filter.length)
    return mapStopIds(stops.filter(({ stop }) => filter.includes(stop.id)));
  return mapStopIds(stops);
};

export const FareModelStopsContextProvider: FC<FareModelStopsContextProps> = (
  props
) => {
  const { view, id, originStops, destinationStops, children } = props;
  const [filters, setFilters] = useState<FareModelStopsFilter>({
    originStopIds: [],
    destinationStopIds: [],
  });

  const stopsMap = useMemo(
    () =>
      new Map(
        [...originStops, ...destinationStops].map(({ id, stop }) => [id, stop])
      ),
    [originStops, destinationStops]
  );

  const originStopIds = useMemo(
    () =>
      filters.originStopIds.length
        ? filterStopIds(originStops, filters.originStopIds)
        : new Map(),
    [filters.originStopIds, originStops]
  );
  const destinationStopIds = useMemo(
    () => filterStopIds(destinationStops, filters.destinationStopIds),
    [destinationStops, filters.destinationStopIds]
  );
  const originStopUuids = useMemo(
    () => [...originStopIds.keys()],
    [originStopIds]
  );
  const destinationStopUuids = useMemo(
    () => [...destinationStopIds.keys()],
    [destinationStopIds]
  );

  return (
    <FareModelStopsContext.Provider
      value={{
        view,
        fareModelId: id,
        originStops,
        destinationStops,
        originStopIds,
        destinationStopIds,
        originStopUuids,
        destinationStopUuids,
        stopsMap,
        filters,
        setFilters,
      }}
    >
      {children}
    </FareModelStopsContext.Provider>
  );
};
