import { selector } from 'store/utils';
import { ClassificationGroup, InventoryType } from 'dto/classification';

export const classificationsSelector = selector(
  (state) => state.classification
);

export const inventoryClassCompartmentIdSelector = selector((state) => {
  const { [ClassificationGroup.INVENTORY_CLASS]: inventoryClasses } =
    classificationsSelector(state);
  return inventoryClasses.find(
    ({ inventoryType }) => inventoryType.id === InventoryType.COMPARTMENT
  )?.id;
});

export const businessEntitiesSelector = selector(
  (state) => state.classification[ClassificationGroup.BUSINESS_ENTITY]
);
