import { type FC } from 'react';
import { Loadable, Layout } from '@fleet/shared';
import { PassengerTypeDiscountsTable } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscountsTable';
import { PassengerTypeDiscountsForm } from 'routes/PassengerTypeDiscounts/PassengerTypeDiscountsForm';
import { useSelector } from 'store/utils';
import { passengerTypeDiscountsLoadingSelector } from 'features/loading/loadingSelectors';
import { Route, Switch } from 'react-router-dom';

interface PassengerTypeDiscountsProps {}

export const PassengerTypeDiscounts: FC<PassengerTypeDiscountsProps> = () => {
  const loading = useSelector(passengerTypeDiscountsLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Switch>
            <Route
              path="/passenger-type-discounts/:action(create|edit)/:id?"
              component={PassengerTypeDiscountsForm}
              exact
            />
          </Switch>
        }
      >
        <PassengerTypeDiscountsTable />
      </Layout>
    </Loadable>
  );
};
