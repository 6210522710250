import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  accompanyingPassengerAmount: (
    <Trans
      i18nKey="table.head.accompanyingPassengerAmount"
      defaults="Accompanying passenger amount"
    />
  ),
  accompanyingPassengerType: (
    <Trans
      i18nKey="table.head.accompanyingPassengerType"
      defaults="Accompanying passenger type"
    />
  ),
  active: <Trans i18nKey="table.head.active" defaults="Active" />,
  ageFromTo: <Trans i18nKey="table.head.ageFromTo" defaults="Age (from-to)" />,
  agency: <Trans i18nKey="table.head.agency" defaults="Agency" />,
  assigned: <Trans i18nKey="table.head.assigned" defaults="Assigned" />,
  bookingNumber: (
    <Trans i18nKey="table.head.bookingNumber" defaults="Booking number" />
  ),
  cancelled: <Trans i18nKey="table.head.cancelled" defaults="Cancelled" />,
  cancelledOn: (
    <Trans i18nKey="table.head.cancelledOn" defaults="Cancelled on" />
  ),
  cardType: <Trans i18nKey="table.head.cardType" defaults="Card type" />,
  carrier: <Trans i18nKey="table.head.carrier" defaults="Carrier" />,
  carriers: <Trans i18nKey="table.head.carriers" defaults="Carriers" />,
  categories: <Trans i18nKey="table.head.categories" defaults="Categories" />,
  category: <Trans i18nKey="table.head.category" defaults="Category" />,
  change: <Trans i18nKey="table.head.change" defaults="Change" />,
  changedBy: <Trans i18nKey="table.head.changedBy" defaults="Changed by" />,
  changedOn: <Trans i18nKey="table.head.changedOn" defaults="Changed on" />,
  city: <Trans i18nKey="table.head.city" defaults="City" />,
  class: <Trans i18nKey="table.head.class" defaults="Class" />,
  code: <Trans i18nKey="table.head.code" defaults="Code" />,
  coefficient: (
    <Trans i18nKey="table.head.coefficient" defaults="Coefficient" />
  ),
  country: <Trans i18nKey="table.head.country" defaults="Country" />,
  countryFrom: (
    <Trans i18nKey="table.head.countryFrom" defaults="Country from" />
  ),
  countryTo: <Trans i18nKey="table.head.countryTo" defaults="Country to" />,
  county: <Trans i18nKey="table.head.county" defaults="County" />,
  createdBy: <Trans i18nKey="table.head.createdBy" defaults="Created by" />,
  currencies: <Trans i18nKey="table.head.currencies" defaults="Currencies" />,
  currency: <Trans i18nKey="table.head.currency" defaults="Currency" />,
  currentLeg: <Trans i18nKey="table.head.currentLeg" defaults="Current leg" />,
  date: <Trans i18nKey="table.head.date" defaults="Date" />,
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  destinationStop: (
    <Trans i18nKey="table.head.destinationStop" defaults="Destination stop" />
  ),
  discount: <Trans i18nKey="table.head.discount" defaults="Discount" />,
  discountAmount: (
    <Trans i18nKey="table.head.discountAmount" defaults="Discount amount" />
  ),
  discountCalculationType: (
    <Trans
      i18nKey="table.head.discountCalculationType"
      defaults="Discount calculation type"
    />
  ),
  discountDocumentType: (
    <Trans
      i18nKey="table.head.discountDocumentType"
      defaults="Discount document type"
    />
  ),
  discountName: (
    <Trans i18nKey="table.head.discountName" defaults="Discount name" />
  ),
  discountType: (
    <Trans i18nKey="table.head.discountType" defaults="Discount type" />
  ),
  discountWithPercentage: (
    <Trans
      i18nKey="table.head.discountWithPercentage"
      defaults="Discount (%)"
    />
  ),
  distanceFrom: (
    <Trans i18nKey="table.head.distanceFrom" defaults="Distance from (km)" />
  ),
  distanceTo: (
    <Trans i18nKey="table.head.distanceTo" defaults="Distance to (km)" />
  ),
  fare: <Trans i18nKey="table.head.fare" defaults="Fare" />,
  fareBasedOnCategory: (
    <Trans
      i18nKey="table.head.fareBasedOnCategory"
      defaults="Fare based on category"
    />
  ),
  fareCategory: (
    <Trans i18nKey="table.head.fareCategory" defaults="Fare category" />
  ),
  fareModel: <Trans i18nKey="table.head.fareModel" defaults="Fare model" />,
  fares: <Trans i18nKey="table.head.fares" defaults="Fares" />,
  faresQty: (
    <Trans
      i18nKey="table.head.faresQty"
      defaults="(0)[一];(1)[{{count}} fare];(2-inf)[{{count}} fares];"
    />
  ),
  field: <Trans i18nKey="table.head.field" defaults="Field" />,
  from: <Trans i18nKey="table.head.from" defaults="From" />,
  groupName: <Trans i18nKey="table.head.groupName" defaults="Group name" />,
  informationOnTicketAndSalesChannels: (
    <Trans
      i18nKey="table.head.informationOnTicketAndSalesChannels"
      defaults="Information on the ticket and in sales channels"
    />
  ),
  inventoryClasses: (
    <Trans i18nKey="table.head.inventoryClasses" defaults="Inventory classes" />
  ),
  isActive: <Trans i18nKey="table.head.isActive" defaults="Is active" />,
  isWithoutSeatReservation: (
    <Trans
      i18nKey="table.head.isWithoutSeatReservation"
      defaults="Is without seat reservation"
    />
  ),
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  lineBrand: <Trans i18nKey="table.head.lineBrand" defaults="Brand" />,
  lineType: <Trans i18nKey="table.head.lineType" defaults="Line type" />,
  lines: <Trans i18nKey="table.head.lines" defaults="Lines" />,
  mapName: <Trans i18nKey="table.head.mapName" defaults="Map name" />,
  method: <Trans i18nKey="table.head.method" defaults="Method" />,
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  no: <Trans i18nKey="table.head.no" defaults="No." />,
  nominalValue: (
    <Trans i18nKey="table.head.nominalValue" defaults="Nominal value" />
  ),
  number: <Trans i18nKey="table.head.number" defaults="No." />,
  numberOfPaymentsUsed: (
    <Trans
      i18nKey="table.head.numberOfPaymentsUsed"
      defaults="Number of payments / max"
    />
  ),
  numberOfProductsUsed: (
    <Trans
      i18nKey="table.head.numberOfProductsUsed"
      defaults="Used tickets / max"
    />
  ),
  numberOfTickets: (
    <Trans i18nKey="table.head.numberOfTickets" defaults="No. of tickets" />
  ),
  originStop: <Trans i18nKey="table.head.originStop" defaults="Origin stop" />,
  owner: <Trans i18nKey="table.head.owner" defaults="Owner" />,
  paidWith: <Trans i18nKey="table.head.paidWith" defaults="Paid with" />,
  pairsQty: (
    <Trans
      i18nKey="table.head.pairsQty"
      defaults="(0)[no pairs];(1)[{{count}} pair];(2-inf)[{{count}} pairs];"
    />
  ),
  passengerAmount: (
    <Trans i18nKey="table.head.passengerAmount" defaults="Passenger amount" />
  ),
  passengerType: (
    <Trans i18nKey="table.head.passengerType" defaults="Passenger type" />
  ),
  pointOfSale: (
    <Trans i18nKey="table.head.pointOfSale" defaults="Point of sale" />
  ),
  previousNextLeg: (
    <Trans i18nKey="table.head.previousNextLeg" defaults="Previous/next legs" />
  ),
  price: <Trans i18nKey="table.head.price" defaults="Price" />,
  priority: <Trans i18nKey="table.head.priority" defaults="Priority" />,
  product: <Trans i18nKey="table.head.product" defaults="Product" />,
  promotionName: (
    <Trans i18nKey="table.head.promotionName" defaults="Promotion name" />
  ),
  promotions: <Trans i18nKey="table.head.promotions" defaults="Promotions" />,
  refundedTo: <Trans i18nKey="table.head.refundedTo" defaults="Refunded to" />,
  salesChannel: (
    <Trans i18nKey="table.head.salesChannel" defaults="Sales channel" />
  ),
  salesChannelAgents: (
    <Trans
      i18nKey="table.head.salesChannelAgents"
      defaults="Sales channel agents"
    />
  ),
  salesChannelCountries: (
    <Trans
      i18nKey="table.head.salesChannelCountries"
      defaults="Sales channel countries"
    />
  ),
  salesDatesFrom: (
    <Trans i18nKey="table.head.salesDatesFrom" defaults="Sales dates from" />
  ),
  salesDatesTo: (
    <Trans i18nKey="table.head.salesDatesTo" defaults="Sales dates to" />
  ),
  salesPoint: <Trans i18nKey="table.head.salesPoint" defaults="Sales point" />,
  salesPoints: (
    <Trans i18nKey="table.head.salesPoints" defaults="Sales points" />
  ),
  salesTimezone: (
    <Trans i18nKey="table.head.salesTimezone" defaults="Sales timezone" />
  ),
  salesUnit: <Trans i18nKey="table.head.salesUnit" defaults="Sales unit" />,
  seatReservationNotAllowed: (
    <Trans
      i18nKey="table.head.seatReservationNotAllowed"
      defaults="Seat reservation not allowed"
    />
  ),
  shortDescription: (
    <Trans i18nKey="table.head.shortDescription" defaults="Short description" />
  ),
  stopName: <Trans i18nKey="table.head.stopName" defaults="Stop name" />,
  stops: <Trans i18nKey="table.head.stops" defaults="Stops" />,
  street: <Trans i18nKey="table.head.street" defaults="Street" />,
  streetAndHouseNumber: (
    <Trans
      i18nKey="table.head..streetAndHouseNumber"
      defaults="Street, house number"
    />
  ),
  templateName: (
    <Trans i18nKey="table.head.templateName" defaults="Template name" />
  ),
  ticketAndSalesChannelsInformation: (
    <Trans
      i18nKey="table.head.ticketAndSalesChannelsInformation"
      defaults="Information on the ticket and in sales channels"
    />
  ),
  ticketNumber: (
    <Trans i18nKey="table.head.ticketNumber" defaults="Ticket number" />
  ),
  to: <Trans i18nKey="table.head.to" defaults="To" />,
  transactionNumber: (
    <Trans
      i18nKey="table.head.transactionNumber"
      defaults="Transaction number"
    />
  ),
  unitPrice: <Trans i18nKey="table.head.unitPrice" defaults="Unit price" />,
  usedAmount: <Trans i18nKey="table.head.usedAmount" defaults="Used amount" />,
  usedPayments: (
    <Trans i18nKey="table.head.usedPayments" defaults="Used payments / max" />
  ),
  usedTickets: (
    <Trans i18nKey="table.head.usedTickets" defaults="Used tickets / max" />
  ),
  validFrom: <Trans i18nKey="table.head.validFrom" defaults="Valid from" />,
  validFromTo: (
    <Trans i18nKey="table.head.validFromTo" defaults="Valid from-to" />
  ),
  validTo: <Trans i18nKey="table.head.validTo" defaults="Valid to" />,
  validUntil: <Trans i18nKey="table.head.validUntil" defaults="Valid until" />,
  value: <Trans i18nKey="table.head.value" defaults="Value" />,
  voucherCode: (
    <Trans i18nKey="table.head.voucherCode" defaults="Voucher code" />
  ),
  voucherType: (
    <Trans i18nKey="table.head.voucherType" defaults="Voucher type" />
  ),
  vouchers: <Trans i18nKey="table.vouchers" defaults="Vouchers" />,
  vouchersCount: (
    <Trans i18nKey="table.head.vouchersCount" defaults="Vouchers" />
  ),
  withCardType: (
    <Trans i18nKey="table.head.withCardType" defaults="With card type" />
  ),
  zoneA: <Trans i18nKey="table.head.zoneA" defaults="Zone A" />,
  zoneB: <Trans i18nKey="table.head.zoneB" defaults="Zone B" />,
  zoneMap: <Trans i18nKey="table.head.zoneMap" defaults="Zone map" />,
  zoneName: <Trans i18nKey="table.head.zoneName" defaults="Zone name" />,
  zones: <Trans i18nKey="table.head.zones" defaults="Zones" />,
});
