import type { ComponentType, FC } from 'react';
import { currentPriceListSelector } from 'features/zonePriceList/zonePriceListSelectors';
import { TransTableHead } from 'i18n/trans/table';
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { populate } from 'helpers/array';
import type { GridChildComponentProps } from 'react-window';
import { PriceDetailsTabPricesContext } from 'routes/ZonePriceList/context/PriceDetailsTabPricesContext';
import { PriceDetailsTabPricesTextField } from 'routes/ZonePriceList/PriceDetailsTabPricesTextField';
import { useSelector } from 'store/utils';
import CardActionArea from '@mui/material/CardActionArea';

export const PriceDetailsTabPricesMatrixContext = createContext<
  Partial<{
    rowCount: number;
    columnCount: number;
    ItemRenderer: ComponentType<
      GridChildComponentProps<GridChildComponentProps>
    >;
  }>
>({});
PriceDetailsTabPricesMatrixContext.displayName =
  'PriceDetailsTabPricesMatrixContext';

export const stickyRowHeight = 64;
export const rowHeight = 40;
export const stickyColumnWidth = 176;
export const columnWidth = 128;

const useStyles = makeStyles(
  (theme) => ({
    innerElementType: {
      position: 'relative',
    },
    cell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '4px 16px',
      lineHeight: '16px',
    },
    cellCorner: {
      cursor: 'default',
      alignItems: 'flex-start',
      padding: 0,
      '& $cellNo': {
        lineHeight: theme.spacing(5),
        verticalAlign: 'middle',
        borderRightWidth: 0,
      },
      '& $cellZone': {
        width: '100%',
        borderTop: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
        marginLeft: -1,
      },
    },
    cellNo: {
      display: 'inline-block',
      minWidth: 48,
      height: '100%',
      borderRight: `1px solid ${theme.palette.primary.light}`,
      paddingLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
      fontSize: 12,
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: 1,
        marginLeft: -1,
        height: '100%',
        verticalAlign: 'middle',
      },
    },
    cellZone: {
      padding: `${theme.spacing(1)} 0 0`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      lineHeight: '14px',
      margin: 'auto 0',
    },
    orderNumber: {
      display: 'block',
      color: theme.palette.text.secondary,
      fontSize: 12,
      lineHeight: theme.spacing(2),
      margin: `0 ${theme.spacing(-2)}`,
      paddingLeft: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    sticky: {
      position: 'sticky !important' as 'sticky',
      zIndex: 1,
      background: theme.palette.background.default,
      border: `0px solid ${theme.palette.divider}`,
    },
    cellTitle: {
      transition: 'none',
      flexDirection: 'column',
    },
    cellTitleSelected: {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    cellPrice: {
      cursor: 'cell',
      '& .MuiCardActionArea-focusHighlight': {
        background: theme.palette.primary.light,
      },
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 1,
      },
    },
    stickyTop: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      zIndex: 2,
      borderBottomWidth: 1,
      '&$cellCorner': {
        borderBottomWidth: 0,
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      },
    },
    stickyLeft: {
      borderRightWidth: 1,
      flexDirection: 'row',
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      '& $cellZone': {
        padding: `0 0 0 ${theme.spacing(2)}`,
      },
      '&$cellCorner': {
        borderRightColor: 'transparent',
        paddingTop: 20,
        '& $cellZone': {
          padding: `0 ${theme.spacing(2)}`,
          height: '100%',
          '&:before': {
            content: '""',
            display: 'inline-block',
            width: 1,
            marginLeft: -1,
            height: '100%',
            verticalAlign: 'middle',
          },
        },
      },
    },
  }),
  {
    name: 'PriceDetailsTabPricesMatrixCell',
  }
);

export const PriceDetailsTabPricesMatrixCell: FC<
  Omit<GridChildComponentProps, 'data'>
> = ({ rowIndex, columnIndex: colIndex, style }) => {
  const classes = useStyles();
  const {
    zones,
    zoneIds = [],
    selection,
    selectCol,
    selectRow,
    selectCell,
  } = useContext(PriceDetailsTabPricesContext);
  const coords = useMemo(
    () => ({
      x: colIndex - 1,
      y: rowIndex - 1,
    }),
    [colIndex, rowIndex]
  );

  const handleClick = useCallback(() => {
    if ([rowIndex, colIndex].every((i) => i === 0)) return;
    if (rowIndex === 0) return selectCol(coords.x);
    if (colIndex === 0) return selectRow(coords.y);
    selectCell(coords.x, coords.y);
  }, [
    rowIndex,
    colIndex,
    selectCol,
    coords.x,
    coords.y,
    selectRow,
    selectCell,
  ]);

  const editable = useMemo(() => rowIndex >= colIndex, [rowIndex, colIndex]);
  const isTitle = useMemo(
    () =>
      (rowIndex === 0 && colIndex !== 0) || (colIndex === 0 && rowIndex !== 0),
    [rowIndex, colIndex]
  );
  const disabled = useMemo(
    () => (rowIndex === 0 || colIndex === 0 ? !isTitle : !editable),
    [rowIndex, colIndex, isTitle, editable]
  );

  const priceList = useSelector(currentPriceListSelector)!;
  const content = useMemo(() => {
    if (rowIndex === 0) {
      if (colIndex === 0)
        return (
          <>
            <span className={classes.cellNo}>
              <TransTableHead i18nKey="number" />
            </span>
            <span className={classes.cellZone}>
              <TransTableHead i18nKey="zoneName" />
            </span>
          </>
        );

      const id = Number(zoneIds[coords.x]);
      const { orderNumber, name } = zones?.get(id) ?? {};
      return id && orderNumber ? (
        <div>
          <span className={classes.orderNumber}>{orderNumber}</span>
          <span className={classes.cellZone}>{name}</span>
        </div>
      ) : (
        <div>
          <span className={classes.orderNumber}>&nbsp;</span>
          <span className={classes.cellZone}>—</span>
        </div>
      );
    }
    if (colIndex === 0) {
      const id = Number(zoneIds[coords.y]);
      const { orderNumber, name } = zones?.get(id) ?? {};
      return orderNumber ? (
        <>
          <span className={classes.cellNo}>
            <span>{orderNumber}</span>
          </span>
          <span className={classes.cellZone}>{name}</span>
        </>
      ) : (
        <span className={classes.cellNo}>
          <span>—</span>
        </span>
      );
    }

    return editable ? (
      <PriceDetailsTabPricesTextField
        id={priceList.id}
        rowIndex={coords.y}
        colIndex={coords.x}
      />
    ) : null;
  }, [
    rowIndex,
    colIndex,
    editable,
    priceList.id,
    coords.y,
    coords.x,
    classes.cellNo,
    classes.cellZone,
    classes.orderNumber,
    zoneIds,
    zones,
  ]);

  return (
    <CardActionArea
      data-row={rowIndex}
      data-col={colIndex}
      className={classNames(classes.cell, {
        [classes.cellTitle]: isTitle,
        [classes.cellTitleSelected]:
          (rowIndex === 0 &&
            colIndex !== 0 &&
            selection.cols.get(coords.x)?.size === zoneIds.length - coords.x) ||
          (colIndex === 0 &&
            rowIndex !== 0 &&
            selection.rows.get(coords.y)?.size === coords.y + 1),
        [classes.cellPrice]: editable && !isTitle,
        [classes.sticky]: rowIndex === 0 || colIndex === 0,
        [classes.stickyTop]: rowIndex === 0,
        [classes.stickyLeft]: colIndex === 0,
        [classes.cellCorner]: rowIndex === 0 && colIndex === 0,
      })}
      style={style}
      onClick={handleClick}
      disableRipple={!isTitle}
      disabled={disabled}
    >
      {content}
    </CardActionArea>
  );
};

export const PriceDetailsTabPricesMatrixInnerElementType =
  forwardRef<HTMLDivElement>(({ children, ...props }, ref) => {
    const { rowCount, columnCount } = useContext(
      PriceDetailsTabPricesMatrixContext
    );

    const rows = useMemo(() => populate(rowCount!), [rowCount]);
    const columns = useMemo(() => populate(columnCount!), [columnCount]);
    const classes = useStyles();
    return (
      <>
        <div className={classes.innerElementType} ref={ref} {...props}>
          {rows.map((rowIndex) =>
            (rowIndex === 0 ? columns : [0]).map((colIndex) => (
              <PriceDetailsTabPricesMatrixCell
                key={`${rowIndex}.${colIndex}`}
                rowIndex={rowIndex}
                columnIndex={colIndex}
                style={{
                  width: columnWidth,
                  height: rowHeight,
                  ...(rowIndex === 0 && {
                    height: stickyRowHeight,
                    top: 0,
                    ...(colIndex === 1 && { marginLeft: 0 }),
                    ...(colIndex === 0 && { zIndex: 3 }),
                  }),
                  ...(colIndex === 0 && {
                    width: stickyColumnWidth,
                    left: 0,
                    ...(rowIndex === 1 && { marginTop: 0 }),
                  }),
                }}
              />
            ))
          )}

          {children}
        </div>
      </>
    );
  });

export const PriceDetailsTabPricesMatrixItemWrapper: FC<GridChildComponentProps> =
  ({ data, rowIndex, columnIndex, style }) => {
    const { ItemRenderer } = data;
    // Skip virtualized render for headings
    if ([rowIndex, columnIndex].includes(0)) return null;

    return (
      <ItemRenderer
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        style={style}
      />
    );
  };
