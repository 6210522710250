import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Header as FleetHeader,
  HeaderProps as FleetHeaderProps,
  Steps,
  api,
  useAuth,
} from '@fleet/shared';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { setCurrentBusinessEntity } from 'features/common/commonActions';
import { TransNav } from 'i18n/trans/nav';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ModuleItem } from '@fleet/shared/components/Header';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';

const backLinks: FleetHeaderProps['backLinks'] = [];

interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  const auth = useAuth();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [moduleItems, setModuleItems] = useState<Array<ModuleItem>>([]);

  const fetchModuleItems = useCallback(
    async () =>
      setModuleItems(
        (
          await api.get<Array<ModuleItem>>('/api/applications', {
            baseURL: process.env.REACT_APP_AUTH_URL,
          })
        ).data
      ),
    []
  );

  useEffect(() => {
    fetchModuleItems();
  }, [fetchModuleItems]);

  const steps: Steps = useMemo(
    () => [
      {
        label: <TransNav i18nKey="passengerTypes" />,
        path: '/passenger-types',
        basePath: '/passenger-types',
      },
      {
        label: <TransNav i18nKey="passengerTypeDiscounts" />,
        path: '/passenger-type-discounts',
        basePath: '/passenger-type-discounts',
      },
      {
        label: <TransNav i18nKey="coTaxationGroups" />,
        path: '/co-taxation-groups',
        basePath: '/co-taxation-groups',
      },
      {
        label: <TransNav i18nKey="zoneFares" />,
        basePath: '/zone-fares',
        children: [
          {
            label: <TransNav i18nKey="zoneMaps" />,
            path: '/zone-fares/maps',
          },
          {
            label: <TransNav i18nKey="zoneMapPriceLists" />,
            path: '/zone-fares/pricelists',
          },
        ],
      },

      {
        label: <TransNav i18nKey="vouchersTemplate" />,
        path: '/vouchers-template',
        basePath: '/vouchers-template',
      },
      {
        label: <TransNav i18nKey="vouchers" />,
        path: '/vouchers',
        basePath: '/vouchers',
      },
      {
        label: <TransNav i18nKey="promotionalDiscounts" />,
        path: '/promotional-discounts',
        basePath: '/promotional-discounts',
      },
      {
        label: <TransNav i18nKey="fareModels" />,
        path: '/fare-models',
        basePath: '/fare-models',
      },
    ],
    []
  );
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const onBusinessEntitySelect = useCallback(
    (value) => {
      dispatch(setCurrentBusinessEntity(value));
    },
    [dispatch]
  );

  return (
    <FleetHeader
      steps={steps}
      backLinks={backLinks}
      pathname={pathname}
      businessEntities={businessEntities}
      allowedRoles={ALLOWED_BUSINESS_ENTITY_ROLES}
      currentBusinessEntityId={currentBusinessEntityId}
      onBusinessEntityChange={onBusinessEntitySelect}
      onLogout={auth.signoutRedirect}
      helpPath="https://turnit.atlassian.net/wiki/spaces/HM/pages/455507987/Fare+Management"
      onMenuClick={history.push}
      moduleItems={moduleItems}
    />
  );
};
