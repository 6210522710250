import type { PropsWithChildren } from 'react';
import { useCallback, useContext, useMemo } from 'react';
import { TransButton } from 'i18n/trans/button';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Button,
  FormControl,
  FormProvider,
  Icon,
  SearchForm,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { FormConfig } from 'react-final-form-hooks';
import {
  FareModelStopsSelect,
  FareModelStopsSelectValue,
  mapStops,
} from 'routes/FareModels/FareModelStopsSelect/FareModelStopsSelect';
import { Tooltip } from '@fleet/shared/mui';
import { TransHint } from 'i18n/trans/hint';
import { FareModelStopsContext } from 'routes/FareModels/FareModelStops/FareModelStopsContext';

interface FormValues {
  originStops: FareModelStopsSelectValue;
  destinationStops: FareModelStopsSelectValue;
}

interface SubmitValues extends Record<string, unknown> {
  originStopIds: Array<number>;
  destinationStopIds: Array<number>;
}

export interface FareModelFilterFormProps<D extends FormValues> {
  initialValues?: Partial<D>;
  onSubmit: (values: SubmitValues) => Promise<void>;
  className?: string;
}

export function FareModelFilterForm<D extends FormValues>({
  initialValues: initialValuesProp,
  onSubmit: onSubmitProp,
  className,
  children,
}: PropsWithChildren<FareModelFilterFormProps<D>>) {
  const {
    originStops: fareModelOriginStops,
    destinationStops: fareModelDestinationStops,
  } = useContext(FareModelStopsContext);

  const originStops = useMemo(
    () => Array.from(mapStops(fareModelOriginStops).values()),
    [fareModelOriginStops]
  );
  const destinationStops = useMemo(
    () =>
      fareModelDestinationStops.length
        ? Array.from(mapStops(fareModelDestinationStops).values())
        : originStops,
    [fareModelDestinationStops, originStops]
  );

  const initialValues = useMemo(
    () =>
      ({
        originStops: new Map(),
        destinationStops: new Map(),
        // TODO, DEV mode only, remove if unnecessary
        ...(process.env.NODE_ENV === 'development' && {
          originStops: mapStops(fareModelOriginStops),
        }),
        ...(initialValuesProp && initialValuesProp),
      } as D),
    [fareModelOriginStops, initialValuesProp]
  );

  const { setFilters } = useContext(FareModelStopsContext);
  const onSubmit = useCallback<FormConfig<D>['onSubmit']>(
    ({ originStops, destinationStops, ...values }) =>
      formSubmit(async () => {
        const originStopIds = Array.from(originStops.keys());
        const destinationStopIds = destinationStops.size
          ? Array.from(destinationStops.keys())
          : originStopIds;
        await onSubmitProp({ originStopIds, destinationStopIds, ...values });
        setFilters({ originStopIds, destinationStopIds, ...values });
      }),
    [onSubmitProp, setFilters]
  );
  const { form, handleSubmit } = useForm<D>({
    initialValues,
    onSubmit,
  });
  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <SearchForm
      className={className}
      title={<TransSubtitle i18nKey="filterStops" />}
    >
      <FormProvider form={form}>
        <Grid
          container
          columns={4}
          spacing={2}
          rowSpacing={1}
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid item xs={1}>
            <FareModelStopsSelect
              name="originStops"
              label={<TransField i18nKey="originStops" />}
              selectedStops={originStops}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <FareModelStopsSelect
              name="destinationStops"
              label={
                <Stack direction="row" alignItems="center">
                  <TransField i18nKey="destinationStops" />
                  <Tooltip
                    content={<TransHint i18nKey="destinationStops" />}
                    delay={500}
                  >
                    <Icon name="question" size={16} margin />
                  </Tooltip>
                </Stack>
              }
              selectedStops={destinationStops}
            />
          </Grid>

          {children}

          <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <Stack direction="row" spacing={2}>
              <FormControl label="&nbsp;">
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="text"
                  type="button"
                  onClick={handleReset}
                >
                  <TransButton i18nKey="resetFilters" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<Icon name="filter" />}
                >
                  <TransButton i18nKey="filter" />
                </Button>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </SearchForm>
  );
}
