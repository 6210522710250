import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import type {
  PromotionalDiscount,
  PromotionalDiscountFilter,
  PromotionalDiscountValues,
} from 'dto/promotionalDiscount';
import type { Pagination } from '@fleet/shared/dto/pagination';
import qs from 'qs';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import { api } from '@fleet/shared';

export const setPromotionalDiscountFilter = createAction<
  Partial<PromotionalDiscountFilter>
>('promotionalDiscount/setPromotionalDiscountFilter');

export const setPromotionalDiscount = createAction<
  PromotionalDiscount | undefined
>('promotionalDiscount/setPromotionalDiscount');

export const clearPromotionalDiscounts = createAction(
  'promotionalDiscount/clearPromotionalDiscounts'
);

export const getPromotionalDiscounts = createAsyncThunk<
  Pagination<PromotionalDiscount>,
  Partial<PromotionalDiscountFilter> | undefined
>(
  'promotionalDiscount/getPromotionalDiscounts',
  async (values, { dispatch, getState }) => {
    values && dispatch(setPromotionalDiscountFilter(values));
    const { filter } = getState().promotionalDiscount;

    return (
      await api.get(
        `/promotional-discounts${qs.stringify(
          {
            ownerId: selectCurrentBusinessEntityId(getState()),
            ...filter,
          },
          {
            addQueryPrefix: true,
            allowDots: true,
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_DM,
        }
      )
    ).data;
  }
);

export const getPromotionalDiscount = createAsyncThunk<
  PromotionalDiscount,
  PromotionalDiscountValues['id']
>('promotionalDiscount/getPromotionalDiscount', async (id, { dispatch }) => {
  const { data } = await api.get<PromotionalDiscount>(
    `/promotional-discounts/${id}`,
    {
      baseURL: process.env.REACT_APP_API_DM,
    }
  );

  dispatch(setPromotionalDiscount(data));
  return data;
});

export const createPromotionalDiscount = createAsyncThunk<
  PromotionalDiscount,
  PromotionalDiscountValues
>(
  'promotionalDiscount/createPromotionalDiscount',
  async (payload, { dispatch }) => {
    const { data } = await api.post<PromotionalDiscount>(
      `/promotional-discounts`,
      payload,
      {
        baseURL: process.env.REACT_APP_API_DM,
      }
    );
    await dispatch(getPromotionalDiscounts()).unwrap();
    return data;
  }
);

export const updatePromotionalDiscount = createAsyncThunk<
  PromotionalDiscount,
  PromotionalDiscountValues
>(
  'promotionalDiscount/updatePromotionalDiscount',
  async ({ id, ...payload }, { dispatch }) => {
    const { data } = await api.put<PromotionalDiscount>(
      `/promotional-discounts/${id}`,
      payload,
      {
        baseURL: process.env.REACT_APP_API_DM,
      }
    );
    await dispatch(getPromotionalDiscounts()).unwrap();
    return data;
  }
);

export const deletePromotionalDiscount = createAsyncThunk<
  void,
  PromotionalDiscountValues['id']
>('promotionalDiscount/deletePromotionalDiscount', async (id, { dispatch }) => {
  await api.delete<PromotionalDiscount>(`/promotional-discounts/${id}`, {
    baseURL: process.env.REACT_APP_API_DM,
  });
  await dispatch(getPromotionalDiscounts());
});
