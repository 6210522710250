import { ClassificationGroup, ZoneMapZone } from 'dto/classification';
import { classificationsSelector } from 'features/classification/classificationSelectors';
import {
  addPriceListZone,
  deletePriceListZone,
  getPriceList,
  updatePriceListZone,
} from 'features/zonePriceList/zonePriceListActions';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { TableColumns } from '@fleet/shared';
import { Table, useRowSelectCheckbox } from '@fleet/shared';
import { TableDragDropProps } from '@fleet/shared/components/Table';
import { Icon } from '@fleet/shared/mui';
import { selectCurrentPriceList } from 'features/zonePriceList/zonePriceListSelectors';
import { TransTableHead } from 'i18n/trans/table';
import { makeStyles } from '@mui/styles';
import { useTable, useRowSelect, Hooks } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import {
  PriceDetailsTabZonesFilterForm,
  TabZonesFilters,
} from 'routes/ZonePriceList/PriceDetailsTabZonesFilterForm';
import { filterByKeyword } from 'helpers/filter';

const useStyles = makeStyles(
  () => ({
    root: {
      overflow: 'auto',
      height: '100%',
    },
  }),
  {
    name: 'PriceDetailsTabZones',
  }
);

interface PriceDetailsTabZonesProps {}

type ZoneColumn = ZoneMapZone & { order?: number };

export const PriceDetailsTabZones: FC<PriceDetailsTabZonesProps> = () => {
  const {
    id,
    zones: assignedZones = [],
    zoneMap,
  } = useSelector(selectCurrentPriceList) ?? {};
  const zoneMapClassifications = useSelector(classificationsSelector)[
    ClassificationGroup.ZONE_MAP
  ];

  const ordersMap = useMemo(
    () =>
      new Map(
        assignedZones.map(
          ({ zoneMapZoneId, orderNumber }) => [zoneMapZoneId, orderNumber],
          {}
        )
      ),
    [assignedZones]
  );
  const selectedRowIds = useMemo<Record<string, boolean>>(
    () =>
      assignedZones.reduce(
        (acc, { zoneMapZoneId }) => ({ ...acc, [zoneMapZoneId]: true }),
        {}
      ),
    [assignedZones]
  );
  const [filters, setFilters] = useState<TabZonesFilters>({});
  const [zones, setZones] = useState<Array<ZoneColumn>>([]);
  useEffect(() => {
    const { assigned, name, code } = filters;
    const getOrderNo = (id: number) => ordersMap.get(id) ?? Infinity;
    setZones(
      (zoneMapClassifications.find(({ id }) => id === zoneMap?.id)?.zones ?? [])
        .map((zone) => ({ ...zone, order: getOrderNo(zone.id) }))
        .filter((zone) => {
          return (
            (typeof assigned === 'boolean'
              ? assigned
                ? selectedRowIds[zone.id]
                : !selectedRowIds[zone.id]
              : true) &&
            (name ? filterByKeyword(name)(zone.name) : true) &&
            (code ? filterByKeyword(code)(zone.code) : true)
          );
        })
        .sort((a, b) =>
          getOrderNo(a.id) ? getOrderNo(a.id) - getOrderNo(b.id) : Infinity
        )
    );
  }, [
    assignedZones,
    filters,
    ordersMap,
    selectedRowIds,
    zoneMap?.id,
    zoneMapClassifications,
  ]);

  const columns = useMemo<TableColumns<ZoneColumn>>(
    () => [
      {
        id: 'name',
        accessor: (zone) => zone.name,
        Header: <TransTableHead i18nKey="zoneName" />,
        width: 'auto',
      },
      {
        id: 'code',
        accessor: (zone) => zone.code,
        Header: <TransTableHead i18nKey="code" />,
        width: 'auto',
      },
    ],
    []
  );

  const initialState = useMemo(() => ({ selectedRowIds }), [selectedRowIds]);
  const getRowId = useCallback((row: ZoneMapZone) => `${row.id}`, []);

  const dispatch = useDispatch();
  const useCustomRowSelect = useMemo(
    () => (hooks: Hooks<ZoneMapZone>) => {
      hooks.getToggleRowSelectedProps.push((props, meta) => ({
        ...props,
        onChange: async (event) => {
          props.onChange?.(event);
          await dispatch(
            ((event.target as HTMLInputElement).checked
              ? addPriceListZone
              : deletePriceListZone)({
              id: id!,
              zoneId: Number(meta.row.id!),
              orderNumber: meta.row.index! + 1,
            })
          ).unwrap();
          await dispatch(getPriceList(id!));
        },
      }));
    },
    [dispatch, id]
  );

  const handleDragEnd = useCallback<
    TableDragDropProps<ZoneMapZone>['onDragEnd']
  >(
    async ({ data, result }) => {
      if (!ordersMap.get(Number(result.draggableId))) return;
      setZones(data);
      await dispatch(
        updatePriceListZone({
          id: id!,
          zoneId: result.draggableId,
          orderNumber: result.destination.index + 1,
        })
      );
      await dispatch(getPriceList(id!));
    },
    [dispatch, id, ordersMap]
  );

  const table = useTable(
    {
      data: zones,
      columns,
      initialState,
      getRowId,
    },
    useRowSelect,
    useCustomRowSelect,
    useRowSelectCheckbox,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          accessor: 'id',
          id: 'draggable',
          width: 0,
          Cell: ({ row: { original } }) => {
            if (original.order === Infinity) return null;
            return <Icon name="draggable" />;
          },
        },
        ...columns.map((column) =>
          column.id === 'selection'
            ? {
                ...column,
                Header: () => <TransTableHead i18nKey="assigned" />,
                width: 64,
              }
            : column
        ),
      ]);
    }
  );

  const classes = useStyles();
  return (
    <Table
      classes={classes}
      caption={<PriceDetailsTabZonesFilterForm onChange={setFilters} />}
      table={table}
      draggable={filters.assigned !== false}
      onDragEnd={handleDragEnd}
    />
  );
};
